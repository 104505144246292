import logo from "../images/RhibhusLogoFooter.svg";
import { Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import emailjs from "@emailjs/browser";
import Loader from "./Loader";
import $ from 'jquery';
import { useLocation } from "react-router-dom";
import linkedin from "../images/linkedin.svg";
import twitter from "../images/twitter.svg";
import facebook from "../images/facebook.svg";

const Footer = () => {
  const location = useLocation();
  const footerSwitch = () => {
    if (location.pathname.includes("/Contact")) {
      setSection(false)
    } else {
      setSection(true)
    }
  }
  useEffect(() => {
    footerSwitch()
  })

  const [formTemp, setFormTemp] = useState({
    form_name: "",
    form_email: "",
    form_message: "",
  });

  const [disableBtn, setDisableBtn] = useState(true);
  const [loader, setLoader] = useState(false);
  const [section, setSection] = useState(true);



  window.onscroll = function () {
    scrollFunction();
  };

  function scrollFunction() {
    if (
      document.body.scrollTop > 60 ||
      document.documentElement.scrollTop > 60
    ) {
      document.getElementById("movetop").style.display = "block";
      // document.getElementById("NavBar").style.position = "sticky";
      $(".Logo").addClass("Hidden");
      $(".LogoInside").addClass("Shown");
      $(".me-auto.navbar-nav").addClass("moveLeft");
      $(".navTabs").addClass("shrink");
      $(".navbar-brand").addClass("paddings");
    } else {
      document.getElementById("movetop").style.display = "none";
      $(".Logo").removeClass("Hidden");
      $(".LogoInside").removeClass("Shown");
      $(".me-auto.navbar-nav").removeClass("moveLeft");
      $(".navTabs").removeClass("shrink");
      $(".navbar-brand").removeClass("paddings");
    }
  }

  function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(formTemp);
    setLoader(true);

    emailjs
      .send(
        "service_cdmiu3v",
        "template_3jwzouj",
        formTemp,
        "oObGEzKthT9U9wEoT"
      )
      .then(
        function (response) {
          // console.log("SUCCESS!", response.status, response.text);
          setLoader(false);
          alert("Message Recieved. You will be contacted Shortly. Thank You");
          resetForm();
          setDisableBtn(true);
        },
        function (error) {
          // console.log("FAILED...", error);
          setLoader(false);
          alert("Message failed to send. Retry or just contact our support.");
          setDisableBtn(true);
        }
      );

  };

  const resetForm = () => {
    setFormTemp({ form_email: "", form_message: "", form_name: "" });
  };

  const handleChange = (e) => {
    if (e.target.name === "form_fname") {
      setFormTemp({ ...formTemp, form_fname: e.target.value });
    }
    if (e.target.name === "Last Name") {
      setFormTemp({ ...formTemp, form_lname: e.target.value });
    }
    if (e.target.name === "form_email") {
      setFormTemp({ ...formTemp, form_email: e.target.value });
    }
    if (e.target.name === "Company") {
      setFormTemp({ ...formTemp, company: e.target.value });
    }
    if (e.target.name === "form_message") {
      setFormTemp({ ...formTemp, form_message: e.target.value });
    }
    console.log(e.target.name);
    // setFormTemp({ form_email: "", form_message: "", form_name: "" });
  };

  const Checked = () => {
    setDisableBtn(!disableBtn);
  };

  function validateEmail522767000000385005() {
    var form = document.forms['WebToLeads522767000000385005'];
    var emailFld = form.querySelectorAll('[ftype=email]');
    var i;
    for (i = 0; i < emailFld.length; i++) {
      var emailVal = emailFld[i].value;
      if ((emailVal.replace(/^\s+|\s+$/g, '')).length !== 0) {
        var atpos = emailVal.indexOf('@');
        var dotpos = emailVal.lastIndexOf('.');
        if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= emailVal.length) {
          alert('Please enter a valid email address. ');
          emailFld[i].focus();
          return false;
        }
      }
    }
    return true;
  }

  function checkMandatory522767000000385005() {
    var mndFileds = ['Company', 'Last Name'];
    var fldLangVal = ['Company', 'Last\x20Name'];
    for (var i = 0; i < mndFileds.length; i++) {
      var fieldObj = document.forms['WebToLeads522767000000385005'][mndFileds[i]];
      if (fieldObj) {
        if (((fieldObj.value).replace(/^\s+|\s+$/g, '')).length === 0) {
          if (fieldObj.type === 'file') {
            alert('Please select a file to upload.');
            fieldObj.focus();
            return false;
          }
          alert(fldLangVal[i] + ' cannot be empty.');
          fieldObj.focus();
          return false;
        } else if (fieldObj.nodeName === 'SELECT') {
          if (fieldObj.options[fieldObj.selectedIndex].value === '-None-') {
            alert(fldLangVal[i] + ' cannot be none.');
            fieldObj.focus();
            return false;
          }
        } else if (fieldObj.type === 'checkbox') {
          if (fieldObj.checked === false) {
            alert('Please accept  ' + fldLangVal[i]);
            fieldObj.focus();
            return false;
          }
        }
      }
    }
    if (!validateEmail522767000000385005()) { return false; }
    document.querySelector('.crmWebToEntityForm .formsubmit').setAttribute('disabled', true);
  }

  return (
    <>
      {!section &&
        <div id="GetInTouch" className="backgroundpale pt-4 m-0 mt-2">
          <div className="BringItToCenter">
            <div className="MainLayout  pb-4">
              <div className="row  pb-4 ">
                <div data-aos="fade-right" data-aos-duration="800"
                  data-aos-easing="ease-in-out" className="col-lg-5 col-md-5 d-flex flex-column align-items-center justify-content-center Overflow mt-3 mb-3 ps-4 ">
                  <div className="HiContainer Overflow">
                    <p className="HiText">Let's Say Hi</p>
                    <p className="HiText2">Rhibhus Infosystems Private Limited</p>
                    <div className="HiText3">
                      <p >Office - 43, Residency Rd, Shanthala Nagar, Ashok Nagar, Bengaluru, Karnataka 560025
                      </p>
                      <a className="HiText3" href="mailto:info@rhibhus.com">info@rhibhus.com</a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-7 col-md-7 d-flex flex-column align-items-start justify-content-start mt-3 mb-3 ">
                  <div className="GetinTouchForm ">
                    {loader ? (
                      <div className="LoaderClass">
                        <Loader />
                      </div>
                    ) : (
                      <form
                        data-aos="fade-left" data-aos-duration="800"
                        data-aos-easing="ease-in-out"
                        id="getInTouch-form"
                        method="POST"
                        onSubmit={(e) => handleSubmit(e)}
                        noValidate
                      >
                        <div className="row survey  pt-4 pb-4 m-0">
                          <div className="col-lg-12 col-md-12 p-5 ">
                            <div class="input-group mb-3 surveyInputs">
                              <input
                                type="text"
                                name="form_name"
                                class="form-control"
                                placeholder="Name"
                                value={formTemp.form_name}
                                onChange={(e) => handleChange(e)}
                                required
                              />
                            </div>
                            <div class="input-group mb-3 surveyInputs">
                              <input
                                type="email"
                                name="form_email"
                                class="form-control "
                                onChange={(e) => handleChange(e)}
                                value={formTemp.form_email}
                                placeholder="Email Address"
                                required
                              />
                            </div>
                            <div class="input-group surveyTextBox">
                              <textarea
                                name="form_message"
                                class="form-control "
                                value={formTemp.form_message}
                                placeholder="Briefly, what do you want us to engineer for you?"
                                onChange={(e) => handleChange(e)}
                                required
                                style={{
                                  maxHeight: "100px",
                                  minHeight: "100px",
                                  height: "100px",
                                }}
                              ></textarea>
                            </div>
                            <div class="form-group pt-3 pb-3">
                              <input
                                className="me-1"
                                name="policy"
                                onChange={Checked}
                                type="checkbox"
                              />
                              <span className="checkBoxText">
                                Yes - I have read and understood the privacy policy and terms and conditions
                              </span>
                            </div>
                            <div>
                              <Button
                                className="btn btn-secondary mt-2 SurveySubmitButton getinTouch "
                                type="submit"
                                disabled={disableBtn}
                              >
                                Submit
                              </Button>
                            </div>
                          </div>
                        </div>
                      </form>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div >
        </div>
      }

      {section &&
        <div id="GetInTouch" className="backgroundpale pt-4">
          <div className="BringItToCenter ">
            <div className="MainLayout pt-4 pb-4">
              <div className="row pt-3 pb-4 ">
                <div className="col-lg-5 col-md-5 d-flex flex-column align-items-center justify-content-center Overflow   mt-3 mb-3 FooterPaddings  " data-aos="fade-right" data-aos-duration="800"
                  data-aos-easing="ease-in-out" >
                  <div className="row Hiblock d-flex  align-items-center justify-content-center">
                    <div className="col-lg-12 col-md-12 p-5   ">
                      <div className="GetinTouchForm Overflow ">
                        <p className="ProductEngineeringText">Let's Say Hi</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-7 col-md-7 d-flex flex-column align-items-start justify-content-start mt-3 mb-3 FooterPaddings ">
                  <div id='crmWebToEntityForm' class='crmWebToEntityForm GetinTouchForm'>
                    {loader ? (
                      <div className="LoaderClass">
                        <Loader />
                      </div>
                    ) : (
                      <>
                        <form
                          data-aos="fade-left" data-aos-duration="800"
                          data-aos-easing="ease-in-out"
                          id="getInTouch-form"
                          method="POST"
                          name='WebToLeads522767000000385005'
                          action='https://crm.zoho.in/crm/WebToLeadForm'
                          onSubmit={() => { checkMandatory522767000000385005() }}
                          accept-charset='UTF-8'
                        >
                          <input type='text' style={{ display: 'none' }} name='xnQsjsdp' value='3d428d58cdf7e63422a3dac8e2ccb64931591abd57cc5483791a14b2b5647c00' />
                          <input type='hidden' name='zc_gad' id='zc_gad' value='' />
                          <input type='text' style={{ display: 'none' }} name='xmIwtLD' value='285e916dc7be39a6a2a78bb2b44afa4c9b766b887166db45569c4242de278899' />
                          <input type='text' style={{ display: 'none' }} name='actionType' value='TGVhZHM=' />
                          <input type='text' style={{ display: 'none' }} name='returnURL' value='https&#x3a;&#x2f;&#x2f;rhibhus.com&#x2f;thankyou' />

                          <div className="row survey  pt-2 m-0">
                            <div className="col-lg-12 col-md-12 p-5 ">

                              <div class="input-group mb-3 surveyInputs">
                                <input
                                  type="text"
                                  id='First_Name'
                                  name='First Name'
                                  class="form-control zcwf_col_fld"
                                  maxlength='80'
                                  placeholder="First Name*"
                                  required
                                />
                                <div class='zcwf_col_help'>
                                </div>
                              </div>

                              <div class="input-group mb-3 surveyInputs">
                                <input
                                  type="text"
                                  id='Last_Name'
                                  name='Last Name'
                                  maxlength='80'
                                  class="form-control zcwf_col_fld"
                                  placeholder="Last Name*"
                                  required
                                />
                                <div class='zcwf_col_help'>
                                </div>
                              </div>

                              <div class="input-group mb-3 surveyInputs">
                                <input
                                  type="text"
                                  id='Company'
                                  maxlength='200'
                                  class="form-control zcwf_col_fld"
                                  placeholder="Company*"
                                  required
                                />
                                <div class='zcwf_col_help'>
                                </div>
                              </div>

                              <div class="input-group mb-3 surveyInputs">
                                <input
                                  type="email"
                                  id='Email'
                                  ftype='email'
                                  name='Email'
                                  maxlength='100'
                                  class="form-control zcwf_col_fld"
                                  placeholder="Email Address*"
                                  required
                                />
                                <div class='zcwf_col_help'>
                                </div>
                              </div>

                              <div class="input-group surveyTextBox">
                                <textarea
                                  id='Description'
                                  name='Description'
                                  class="form-control zcwf_col_fld"
                                  placeholder="Briefly, what do you want us to engineer for you?"
                                  style={{
                                    maxHeight: "100px",
                                    minHeight: "100px",
                                    height: "100px",
                                  }}
                                ></textarea>
                                <div class='zcwf_col_help'>
                                </div>
                              </div>

                              <div class='zcwf_row wfrm_fld_dpNn'>
                                <div class='zcwf_col_lab' >
                                  <label for='Lead_Source'>Lead Source</label>
                                </div>
                                <div class='zcwf_col_fld'>
                                  <select
                                    class='zcwf_col_fld_slt form-control'
                                    id='Lead_Source'
                                    name='Lead Source' >
                                    <option value='-None-'>-None-</option>
                                    <option value='Advertisement'>Advertisement</option>
                                    <option value='Cold&#x20;Call'>Cold Call</option>
                                    <option value='Employee&#x20;Referral'>Employee Referral</option>
                                    <option value='External&#x20;Referral'>External Referral</option>
                                    <option value='Online&#x20;Store'>Online Store</option>
                                    <option value='Partner'>Partner</option>
                                    <option value='Public&#x20;Relations'>Public Relations</option>
                                    <option value='Sales&#x20;Email&#x20;Alias'>Sales Email Alias</option>
                                    <option value='Seminar&#x20;Partner'>Seminar Partner</option>
                                    <option value='Internal&#x20;Seminar'>Internal Seminar</option>
                                    <option value='Trade&#x20;Show'>Trade Show</option>
                                    <option value='Web&#x20;Download'>Web Download</option>
                                    <option selected value='Web&#x20;Research'>Web Research</option>
                                    <option value='Chat'>Chat</option>
                                    <option value='Twitter'>Twitter</option>
                                    <option value='Facebook'>Facebook</option>
                                    <option value='Google&#x2b;'>Google&#x2b;</option>
                                  </select>
                                  <div class='zcwf_col_help'></div>
                                </div>
                              </div>

                              <div class="form-group pt-3 pb-3">
                                <input
                                  className="me-1"
                                  name="policy"
                                  onChange={Checked}
                                  type="checkbox"
                                  required
                                />
                                <span className="checkBoxText">
                                  Yes - I have read and understood the privacy policy.
                                </span>
                              </div>

                              <div class="zcwf_col_fld mt-2">
                                <input
                                  id='formsubmit'
                                  class='formsubmit zcwf_button btn btn-secondary mt-2 SurveySubmitButton getinTouch '
                                  value='Submit'
                                  title='Submit'
                                  type="submit"
                                />

                                <input
                                  type="reset"
                                  class='zcwf_button btn btn-secondary mt-2 SurveyResetButton getinTouch'
                                  name='reset'
                                  value='Reset'
                                  title='Reset'
                                />
                              </div>
                            </div>
                          </div>

                        </form>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div >
        </div>
      }

      {/* footer */}
      <div className="BringItToCenter p-0">
        <div className="RootLayout" >
          <div class="footer">
            <footer class="text-center text-lg-start ">
              <div class=" p-4 pb-0">
                <section class="">
                  <div class="row">
                    <div className="col-lg-3 col-md-4 mb-4 mb-md-0 d-flex justify-content-center align-items-center">
                      <div class="row ">
                        <div class="col-lg-4 col-md-6 mb-4 mb-md-0  pb-5 ">
                          <a href="/">
                            <img className="FLogo" src={logo} alt="logo" />
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-9 col-md-8 mb-4 mb-md-0">
                      <div class="row">
                        <div class="col-lg-3 col-md-6 mb-4 mb-md-0 FooterPaddingBottom FooterBorderTop ContactOn">
                          <ul class="list-unstyled mb-0">
                            <li>
                              <a
                                class="HashLinkFooter text-white FooterHeads"
                                href="/contactus"
                              >
                                Contact Us
                              </a>
                            </li>
                          </ul>
                          <p class="address mb-0">
                            <a className="mailLink" href="mailto:info@rhibhus.com">E:info@rhibhus.com</a> <br />Office - 43, Residency Rd, Shanthala
                            Nagar, Ashok Nagar, Bengaluru, Karnataka 560025
                          </p>
                        </div>

                        <div class="col-lg-3 col-md-6 mb-4 mb-md-0 ContactOn FooterPaddingBottom">
                          <ul class="list-unstyled mb-0">
                            <li>
                              <p class="text-white FooterHeads contact m-0">
                                Connect with Us
                              </p>
                            </li>
                            <li>
                              <div className="d-flex justify-content-center align-items-center p-3 ms-3">
                                <a
                                  class="HashLinkFooter me-3"
                                  href="https://www.linkedin.com/company/rhibhus/"
                                >
                                  <img src={linkedin} alt="linkedin" />
                                </a>
                                <a
                                  class="HashLinkFooter me-3"
                                  href="https://www.twitter.com/Rhibhus_"
                                >
                                  <img src={twitter} alt="twitter" />
                                </a>
                                <a
                                  class="HashLinkFooter me-3"
                                  href="https://www.facebook.com/rhibhusinfosystem"
                                >
                                  <img src={facebook} alt="facebook" />
                                </a>
                              </div>
                            </li>
                          </ul>
                        </div>

                        <div class="col-lg-3 col-md-6 mb-4 mb-md-0 FooterPaddingBottom ">
                          <ul class="list-unstyled mb-0">
                            <li>
                              <a
                                class="HashLinkFooter text-white FooterHeads"
                                href="/#ProductEngineering"
                              >
                                Product Engineering
                              </a>
                            </li>
                            <li>
                              <a
                                class="HashLinkFooter text-white "
                                href="/premvplabs"
                              >
                                Pre MVP Labs
                              </a>
                            </li>
                            <li>
                              <a
                                class="HashLinkFooter text-white "
                                href="/mvp"
                              >
                                MVP Development
                              </a>
                            </li>
                            <li>
                              <a
                                class="HashLinkFooter text-white "
                                href="/fullscaleapplication"
                              >
                                Full Scale Applications
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div class="col-lg-3 col-md-6 mb-4 mb-md-0 FooterPaddingBottom">
                          <ul class="list-unstyled mb-0">
                            <li>
                              <a
                                class="HashLinkFooter text-white FooterHeads"
                                href="/techconsulting"
                              >
                                Consulting
                              </a>
                            </li>
                            <li>
                              <a
                                class="HashLinkFooter text-white "
                                href="/techconsulting#RequirementScopings"
                              >
                                Requirement Scoping
                              </a>
                            </li>
                            <li>
                              <a
                                class="HashLinkFooter text-white "
                                href="/techconsulting#AuditAssessment"
                              >
                                Audit & Assessment
                              </a>
                            </li>
                            <li>
                              <a
                                class="HashLinkFooter text-white "
                                href="/techconsulting#Re-Architecturing"
                              >
                                Re-Architecturing
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div class="col-lg-3 col-md-6 mb-4 mb-md-0 FooterPaddingBottom">
                          <ul class="list-unstyled mb-0">
                            <li>
                              <a
                                class="HashLinkFooter text-white FooterHeads"
                                href="/maintainandsupport"
                              >
                                Maintenance & Support
                              </a>
                            </li>
                            <li>
                              <a
                                class="HashLinkFooter text-white"
                                href="/maintainandsupport#Re-Parenting"
                              >
                                Re-Parenting
                              </a>
                            </li>
                            <li>
                              <a
                                class="HashLinkFooter text-white"
                                href="/maintainandsupport#Enhancements"
                              >
                                Enhancements
                              </a>
                            </li>
                            <li>
                              <a
                                class="HashLinkFooter text-white"
                                href="/maintainandsupport#ProductionSupport"
                              >
                                Production Support
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div class="col-lg-3 col-md-6 mb-4 mb-md-0 FooterPaddingBottom">
                          <ul class="list-unstyled mb-0">
                            <li>
                              <a
                                class="HashLinkFooter text-white FooterHeads"
                                href="/cybersecurity"
                              >
                                Cyber Security
                              </a>
                            </li>
                            <li>
                              <a
                                class="HashLinkFooter text-white"
                                href="/cybersecurity#Vulnerability"
                              >
                                Vulnerability Assessment
                              </a>
                            </li>
                            <li>
                              <a
                                class="HashLinkFooter text-white"
                                href="/cybersecurity#Penetration"
                              >
                                Penetration Testing
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div class="col-lg-3 col-md-6 mb-4 mb-md-0 FooterPaddingBottom">
                          <ul class="list-unstyled mb-0">
                            <li>
                              <a
                                class="HashLinkFooter text-white FooterHeads"
                                href="/casestudies"
                              >
                                Case Studies
                              </a>
                            </li>
                            <li>
                              <a
                                class="HashLinkFooter text-white"
                                href="/hardwareintegration"
                              >
                                Hardware Integration
                              </a>
                            </li>
                            <li>
                              <a
                                class="HashLinkFooter text-white"
                                href=
                                "/fullscaleapplicationcasestudy"

                              >
                                Full Scale Applications
                              </a>
                            </li>
                            <li>
                              <a
                                class="HashLinkFooter text-white"
                                href="/redevelopment"
                              >
                                Re-developement
                              </a>
                            </li>
                            <li>
                              <a
                                class="HashLinkFooter text-white"
                                href="/fintech"
                              >
                                Fintech
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div class="col-lg-3 col-md-6 mb-4 mb-md-0 ">
                          <ul class="list-unstyled mb-0">
                            <li>
                              <p class="text-white FooterHeads contact m-0">
                                About Us
                              </p>
                            </li>
                            <li>
                              <a
                                class="HashLinkFooter text-white"
                                href="/aboutus"
                              >
                                The Rhibhus Factor
                              </a>
                            </li>
                            <li>
                              <a
                                class="HashLinkFooter text-white"
                                href="/aboutus#OurTeam"
                              >
                                Our Team
                              </a>
                            </li>
                            <li>
                              <a
                                class="HashLinkFooter text-white"
                                href="/careers"
                              >
                                Careers
                              </a>
                            </li>
                            <li>
                              <a
                                class="HashLinkFooter text-white"
                                href="/techstack"
                              >
                                Tech Stack
                              </a>
                            </li>
                            <li>
                              <a
                                class="HashLinkFooter text-white"
                                href="/privacy"
                              >
                                Privacy Policy
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div class="col-lg-3 col-md-6 mb-4 mb-md-0  ContactOff">
                          <ul class="list-unstyled mb-0">
                            {/* <li>
                              <p class="text-white FooterHeads contact m-0">
                                Contact Us
                              </p>
                            </li> */}
                            <li>
                              <a
                                class="HashLinkFooter text-white FooterHeads"
                                href="/contactus"
                              >
                                Contact Us
                              </a>
                            </li>
                          </ul>
                          <p class="address mb-0">
                            <a className="mailLink" href="mailto:info@rhibhus.com">E:info@rhibhus.com</a> <br />Office - 43, Residency Rd, Shanthala
                            Nagar, Ashok Nagar, Bengaluru, Karnataka 560025
                          </p>
                        </div>


                        <div class="col-lg-3 col-md-6 mb-4 mb-md-0 ContactOff ">
                          <ul class="list-unstyled mb-0">
                            <li>
                              <p class="text-white FooterHeads contact m-0">
                                Connect with Us
                              </p>
                            </li>
                            <li>
                              <div className="d-flex justify-content-start align-items-center p-3">
                                <a
                                  class="HashLinkFooter me-3"
                                  href="https://www.linkedin.com/company/rhibhus/"
                                >
                                  <img src={linkedin} alt="linkedin" />
                                </a>
                                <a
                                  class="HashLinkFooter me-3"
                                  href="https://www.twitter.com/Rhibhus_"
                                >
                                  <img src={twitter} alt="twitter" />
                                </a>
                                <a
                                  class="HashLinkFooter me-3"
                                  href="https://www.facebook.com/rhibhusinfosystem"
                                >
                                  <img src={facebook} alt="facebook" />
                                </a>
                              </div>
                            </li>
                          </ul>
                        </div>

                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </footer>
            <Button onClick={topFunction} id="movetop" title="Go to top">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="35"
                fill="currentColor"
                class="bi bi-arrow-up"
                viewBox="-1 -2 19 23"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                />
              </svg>
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
