import NavDropdown from "react-bootstrap/NavDropdown";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
// import { HashLink } from "react-router-hash-link";
import logo from "../images/RhibhusLogos.svg";
import { useEffect, useRef, useState } from "react";

const Navigation = () => {
  const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(true);
  const navbarRef = useRef(null);

  const handleNavbarToggle = () => {
    setIsNavbarCollapsed(!isNavbarCollapsed);
  };
  const handleOutsideClick = (event) => {
    if (navbarRef.current && !navbarRef.current.contains(event.target)) {
      setIsNavbarCollapsed(true);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);
  return (
    <div className="BringItToCenter p-0">
      <div className="RootLayout">
        <Navbar ref={navbarRef} collapseOnSelect={true} expanded={!isNavbarCollapsed} expand="lg" id="NavBar" className="Navbar p-0">
          <Navbar.Brand>
            <a href="/">
              <img className="LogoInside Hidden" src={logo} alt="logo" />
            </a>
          </Navbar.Brand>
          <Navbar.Toggle
            className="NavButton"
            onClick={handleNavbarToggle}
            aria-controls="responsive-navbar-nav"
          />

          <Navbar.Collapse
            id="responsive-navbar-nav ">
            <Nav className="me-auto ">
              <a onClick={handleNavbarToggle}
                className="me-2 navTabs CASESTUDIES navWidth "
                href="/#ProductEngineering"
              >
                Product Engineering
              </a>
              <a onClick={handleNavbarToggle}
                className="me-2 navTabs CASESTUDIES navWidth "
                href="/techconsulting/"
              >
                Technical Consulting
              </a>
              <a onClick={handleNavbarToggle}
                className="me-2 navTabs CASESTUDIES navWidth3"
                href="/maintainandsupport"
              >
                Maintenance & Support
              </a>
              <a onClick={handleNavbarToggle}
                className="me-2 navTabs CASESTUDIES navWidth2 "
                href="/cybersecurity"
              >
                Cyber Security
              </a>
              <a onClick={handleNavbarToggle}
                className="me-2  navTabs CASESTUDIES navWidth5"
                href="/casestudies"
              >
                Case Studies & Insights
              </a>

              <NavDropdown show="true" className="AboutUsDropdown" title="About Us">
                <NavDropdown.Item
                  onClick={handleNavbarToggle}
                  className="navTabsContainer navTabsDropdown pt-2 pb-2"
                  href="/aboutus" >
                  The Rhibhus Factor
                </NavDropdown.Item>

                <NavDropdown.Item
                  onClick={handleNavbarToggle}
                  className="navTabsContainer navTabsDropdown pt-2 pb-2"
                  href="/aboutus#OurTeam">
                  Our Team
                </NavDropdown.Item>

                <NavDropdown.Item onClick={handleNavbarToggle}
                  className="navTabsContainer navTabsDropdown pt-2 pb-2"
                  href="/careers">
                  Careers
                </NavDropdown.Item>

                <NavDropdown.Item onClick={handleNavbarToggle} className="navTabsContainer navTabsDropdown pt-2 pb-2"
                  href="/techstack">
                  Tech Stack
                </NavDropdown.Item>

                <NavDropdown.Item onClick={handleNavbarToggle} className="navTabsContainer navTabsDropdown pt-2 pb-2"
                  href="/privacy">
                  Privacy Policy
                </NavDropdown.Item>
              </NavDropdown>

              <a onClick={handleNavbarToggle} className="getinTouch navWidth4" href="#GetInTouch">
                Get in touch
              </a>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </div>
  );
};

export default Navigation;
