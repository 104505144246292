import ProductEngineeringPreMvpImage from "../img/PE_PreMvp.svg";
import ProductEngineeringMvpDev from "../img/PE_MVP.svg";
import ProductEngineeringFullScale from "../img/PE_FullScale.svg";
import TechnicalConsultingRequirementScoping from "../img/TC_ReqScope.svg";
import TechnicalConsultingRe_Arch from "../img/TC_ReArch.svg";
import TechnicalConsultingAudit_Assessment from "../img/TC_AuditAssess.svg";
import MaintenanceSupportCodeReParent from "../img/MS_CodeReParent.svg";
import MaintenanceSupportEnahncement from "../img/MS_Enhancement.svg";
import MaintenanceSupportProductionSupport from "../img/MS_ProductionSupport.svg";
import CyberSecurityVulnerability from "../img/CS_Vulnerability.svg";
import CyberSecurityPenetration from "../img/CS_Penetration.svg";
import CaseStudiesHome from "../img/CaseStudiesHome.svg";
import CircleWatch from "../img/CircleWatch.svg";
import CircleFingerPrint from "../img/CircleFingerPrint.svg";
import CircleLottery from "../img/CircleLottery.svg";

const HomePage = () => {
  return (
    <>
      <div className="BringItToCenter pt-3">
        <div className="RootLayout">
          <div id="HomePage" className="MainLayout">
            <div className="row HomePaddingTopMarginTop Overflow  pb-5 AlignmentPadding">
              <div className="col-lg-6 col-md-12 ps-0 ">
                <p data-aos="slide-right" data-aos-duration="800"
                  data-aos-easing="ease-in-out" className=" HomePage-cardone-textone pb-3 hp">
                  Exceptional
                  product engineering
                  necessary for
                  your business
                  to flourish
                </p>
                <div data-aos="slide-right" data-aos-duration="1200"
                  data-aos-easing="ease-in-out">
                  <p className=" HomePage-cardone-texttwo pb-3">
                    We are a performance focused engineering
                    company with over 90% of our ongoing
                    relationships referred by our customers from
                    across the globe
                  </p>
                  <div data-aos="fade-up" data-aos-duration="1000"
                    data-aos-easing="ease-in-out" className="col-lg-6 col-md-12 MobileOn">
                    <svg className="HomePageImage" xmlns="http://www.w3.org/2000/svg" width="762.307" height="488.993" viewBox="0 0 762.307 488.993">
                      <g id="tech_company-bro" data-name="tech company-bro" transform="translate(-16.18 -10.5)">
                        <g id="freepik--background-simple--inject-73" transform="translate(16.18 27.596)">
                          <path id="Path_15043" data-name="Path 15043" d="M755.826,153.156c-9.627-40.373-29.51-76.472-65.567-98.471-48.188-29.426-111.6-34.821-171.382-19.516-34.109,8.737-66.238,25.3-97.654,42.28-27.918,15.1-61.178,38.854-92.4,42.06-42.259,4.347-125.541-62.854-201.133-51.687S11.277,170.482,16.651,229.67s74.325,64.4,89.221,113.85,96.1,96.491,212.593,77.918,177.249-29.782,231.1-3.687,132.968,38.341,179.6-50.618c18.657-35.617,30.1-74.011,33.009-112.09C764.709,221.75,763.662,186.028,755.826,153.156Z" transform="translate(-16.18 -26.842)" fill="#ffc100" />
                          <path id="Path_15044" data-name="Path 15044" d="M755.826,153.156c-9.627-40.373-29.51-76.472-65.567-98.471-48.188-29.426-111.6-34.821-171.382-19.516-34.109,8.737-66.238,25.3-97.654,42.28-27.918,15.1-61.178,38.854-92.4,42.06-42.259,4.347-125.541-62.854-201.133-51.687S11.277,170.482,16.651,229.67s74.325,64.4,89.221,113.85,96.1,96.491,212.593,77.918,177.249-29.782,231.1-3.687,132.968,38.341,179.6-50.618c18.657-35.617,30.1-74.011,33.009-112.09C764.709,221.75,763.662,186.028,755.826,153.156Z" transform="translate(-16.18 -26.842)" fill="#fff" opacity="0.7" />
                        </g>
                        <g id="freepik--Scheme--inject-73" transform="translate(672.157 59.104)">
                          <rect id="Rectangle_2141" data-name="Rectangle 2141" width="50.608" height="24.272" rx="4.83" transform="translate(19.108)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2142" data-name="Rectangle 2142" width="37.136" height="17.809" rx="4.83" transform="translate(45.967 43.128)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2143" data-name="Rectangle 2143" width="37.136" height="17.809" rx="4.83" transform="translate(45.967 69.464)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2144" data-name="Rectangle 2144" width="37.136" height="17.809" rx="4.83" transform="translate(45.967 95.8)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2145" data-name="Rectangle 2145" width="37.136" height="17.809" rx="4.83" transform="translate(0 43.128)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <line id="Line_817" data-name="Line 817" y2="9.48" transform="translate(43.799 24.775)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <line id="Line_818" data-name="Line 818" y2="7.689" transform="translate(65.326 88.038)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <line id="Line_819" data-name="Line 819" y2="6.82" transform="translate(65.326 61.807)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15045" data-name="Path 15045" d="M659.46,98.291V92.749a3.069,3.069,0,0,1,3.069-3.069h41.285a3.069,3.069,0,0,1,3.069,3.069v5.175" transform="translate(-641.557 -55.362)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                        </g>
                        <g id="freepik--abstract-element--inject-73" transform="translate(471.414 21.662)">
                          <path id="Path_15046" data-name="Path 15046" d="M477.234,46.658a5.144,5.144,0,1,0-4.306,5.866A5.144,5.144,0,0,0,477.234,46.658Z" transform="translate(-449.969 -20.173)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15047" data-name="Path 15047" d="M547.835,85.549a5.154,5.154,0,1,0-4.316,5.866,5.154,5.154,0,0,0,4.316-5.866Z" transform="translate(-446.611 -18.324)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15048" data-name="Path 15048" d="M460.985,95.547a5.154,5.154,0,1,0-.924,3.86,5.154,5.154,0,0,0,.924-3.86Z" transform="translate(-450.742 -17.846)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15049" data-name="Path 15049" d="M492.146,84.331a8.58,8.58,0,1,0-1.565,6.382,8.59,8.59,0,0,0,1.565-6.382Z" transform="translate(-449.585 -18.521)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15050" data-name="Path 15050" d="M484.465,122.339a5.154,5.154,0,1,0-4.305,5.866,5.154,5.154,0,0,0,4.305-5.866Z" transform="translate(-449.626 -16.574)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15051" data-name="Path 15051" d="M588.3,115.249a5.133,5.133,0,1,0-.934,3.828,5.154,5.154,0,0,0,.934-3.828Z" transform="translate(-444.687 -16.912)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15052" data-name="Path 15052" d="M580.464,31.928a5.133,5.133,0,1,0-.934,3.828,5.144,5.144,0,0,0,.934-3.828Z" transform="translate(-445.058 -20.874)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15053" data-name="Path 15053" d="M539.185,25.559a5.154,5.154,0,1,0-4.305,5.866A5.154,5.154,0,0,0,539.185,25.559Z" transform="translate(-447.023 -21.178)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15054" data-name="Path 15054" d="M562.395,45.33a8.569,8.569,0,1,0-1.558,6.381,8.579,8.579,0,0,0,1.558-6.381Z" transform="translate(-446.243 -20.375)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <line id="Line_820" data-name="Line 820" x2="65.169" y2="36.298" transform="translate(26.48 28.915)" fill="none" stroke="#263238" stroke-linejoin="round" stroke-width="1" />
                          <line id="Line_821" data-name="Line 821" y1="28.473" x2="8.36" transform="translate(97.788 35.221)" fill="none" stroke="#263238" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15055" data-name="Path 15055" d="M557.47,54.762l25.938,59.68L576.787,37.33" transform="translate(-445.666 -20.41)" fill="none" stroke="#263238" stroke-linejoin="round" stroke-width="1" />
                          <line id="Line_822" data-name="Line 822" x1="56.684" y2="34.151" transform="translate(34.798 71.614)" fill="none" stroke="#263238" stroke-linejoin="round" stroke-width="1" />
                          <line id="Line_823" data-name="Line 823" y1="35.46" x2="57.637" transform="translate(42.56 30.34)" fill="none" stroke="#263238" stroke-linejoin="round" stroke-width="1" />
                          <line id="Line_824" data-name="Line 824" x1="7.553" y1="26.619" transform="translate(22.96 32.351)" fill="none" stroke="#263238" stroke-linejoin="round" stroke-width="1" />
                          <line id="Line_825" data-name="Line 825" y1="6.83" x2="10.528" transform="translate(115.722 15.349)" fill="none" stroke="#263238" stroke-linejoin="round" stroke-width="1" />
                          <line id="Line_826" data-name="Line 826" x2="16.95" y2="19.967" transform="translate(8.671 82.571)" fill="none" stroke="#263238" stroke-linejoin="round" stroke-width="1" />
                          <line id="Line_827" data-name="Line 827" y1="41.651" x2="15.022" transform="translate(4.366 31.743)" fill="none" stroke="#263238" stroke-linejoin="round" stroke-width="1" />
                          <line id="Line_828" data-name="Line 828" x2="92.176" y2="27.53" transform="translate(41.251 72.368)" fill="none" stroke="#263238" stroke-linejoin="round" stroke-width="1" />
                          <line id="Line_829" data-name="Line 829" x2="38.268" y2="23.067" transform="translate(96.908 73.091)" fill="none" stroke="#263238" stroke-linejoin="round" stroke-width="1" />
                          <line id="Line_830" data-name="Line 830" x2="71.926" y2="1.121" transform="translate(27.266 26.485)" fill="none" stroke="#263238" stroke-linejoin="round" stroke-width="1" />
                          <line id="Line_831" data-name="Line 831" x1="99.467" y2="11.785" transform="translate(25.789 12.615)" fill="none" stroke="#263238" stroke-linejoin="round" stroke-width="1" />
                          <line id="Line_832" data-name="Line 832" x1="32.873" y1="3.059" transform="translate(92.592 7.209)" fill="none" stroke="#263238" stroke-linejoin="round" stroke-width="1" />
                          <line id="Line_833" data-name="Line 833" x1="49.833" y2="93.862" transform="translate(32.493 8.205)" fill="none" stroke="#263238" stroke-linejoin="round" stroke-width="1" />
                          <line id="Line_834" data-name="Line 834" x1="15.787" y2="6.474" transform="translate(10.243 71.226)" fill="none" stroke="#263238" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15056" data-name="Path 15056" d="M504.408,46.523a2.409,2.409,0,1,0-2.022,2.745,2.409,2.409,0,0,0,2.022-2.745Z" transform="translate(-448.418 -20.07)" fill="#263238" />
                          <path id="Path_15057" data-name="Path 15057" d="M490.388,106.473a2.4,2.4,0,1,0-.435,1.791,2.409,2.409,0,0,0,.435-1.791Z" transform="translate(-449.084 -17.218)" fill="#263238" />
                          <path id="Path_15058" data-name="Path 15058" d="M549.948,70.093a2.409,2.409,0,1,0-2.011,2.745A2.409,2.409,0,0,0,549.948,70.093Z" transform="translate(-446.251 -18.948)" fill="#263238" />
                          <path id="Path_15059" data-name="Path 15059" d="M555.218,108.523a2.409,2.409,0,1,0-2.011,2.745,2.409,2.409,0,0,0,2.011-2.745Z" transform="translate(-446.001 -17.12)" fill="#263238" />
                          <path id="Path_15060" data-name="Path 15060" d="M465.908,68.223a2.409,2.409,0,1,0-2.011,2.745,2.409,2.409,0,0,0,2.011-2.745Z" transform="translate(-450.249 -19.037)" fill="#263238" />
                          <path id="Path_15061" data-name="Path 15061" d="M578.768,47.173a2.389,2.389,0,1,0-.431,1.793,2.409,2.409,0,0,0,.431-1.793Z" transform="translate(-444.88 -20.039)" fill="#263238" />
                        </g>
                        <g id="freepik--Cloud--inject-73" transform="translate(345.942 36.275)">
                          <path id="Path_15062" data-name="Path 15062" d="M417.667,70.957c0-.524.073-1.048.073-1.571a34.235,34.235,0,0,0-67.2-9.25,21.789,21.789,0,0,0-.136,43.338v.136h67.044a16.342,16.342,0,0,0,.251-32.674Z" transform="translate(-330.968 -35.128)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15063" data-name="Path 15063" d="M400.129,95.255,379.889,67.83,359.64,95.255h7.909v13.5H392.23v-13.5Z" transform="translate(-329.604 -33.572)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                        </g>
                        <g id="freepik--Server--inject-73" transform="translate(312.401 127.909)">
                          <path id="Path_15064" data-name="Path 15064" d="M330.615,250.4a3.143,3.143,0,1,1-3.143-3.143,3.143,3.143,0,0,1,3.143,3.143Z" transform="translate(-297.743 -116.67)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15065" data-name="Path 15065" d="M339.705,250.4a3.143,3.143,0,1,1-3.143-3.143A3.143,3.143,0,0,1,339.705,250.4Z" transform="translate(-297.31 -116.67)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15066" data-name="Path 15066" d="M348.8,250.4a3.143,3.143,0,1,1-3.143-3.143A3.143,3.143,0,0,1,348.8,250.4Z" transform="translate(-296.878 -116.67)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2146" data-name="Rectangle 2146" width="44.899" height="8.161" transform="translate(68.7 129.197)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2147" data-name="Rectangle 2147" width="26.765" height="8.161" transform="translate(117.233 129.197)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2148" data-name="Rectangle 2148" width="135.65" height="16.824" transform="translate(17.432)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2149" data-name="Rectangle 2149" width="135.65" height="8.412" transform="translate(17.432 16.824)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2150" data-name="Rectangle 2150" width="135.65" height="16.824" transform="translate(17.432 25.152)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2151" data-name="Rectangle 2151" width="135.65" height="8.412" transform="translate(17.432 41.976)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2152" data-name="Rectangle 2152" width="135.65" height="16.824" transform="translate(17.432 50.304)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2153" data-name="Rectangle 2153" width="135.65" height="8.412" transform="translate(17.432 67.128)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2154" data-name="Rectangle 2154" width="135.65" height="16.824" transform="translate(17.432 75.467)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2155" data-name="Rectangle 2155" width="135.65" height="8.412" transform="translate(17.432 92.291)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2156" data-name="Rectangle 2156" width="135.65" height="16.824" transform="translate(17.432 100.619)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2157" data-name="Rectangle 2157" width="135.65" height="8.412" transform="translate(17.432 117.443)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2158" data-name="Rectangle 2158" width="135.65" height="16.824" transform="translate(17.432 125.771)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2159" data-name="Rectangle 2159" width="135.65" height="8.412" transform="translate(17.432 142.595)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2160" data-name="Rectangle 2160" width="135.65" height="16.824" transform="translate(17.432 150.923)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2161" data-name="Rectangle 2161" width="135.65" height="8.412" transform="translate(17.432 167.747)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2162" data-name="Rectangle 2162" width="135.65" height="16.824" transform="translate(17.432 176.086)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2163" data-name="Rectangle 2163" width="135.65" height="8.412" transform="translate(17.432 192.91)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2164" data-name="Rectangle 2164" width="135.65" height="16.824" transform="translate(17.432 201.238)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2165" data-name="Rectangle 2165" width="135.65" height="8.412" transform="translate(17.432 218.062)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15067" data-name="Path 15067" d="M330.615,131.333a3.143,3.143,0,1,1-3.143-3.143,3.143,3.143,0,0,1,3.143,3.143Z" transform="translate(-297.743 -122.334)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15068" data-name="Path 15068" d="M339.705,131.333a3.143,3.143,0,1,1-3.143-3.143A3.143,3.143,0,0,1,339.705,131.333Z" transform="translate(-297.31 -122.334)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15069" data-name="Path 15069" d="M348.8,131.333a3.143,3.143,0,1,1-3.143-3.143A3.143,3.143,0,0,1,348.8,131.333Z" transform="translate(-296.878 -122.334)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2166" data-name="Rectangle 2166" width="44.899" height="8.161" transform="translate(68.7 4.463)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2167" data-name="Rectangle 2167" width="26.765" height="8.161" transform="translate(117.233 4.463)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15070" data-name="Path 15070" d="M330.615,155.143A3.143,3.143,0,1,1,327.473,152a3.143,3.143,0,0,1,3.143,3.143Z" transform="translate(-297.743 -121.201)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15071" data-name="Path 15071" d="M339.705,155.143A3.143,3.143,0,1,1,336.563,152,3.143,3.143,0,0,1,339.705,155.143Z" transform="translate(-297.31 -121.201)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15072" data-name="Path 15072" d="M348.8,155.143A3.143,3.143,0,1,1,345.653,152,3.143,3.143,0,0,1,348.8,155.143Z" transform="translate(-296.878 -121.201)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2168" data-name="Rectangle 2168" width="44.899" height="8.161" transform="translate(68.7 29.416)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2169" data-name="Rectangle 2169" width="26.765" height="8.161" transform="translate(117.233 29.416)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <circle id="Ellipse_296" data-name="Ellipse 296" cx="3.174" cy="3.174" r="3.174" transform="translate(26.524 55.72)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <circle id="Ellipse_297" data-name="Ellipse 297" cx="3.174" cy="3.174" r="3.174" transform="translate(36.047 55.72)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <circle id="Ellipse_298" data-name="Ellipse 298" cx="3.174" cy="3.174" r="3.174" transform="translate(45.569 55.72)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2170" data-name="Rectangle 2170" width="44.899" height="8.161" transform="translate(68.7 54.358)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2171" data-name="Rectangle 2171" width="26.765" height="8.161" transform="translate(117.233 54.358)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <circle id="Ellipse_299" data-name="Ellipse 299" cx="3.174" cy="3.174" r="3.174" transform="translate(26.524 80.673)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <circle id="Ellipse_300" data-name="Ellipse 300" cx="3.174" cy="3.174" r="3.174" transform="translate(36.047 80.673)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <circle id="Ellipse_301" data-name="Ellipse 301" cx="3.174" cy="3.174" r="3.174" transform="translate(45.569 80.673)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2172" data-name="Rectangle 2172" width="44.899" height="8.161" transform="translate(68.7 79.311)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2173" data-name="Rectangle 2173" width="26.765" height="8.161" transform="translate(117.233 79.311)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15073" data-name="Path 15073" d="M330.615,226.593a3.143,3.143,0,1,1-3.143-3.143,3.143,3.143,0,0,1,3.143,3.143Z" transform="translate(-297.743 -117.803)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15074" data-name="Path 15074" d="M339.705,226.593a3.143,3.143,0,1,1-3.143-3.143,3.143,3.143,0,0,1,3.143,3.143Z" transform="translate(-297.31 -117.803)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15075" data-name="Path 15075" d="M348.8,226.593a3.143,3.143,0,1,1-3.143-3.143,3.143,3.143,0,0,1,3.143,3.143Z" transform="translate(-296.878 -117.803)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2174" data-name="Rectangle 2174" width="44.899" height="8.161" transform="translate(68.7 104.254)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2175" data-name="Rectangle 2175" width="26.765" height="8.161" transform="translate(117.233 104.254)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15076" data-name="Path 15076" d="M308.326,131H304.24a5.238,5.238,0,0,0-5.29,5.238V282.9" transform="translate(-298.95 -122.2)" fill="none" stroke="#263238" stroke-linejoin="round" stroke-width="1" />
                          <line id="Line_835" data-name="Line 835" x1="7.836" transform="translate(9.376 8.8)" fill="none" stroke="#263238" stroke-linejoin="round" stroke-width="4" />
                          <path id="Path_15077" data-name="Path 15077" d="M308.775,179.96H306.03V292.7" transform="translate(-298.613 -119.871)" fill="none" stroke="#263238" stroke-linejoin="round" stroke-width="1" />
                          <line id="Line_836" data-name="Line 836" x1="6.652" transform="translate(10.161 60.078)" fill="none" stroke="#263238" stroke-linejoin="round" stroke-width="4" />
                          <path id="Path_15078" data-name="Path 15078" d="M449.53,203.12h2.347V305.687" transform="translate(-291.787 -118.77)" fill="none" stroke="#263238" stroke-linejoin="round" stroke-width="1" />
                          <line id="Line_837" data-name="Line 837" x2="4.693" transform="translate(153.05 84.35)" fill="none" stroke="#263238" stroke-linejoin="round" stroke-width="4" />
                          <path id="Path_15079" data-name="Path 15079" d="M453.64,154.54h3.75a4.473,4.473,0,0,1,4.473,4.473V302.53" transform="translate(-291.592 -121.081)" fill="none" stroke="#263238" stroke-linejoin="round" stroke-width="1" />
                          <line id="Line_838" data-name="Line 838" x2="8.999" transform="translate(153.05 33.459)" fill="none" stroke="#263238" stroke-linejoin="round" stroke-width="4" />
                        </g>
                        <g id="freepik--stairs-3--inject-73" transform="translate(16.777 264.501)">
                          <path id="Path_15080" data-name="Path 15080" d="M383.891,333.642V322.171H371.048v-11.46H358.215v-11.46H345.372V287.79H332.529V276.319H319.7v-11.46H305.91V252.99H16.75V486.556H422.232V333.642Z" transform="translate(-16.75 -252.99)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                        </g>
                        <g id="freepik--stairs-2--inject-73" transform="translate(308.556 264.941)">
                          <path id="Path_15081" data-name="Path 15081" d="M753.633,486.536V253.41H499.116v11.869h-13.8V276.75H472.487v11.46H459.643v11.46H446.811v11.46H433.968v8.244H421.4V322.6h-.262v8.653H407.611v11.46H394.768v11.46H381.935v11.46H369.092v11.471H356.248v11.46H343.416v11.46H295.28v86.508Z" transform="translate(-295.28 -253.41)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                        </g>
                        <g id="freepik--stairs-1--inject-73" transform="translate(308.221 411.621)">
                          <path id="Path_15082" data-name="Path 15082" d="M423.758,479.875v-5.8H410.915v-11.46H398.072v-11.46H385.239V439.68H372.4V428.22H359.564v-11.46H346.72V405.3H332.934V393.43H294.96v86.445Z" transform="translate(-294.96 -393.43)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                        </g>
                        <g id="freepik--Plant--inject-73" transform="translate(30.675 400.947)">
                          <path id="Path_15083" data-name="Path 15083" d="M39.5,406.189s-1.215,3.342-3.8,3.8-7.134-4.567-4.253-7.144S38.283,404.387,39.5,406.189Z" transform="translate(-29.993 -382.347)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75" />
                          <path id="Path_15084" data-name="Path 15084" d="M42.98,390.415s-3.792,9.271-2.273,13.828,10.937,7.909,13.671,1.98-3.342-14.289-5.615-16.112S43.744,388.9,42.98,390.415Z" transform="translate(-29.525 -382.967)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75" />
                          <path id="Path_15085" data-name="Path 15085" d="M64.337,394.178S57.811,400.107,57,406.037s3.646,8.056,8.2,7.333,6.841-12.309,5.479-17.62S64.337,394.178,64.337,394.178Z" transform="translate(-28.737 -382.78)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75" />
                          <path id="Path_15086" data-name="Path 15086" d="M65.546,425.46s-7.333.3-7.595,3.8,3.143,4.253,6.683,1.215S68.594,425.607,65.546,425.46Z" transform="translate(-28.689 -381.232)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75" />
                          <path id="Path_15087" data-name="Path 15087" d="M58.07,430.02s5.741-2.629,8.328-3.09" transform="translate(-28.682 -381.162)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="0.75" />
                          <path id="Path_15088" data-name="Path 15088" d="M67.182,447.44s-5.364-4.955-8.056-2.713-.765,5.238,3.855,5.6S69.214,449.7,67.182,447.44Z" transform="translate(-28.691 -380.343)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75" />
                          <path id="Path_15089" data-name="Path 15089" d="M58.59,445.35s5.919,2.221,8.066,3.729" transform="translate(-28.658 -380.286)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="0.75" />
                          <path id="Path_15090" data-name="Path 15090" d="M39.834,446.106s7.333-6.841,11.094-3.74,1.048,7.26-5.311,7.721S37.027,449.228,39.834,446.106Z" transform="translate(-29.606 -380.466)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75" />
                          <path id="Path_15091" data-name="Path 15091" d="M51.505,443.22s-8.161,3.059-11.125,5.144" transform="translate(-29.524 -380.387)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="0.75" />
                          <path id="Path_15092" data-name="Path 15092" d="M48.36,432.558s-.754,3.646-3.5,5.018-7.595-1.519-5.007-5.929S46.987,430.736,48.36,432.558Z" transform="translate(-29.584 -381.039)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75" />
                          <path id="Path_15093" data-name="Path 15093" d="M42.413,421.618s-1.372-3.038-6.694-1.048-7.438,5.007-4.1,6.076S42.413,421.618,42.413,421.618Z" transform="translate(-30.017 -381.496)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75" />
                          <path id="Path_15094" data-name="Path 15094" d="M41.274,421.41s-5.636.608-8.684,3.143" transform="translate(-29.894 -381.424)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="0.75" />
                          <path id="Path_15095" data-name="Path 15095" d="M56.35,386.9s.922,2.1,2.745,1.666,2.273-3.2,5.165-2.43,5.343,4.107,5.343,2.587-3.656-5.479-7.752-5.479S56.507,384.916,56.35,386.9Z" transform="translate(-28.764 -383.24)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75" />
                          <path id="Path_15096" data-name="Path 15096" d="M41.85,421.544s6.537-1.257,9.271,6.013a21.057,21.057,0,0,1,.681,2.221" transform="translate(-29.454 -381.422)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="0.75" />
                          <path id="Path_15097" data-name="Path 15097" d="M67.92,414.9s1.362-3.038,6.683-1.048,7.448,5.007,4.106,6.076S67.92,414.9,67.92,414.9Z" transform="translate(-28.214 -381.816)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75" />
                          <path id="Path_15098" data-name="Path 15098" d="M69.08,415s5.479-.3,8.066,2.9" transform="translate(-28.159 -381.73)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="0.75" />
                          <path id="Path_15099" data-name="Path 15099" d="M68.126,414.824a8.538,8.538,0,0,0-4.536.618" transform="translate(-28.42 -381.741)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="0.75" />
                          <path id="Path_15100" data-name="Path 15100" d="M45.765,409.811a12.571,12.571,0,0,0-6.38-3.771s-4.683-1.236-6.505.44" transform="translate(-29.881 -382.176)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="0.75" />
                          <line id="Line_839" data-name="Line 839" x1="0.283" y1="1.268" transform="translate(16.251 9.533)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="0.75" />
                          <path id="Path_15101" data-name="Path 15101" d="M53.182,460.67s.681-26.189,0-34.119A86.948,86.948,0,0,0,49.327,409.3L46.09,394.83" transform="translate(-29.252 -382.689)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="0.75" />
                          <line id="Line_840" data-name="Line 840" y1="1.257" x2="0.587" transform="translate(37.444 15.158)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="0.75" />
                          <path id="Path_15102" data-name="Path 15102" d="M54.38,460.39s.23-23.874,1.131-30.694,4.546-17.484,4.546-17.484l5.573-11.942" transform="translate(-28.858 -382.43)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="0.75" />
                          <path id="Path_15103" data-name="Path 15103" d="M58.184,429.88s-1.592,13.744-2.5,29.133" transform="translate(-28.796 -381.021)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="0.75" />
                          <path id="Path_15104" data-name="Path 15104" d="M51.091,458.874s.45-21.831-.461-24.785-2.43-1.655-2.43-1.655-4.19.5-5.73,2.933" transform="translate(-29.424 -380.914)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="0.75" />
                          <path id="Path_15105" data-name="Path 15105" d="M51.69,431c1.194,6.652.094,27.918.094,27.918" transform="translate(-28.986 -380.968)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="0.75" />
                          <path id="Path_15106" data-name="Path 15106" d="M62.626,416.09a9.512,9.512,0,0,0-3.447,4.683c-2.724,7.27-2.409,38.9-2.409,38.9" transform="translate(-28.745 -381.677)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="0.75" />
                          <path id="Path_15107" data-name="Path 15107" d="M45.93,410.45a21.37,21.37,0,0,1,3.855,6.956c3.635,10.476,5,37.5,4.316,42.5" transform="translate(-29.26 -381.946)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="0.75" />
                          <path id="Path_15108" data-name="Path 15108" d="M54.757,419.78c-.22,13.178-.377,39.682-.377,39.682" transform="translate(-28.858 -381.502)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="0.75" />
                          <path id="Path_15109" data-name="Path 15109" d="M54.77,419.815c.147-8.716.325-17.243.5-21.831.45-11.817,1.152-11.146,1.152-11.146s.849-2.661,5.563-2.347" transform="translate(-28.839 -383.182)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="0.75" />
                          <path id="Path_15110" data-name="Path 15110" d="M49.34,428.025s-3.8-3.384-2.284-5.049,10.947-2.891,13.681-.723-3.352,5.238-5.625,5.887S50.094,428.58,49.34,428.025Z" transform="translate(-29.223 -381.44)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75" />
                          <path id="Path_15111" data-name="Path 15111" d="M52.574,425.07a5.532,5.532,0,0,0-.094,1.048" transform="translate(-28.948 -381.25)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="0.75" />
                          <path id="Path_15112" data-name="Path 15112" d="M53.854,421.29a17.189,17.189,0,0,0-.974,2.514" transform="translate(-28.929 -381.43)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="0.75" />
                          <path id="Path_15113" data-name="Path 15113" d="M49.341,449.177s-3.8-3.384-2.326-5.091,10.947-2.891,13.681-.723-3.352,5.238-5.625,5.887S50.1,449.733,49.341,449.177Z" transform="translate(-29.224 -380.436)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75" />
                          <path id="Path_15114" data-name="Path 15114" d="M53.873,442.44a13.712,13.712,0,0,0-1.393,4.965" transform="translate(-28.948 -380.424)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="0.75" />
                          <path id="Path_15115" data-name="Path 15115" d="M52,440.134s-2.587-2.3-1.55-3.436,7.427-1.959,9.281-.492-2.263,3.551-3.813,4A5.981,5.981,0,0,1,52,440.134Z" transform="translate(-29.056 -380.758)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75" />
                          <path id="Path_15116" data-name="Path 15116" d="M55.043,435.56a9.344,9.344,0,0,0-.943,3.373" transform="translate(-28.871 -380.751)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="0.75" />
                          <rect id="Rectangle_2176" data-name="Rectangle 2176" width="10.287" height="22.806" transform="translate(19.373 74.189)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75" />
                        </g>
                        <g id="freepik--device-2--inject-73" transform="translate(448.49 326.318)">
                          <path id="Path_15117" data-name="Path 15117" d="M459.367,312h224.41a10.958,10.958,0,0,1,10.958,10.958v148.9H448.42V322.926A10.958,10.958,0,0,1,459.367,312Z" transform="translate(-427.93 -312)" fill="#263238" stroke="#263238" stroke-miterlimit="10" stroke-width="1" />
                          <rect id="Rectangle_2177" data-name="Rectangle 2177" width="226.118" height="133.743" transform="translate(30.558 10.842)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2178" data-name="Rectangle 2178" width="169.277" height="8.339" transform="translate(59.565 152.159)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2179" data-name="Rectangle 2179" width="169.277" height="4.326" transform="translate(59.565 156.171)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15118" data-name="Path 15118" d="M429.4,469.93s2.776,3.394,11.125,6.181H705.539s6.181-1.55,8.967-5.866Z" transform="translate(-428.834 -304.488)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2180" data-name="Rectangle 2180" width="286.29" height="6.495" rx="1.89" transform="translate(0 159.356)" fill="#969696" stroke="#263238" stroke-miterlimit="10" stroke-width="1" />
                          <path id="Path_15119" data-name="Path 15119" d="M568.2,316.688a2.158,2.158,0,1,0-.628,1.533,2.158,2.158,0,0,0,.628-1.533Z" transform="translate(-422.437 -311.879)" fill="#737373" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15120" data-name="Path 15120" d="M450.658,355.8h0a.629.629,0,0,1-.618-.618V337.258a.629.629,0,0,1,.618-.618h0a.618.618,0,0,1,.618.618v17.924A.618.618,0,0,1,450.658,355.8Z" transform="translate(-427.852 -310.828)" fill="#263238" />
                          <path id="Path_15121" data-name="Path 15121" d="M479.863,332.848V330.68H470.54v15.19h19.631V332.848Z" transform="translate(-426.877 -311.111)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15122" data-name="Path 15122" d="M503.043,332.848V330.68H493.72v15.19h19.642V332.848Z" transform="translate(-425.775 -311.111)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15123" data-name="Path 15123" d="M479.863,353.569V351.39H470.54v15.2h19.631V353.569Z" transform="translate(-426.877 -310.126)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15124" data-name="Path 15124" d="M503.043,353.569V351.39H493.72v15.2h19.642V353.569Z" transform="translate(-425.775 -310.126)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15125" data-name="Path 15125" d="M479.863,374.289V372.11H470.54v15.2h19.631V374.289Z" transform="translate(-426.877 -309.141)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15126" data-name="Path 15126" d="M503.043,374.289V372.11H493.72v15.2h19.642V374.289Z" transform="translate(-425.775 -309.141)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15127" data-name="Path 15127" d="M479.863,395.009V392.83H470.54v15.2h19.631V395.009Z" transform="translate(-426.877 -308.155)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15128" data-name="Path 15128" d="M503.043,395.009V392.83H493.72v15.2h19.642V395.009Z" transform="translate(-425.775 -308.155)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15129" data-name="Path 15129" d="M479.863,415.729V413.55H470.54v15.2h19.631V415.729Z" transform="translate(-426.877 -307.169)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15130" data-name="Path 15130" d="M503.043,415.729V413.55H493.72v15.2h19.642V415.729Z" transform="translate(-425.775 -307.169)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15131" data-name="Path 15131" d="M520.491,381.72h32.59a3.143,3.143,0,0,1,3.143,3.143v38.194a5.646,5.646,0,0,1-5.646,5.646H523.026a5.646,5.646,0,0,1-5.646-5.646V384.831a3.143,3.143,0,0,1,3.111-3.111Z" transform="translate(-424.649 -308.684)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15132" data-name="Path 15132" d="M520.491,381.72h32.59a3.143,3.143,0,0,1,3.143,3.143v1.624H517.38v-1.624a3.143,3.143,0,0,1,3.111-3.143Z" transform="translate(-424.649 -308.684)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15133" data-name="Path 15133" d="M545.514,384.127a1.247,1.247,0,1,0-.365.892A1.257,1.257,0,0,0,545.514,384.127Z" transform="translate(-423.431 -308.629)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15134" data-name="Path 15134" d="M549.375,384.128a1.257,1.257,0,1,0-.372.893,1.268,1.268,0,0,0,.372-.893Z" transform="translate(-423.248 -308.63)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15135" data-name="Path 15135" d="M553.225,384.128a1.268,1.268,0,1,0-1.257,1.257,1.247,1.247,0,0,0,1.257-1.257Z" transform="translate(-423.065 -308.63)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15136" data-name="Path 15136" d="M531.762,391.39l-6.652,5.259,6.652,5.259" transform="translate(-424.281 -308.224)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                          <path id="Path_15137" data-name="Path 15137" d="M540.17,391.39l6.652,5.259-6.652,5.259" transform="translate(-423.565 -308.224)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                          <line id="Line_841" data-name="Line 841" x1="2.011" y2="12.686" transform="translate(110.875 81.93)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                          <line id="Line_842" data-name="Line 842" x2="7.354" transform="translate(120.208 100.619)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <line id="Line_843" data-name="Line 843" x2="9.554" transform="translate(109.324 100.619)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <line id="Line_844" data-name="Line 844" x2="5.919" transform="translate(101.3 100.619)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <line id="Line_845" data-name="Line 845" x2="2.776" transform="translate(97.183 100.619)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <line id="Line_846" data-name="Line 846" x2="4.295" transform="translate(123.267 104.956)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <line id="Line_847" data-name="Line 847" x2="19.296" transform="translate(101.876 104.956)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <line id="Line_848" data-name="Line 848" x2="2.399" transform="translate(97.183 104.956)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <line id="Line_849" data-name="Line 849" x2="14.614" transform="translate(112.949 109.293)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <line id="Line_850" data-name="Line 850" x2="9.554" transform="translate(100.724 109.293)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <line id="Line_851" data-name="Line 851" x2="2.011" transform="translate(97.183 109.293)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <line id="Line_852" data-name="Line 852" x2="4.106" transform="translate(123.456 113.64)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <line id="Line_853" data-name="Line 853" x2="11.659" transform="translate(109.513 113.64)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <line id="Line_854" data-name="Line 854" x2="10.036" transform="translate(97.183 113.64)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15138" data-name="Path 15138" d="M566.621,326.58h32.6a3.143,3.143,0,0,1,3.143,3.143v38.152a5.646,5.646,0,0,1-5.646,5.646H569.156a5.646,5.646,0,0,1-5.646-5.646V329.681A3.143,3.143,0,0,1,566.621,326.58Z" transform="translate(-422.455 -311.306)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15139" data-name="Path 15139" d="M566.621,326.58h32.6a3.143,3.143,0,0,1,3.143,3.143v1.624H563.52v-1.624a3.143,3.143,0,0,1,3.1-3.143Z" transform="translate(-422.454 -311.306)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15140" data-name="Path 15140" d="M591.655,328.988a1.268,1.268,0,1,0-1.268,1.257,1.268,1.268,0,0,0,1.268-1.257Z" transform="translate(-421.237 -311.253)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15141" data-name="Path 15141" d="M595.505,328.988a1.268,1.268,0,1,0-1.257,1.257A1.267,1.267,0,0,0,595.505,328.988Z" transform="translate(-421.054 -311.253)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15142" data-name="Path 15142" d="M599.365,328.988a1.268,1.268,0,1,0-1.268,1.257,1.268,1.268,0,0,0,1.268-1.257Z" transform="translate(-420.87 -311.253)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15143" data-name="Path 15143" d="M577.892,336.25l-6.652,5.259,6.652,5.259" transform="translate(-422.087 -310.846)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                          <path id="Path_15144" data-name="Path 15144" d="M586.3,336.25l6.652,5.259-6.652,5.259" transform="translate(-421.371 -310.846)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                          <line id="Line_855" data-name="Line 855" x1="2.001" y2="12.676" transform="translate(159.209 24.167)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                          <line id="Line_856" data-name="Line 856" x2="7.354" transform="translate(168.533 42.856)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <line id="Line_857" data-name="Line 857" x2="9.554" transform="translate(157.649 42.856)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <line id="Line_858" data-name="Line 858" x2="5.919" transform="translate(149.624 42.856)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <line id="Line_859" data-name="Line 859" x2="2.776" transform="translate(145.507 42.856)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <line id="Line_860" data-name="Line 860" x2="4.295" transform="translate(171.592 47.193)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <line id="Line_861" data-name="Line 861" x2="19.296" transform="translate(150.2 47.193)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <line id="Line_862" data-name="Line 862" x2="2.399" transform="translate(145.507 47.193)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <line id="Line_863" data-name="Line 863" x2="14.614" transform="translate(161.273 51.53)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <line id="Line_864" data-name="Line 864" x2="9.554" transform="translate(149.048 51.53)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <line id="Line_865" data-name="Line 865" x2="2.011" transform="translate(145.507 51.53)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <line id="Line_866" data-name="Line 866" x2="4.106" transform="translate(171.78 55.867)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <line id="Line_867" data-name="Line 867" x2="11.659" transform="translate(157.837 55.867)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <line id="Line_868" data-name="Line 868" x2="10.036" transform="translate(145.507 55.867)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                        </g>
                        <g id="freepik--device-1--inject-73" transform="translate(87.142 11)">
                          <rect id="Rectangle_2181" data-name="Rectangle 2181" width="118.344" height="221.697" rx="7.69" transform="translate(0 14.373)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2182" data-name="Rectangle 2182" width="105.029" height="196.765" transform="translate(6.652 26.839)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2183" data-name="Rectangle 2183" width="17.107" height="17.107" rx="2.12" transform="translate(15.839 100.682)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2184" data-name="Rectangle 2184" width="17.107" height="17.107" rx="2.12" transform="translate(38.844 100.682)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2185" data-name="Rectangle 2185" width="17.107" height="17.107" rx="2.12" transform="translate(61.848 100.682)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2186" data-name="Rectangle 2186" width="17.107" height="17.107" rx="2.12" transform="translate(84.843 100.682)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2187" data-name="Rectangle 2187" width="17.107" height="17.107" rx="2.12" transform="translate(15.839 126.494)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2188" data-name="Rectangle 2188" width="17.107" height="17.107" rx="2.12" transform="translate(38.844 126.494)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2189" data-name="Rectangle 2189" width="17.107" height="17.107" rx="2.12" transform="translate(61.848 126.494)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2190" data-name="Rectangle 2190" width="17.107" height="17.107" rx="2.12" transform="translate(84.843 126.494)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2191" data-name="Rectangle 2191" width="17.107" height="17.107" rx="2.12" transform="translate(15.839 152.306)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2192" data-name="Rectangle 2192" width="17.107" height="17.107" rx="2.12" transform="translate(38.844 152.306)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2193" data-name="Rectangle 2193" width="17.107" height="17.107" rx="2.12" transform="translate(61.848 152.306)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2194" data-name="Rectangle 2194" width="17.107" height="17.107" rx="2.12" transform="translate(84.843 152.306)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2195" data-name="Rectangle 2195" width="17.107" height="17.107" rx="2.12" transform="translate(15.839 178.118)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2196" data-name="Rectangle 2196" width="17.107" height="17.107" rx="2.12" transform="translate(38.844 178.118)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2197" data-name="Rectangle 2197" width="17.107" height="17.107" rx="2.12" transform="translate(61.848 178.118)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2198" data-name="Rectangle 2198" width="17.107" height="17.107" rx="2.12" transform="translate(84.843 178.118)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15145" data-name="Path 15145" d="M113.7,11A15.368,15.368,0,0,0,98.29,26.378c0,8.485,15.368,25.833,15.368,25.833s15.368-17.348,15.368-25.833A15.368,15.368,0,0,0,113.7,11Zm-.43,22.565a14.787,14.787,0,1,0-.042.01Z" transform="translate(-83.236 -11)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2199" data-name="Rectangle 2199" width="82.559" height="56.537" transform="translate(70.438 37.367)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2200" data-name="Rectangle 2200" width="82.559" height="7.228" transform="translate(70.438 86.665)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15146" data-name="Path 15146" d="M205.334,71.5,180.36,57.07V85.91Z" transform="translate(-79.333 -8.808)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <line id="Line_869" data-name="Line 869" x2="74.891" transform="translate(74.744 90.636)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                        </g>
                        <g id="freepik--Tabs--inject-73" transform="translate(37.236 277.563)">
                          <path id="Path_15147" data-name="Path 15147" d="M40.858,299.93h85.9a4.578,4.578,0,0,1,4.557,4.578v78.966H36.28V304.508A4.578,4.578,0,0,1,40.858,299.93Z" transform="translate(-36.28 -263.819)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15148" data-name="Path 15148" d="M131.315,308.572v-3.6a5.039,5.039,0,0,0-5.039-5.039H41.319a5.049,5.049,0,0,0-5.039,5.039v3.6Z" transform="translate(-36.28 -263.819)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <line id="Line_870" data-name="Line 870" y2="3.227" transform="translate(9.868 109.943)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="1" />
                          <line id="Line_871" data-name="Line 871" y2="3.227" transform="translate(17.61 109.943)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="1" />
                          <line id="Line_872" data-name="Line 872" y2="3.227" transform="translate(25.341 109.943)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="1" />
                          <line id="Line_873" data-name="Line 873" y2="3.227" transform="translate(33.082 109.943)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="1" />
                          <line id="Line_874" data-name="Line 874" y2="3.227" transform="translate(40.824 109.943)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="1" />
                          <line id="Line_875" data-name="Line 875" y2="3.227" transform="translate(48.565 109.943)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="1" />
                          <line id="Line_876" data-name="Line 876" y2="3.227" transform="translate(56.296 109.943)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="1" />
                          <line id="Line_877" data-name="Line 877" y2="3.227" transform="translate(64.038 109.943)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="1" />
                          <line id="Line_878" data-name="Line 878" y2="3.227" transform="translate(71.779 109.943)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="1" />
                          <line id="Line_879" data-name="Line 879" y2="3.227" transform="translate(79.521 109.943)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="1" />
                          <line id="Line_880" data-name="Line 880" y2="3.227" transform="translate(87.262 109.943)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="1" />
                          <path id="Path_15149" data-name="Path 15149" d="M119.736,327.906l-8.391,4.515-6.767-13.545-8.705,11.282-7.742-6.767L80.714,315l-7.742,9.03-7.742,21.926L56.851,337.9l-8.381,6.128V364.34h71.266Z" transform="translate(-35.7 -263.102)" fill="#ffc100" />
                          <line id="Line_881" data-name="Line 881" x2="8.768" transform="translate(12.958 51.72)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="1" />
                          <line id="Line_882" data-name="Line 882" x2="8.768" transform="translate(12.958 55.376)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="1" />
                          <line id="Line_883" data-name="Line 883" x2="8.768" transform="translate(12.958 59.021)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="1" />
                          <path id="Path_15150" data-name="Path 15150" d="M119.736,344.9l-8.391,3.1-6.767-15.064-8.705,10.549-7.742-2.577-7.417,16.122-7.742-21.6-7.742,21.6-8.381-8.056L48.47,359.622v7.731h71.266Z" transform="translate(-35.7 -262.249)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15151" data-name="Path 15151" d="M142.147,265.46H254.718a3.143,3.143,0,0,1,3.143,3.143v88.834H138.92V268.6a3.143,3.143,0,0,1,3.227-3.143Z" transform="translate(-31.398 -265.459)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15152" data-name="Path 15152" d="M142.147,265.46H254.718a3.143,3.143,0,0,1,3.143,3.143v6.568H138.92V268.6a3.143,3.143,0,0,1,3.227-3.143Z" transform="translate(-31.398 -265.459)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15153" data-name="Path 15153" d="M172.78,316.242a18.49,18.49,0,0,1-18.05-22.5l6.61,1.467a11.523,11.523,0,0,0-.283,2.546,11.722,11.722,0,0,0,15.567,11.073l2.21,6.4A18.552,18.552,0,0,1,172.78,316.242Z" transform="translate(-30.667 -264.114)" fill="#263238" />
                          <path id="Path_15154" data-name="Path 15154" d="M161.3,295.865l-6.61-1.467a18.49,18.49,0,0,1,33.009-6.872l-5.447,4a11.722,11.722,0,0,0-20.951,4.358Z" transform="translate(-30.647 -264.772)" fill="#b0b0b0" />
                          <path id="Path_15155" data-name="Path 15155" d="M177.82,315.537l-2.21-6.39a11.733,11.733,0,0,0,5.646-17.966l5.468-3.981a18.49,18.49,0,0,1-8.9,28.358Z" transform="translate(-29.652 -264.425)" fill="#ffc100" />
                          <line id="Line_884" data-name="Line 884" x2="22.407" transform="translate(178.097 20.743)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="1" />
                          <rect id="Rectangle_2201" data-name="Rectangle 2201" width="5.385" height="5.385" transform="translate(169.13 18.051)" fill="#263238" />
                          <line id="Line_885" data-name="Line 885" x2="22.407" transform="translate(178.097 33.293)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="1" />
                          <rect id="Rectangle_2202" data-name="Rectangle 2202" width="5.385" height="5.385" transform="translate(169.13 30.611)" fill="#b0b0b0" />
                          <line id="Line_886" data-name="Line 886" x2="22.407" transform="translate(178.097 45.853)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="1" />
                          <rect id="Rectangle_2203" data-name="Rectangle 2203" width="5.385" height="5.385" transform="translate(169.13 43.161)" fill="#ffc100" />
                          <rect id="Rectangle_2204" data-name="Rectangle 2204" width="5.982" height="6.945" transform="translate(113.085 81.041)" fill="#ffc100" />
                          <rect id="Rectangle_2205" data-name="Rectangle 2205" width="5.982" height="4.484" transform="translate(122.283 83.503)" fill="#ffc100" />
                          <rect id="Rectangle_2206" data-name="Rectangle 2206" width="5.982" height="9.418" transform="translate(131.48 78.579)" fill="#ffc100" />
                          <rect id="Rectangle_2207" data-name="Rectangle 2207" width="5.982" height="18.825" transform="translate(140.678 69.162)" fill="#ffc100" />
                          <rect id="Rectangle_2208" data-name="Rectangle 2208" width="5.982" height="22.418" transform="translate(149.876 65.579)" fill="#ffc100" />
                          <rect id="Rectangle_2209" data-name="Rectangle 2209" width="5.982" height="3.143" transform="translate(159.073 84.854)" fill="#ffc100" />
                          <rect id="Rectangle_2210" data-name="Rectangle 2210" width="5.982" height="24.209" transform="translate(168.271 63.777)" fill="#ffc100" />
                          <rect id="Rectangle_2211" data-name="Rectangle 2211" width="5.982" height="5.385" transform="translate(177.469 82.612)" fill="#ffc100" />
                          <rect id="Rectangle_2212" data-name="Rectangle 2212" width="5.982" height="14.792" transform="translate(186.666 73.195)" fill="#ffc100" />
                          <rect id="Rectangle_2213" data-name="Rectangle 2213" width="5.982" height="7.396" transform="translate(195.864 80.591)" fill="#ffc100" />
                          <rect id="Rectangle_2214" data-name="Rectangle 2214" width="5.982" height="21.517" transform="translate(205.061 66.469)" fill="#ffc100" />
                          <rect id="Rectangle_2215" data-name="Rectangle 2215" width="5.982" height="34.067" transform="translate(214.259 53.919)" fill="#ffc100" />
                          <line id="Line_887" data-name="Line 887" x2="22.868" transform="translate(115.777 57.953)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="1" />
                          <line id="Line_888" data-name="Line 888" x2="22.868" transform="translate(115.777 62.887)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="1" />
                        </g>
                        <g id="freepik--character-6--inject-73" transform="translate(552.636 303.981)">
                          <path id="Path_15156" data-name="Path 15156" d="M687.76,331a9.365,9.365,0,0,0-5.919,1.257,8.61,8.61,0,0,0-2.965,2.849c-1.917,3.143-.419,5.007-1.655,6.285s-2.179,1.865-1.98,2.807,1.7,1.676,1.928,1.844-.419,3.206.073,4.84,4.536,1.592,4.536,1.592.461,2.986.576,3.96-.817,8.517,3.268,6.191,6.443-4.609,6.684-5.594-1.048-4-1.048-4.965a2.64,2.64,0,0,1,.388-2.1c.482-.671,3.865-2.839,5.426-6.746a8.653,8.653,0,0,0-4.023-10.79A16.426,16.426,0,0,0,687.76,331Z" transform="translate(-521.287 -288.763)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15157" data-name="Path 15157" d="M685.548,333.578a4.756,4.756,0,0,0-3.038-1.8,9.564,9.564,0,0,1,4.9-.733,16.427,16.427,0,0,1,5.238,1.488,8.653,8.653,0,0,1,4.023,10.79c-1.561,3.907-4.945,6.076-5.426,6.746a2.272,2.272,0,0,0-.356.744c-3.143-.587-3.551-2.451-3.551-2.451a2.682,2.682,0,0,0,2.1.21c1.163-.4,2.3-3.247,1.771-4.965s-2.3-.346-2.724.7-1.268,1.728-1.708,1.4.147-1.446.251-2.263-1.613-2.8-1.854-3.614.712-1.048,1.886-1.687S687.989,336.239,685.548,333.578Z" transform="translate(-520.94 -288.761)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15158" data-name="Path 15158" d="M687.259,347.8a4.976,4.976,0,0,1-2.189,2.42" transform="translate(-520.818 -287.961)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75" />
                          <path id="Path_15159" data-name="Path 15159" d="M679.15,341.13a3.6,3.6,0,0,1,1.77.409" transform="translate(-521.099 -288.278)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75" />
                          <path id="Path_15160" data-name="Path 15160" d="M678.85,339.367a1.844,1.844,0,0,1,2.8.733" transform="translate(-521.114 -288.379)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75" />
                          <path id="Path_15161" data-name="Path 15161" d="M678.25,346.861a1.812,1.812,0,0,0,2.21-.471" transform="translate(-521.142 -288.028)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75" />
                          <path id="Path_15162" data-name="Path 15162" d="M658.915,477.879a67.929,67.929,0,0,0,6.17-3.436,7.7,7.7,0,0,1,0-1.865c.136-.43,1.425-1.435,1.425-1.435s1.152-10.476,1.152-15.473a90.189,90.189,0,0,1,1.142-12.477c.147-.566,4.012-40.551,4.012-40.551l1.3-10.182,12.759.629,12.759-.629,1.289,10.182s3.865,39.986,4.012,40.551a87.911,87.911,0,0,1,1.152,12.477c0,5.018,1.142,15.473,1.142,15.473a7.936,7.936,0,0,1,1.435,1.435,7.7,7.7,0,0,1,0,1.865,67.5,67.5,0,0,0,6.16,3.436c1.048.293.576,1.152-.576,1.728a14.666,14.666,0,0,1-4.871.859c-1.142,0-2.86-2.011-3.289-2.294s-.293,1.435-.293,1.435a4.273,4.273,0,0,1-1.571-.293c-.576-.283-1.582.293-1.718-1.435s-.147-3.436-.147-3.436a6.2,6.2,0,0,1-1.288-1.865c-.293-.859-.859-3.3-.859-3.3l-9.03-36.445-4.305-19.9-4.306,19.9-9.03,36.4s-.576,2.441-.859,3.3a6.411,6.411,0,0,1-1.288,1.865s0,1.718-.147,3.436-1.152,1.152-1.718,1.435a4.359,4.359,0,0,1-1.582.293s.147-1.729-.283-1.435-2.1,2.294-3.3,2.294a14.741,14.741,0,0,1-4.871-.859C658.35,479.031,657.92,478.172,658.915,477.879Z" transform="translate(-522.088 -285.836)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15163" data-name="Path 15163" d="M700.19,379.247l3.646-17.557s7.867,27.362,8.213,28.043a.316.316,0,0,1,0,.126l3.656,11.817-7.176-.21-4.19-7.783-2.022-6.17C701.468,385.135,700.19,379.247,700.19,379.247Z" transform="translate(-520.099 -287.3)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <line id="Line_889" data-name="Line 889" y1="5.332" x2="2.2" transform="translate(180.804 84.174)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15164" data-name="Path 15164" d="M703.539,384.05l-.628.45" transform="translate(-519.969 -286.236)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15165" data-name="Path 15165" d="M709.291,382.9a18.732,18.732,0,0,0-2.609-.765,12.336,12.336,0,0,0-2.483,1.5" transform="translate(-519.908 -286.327)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15166" data-name="Path 15166" d="M669.276,360.17s-9,24.094-9.229,24.964a6.057,6.057,0,0,0-.346,2.514c.115,1.048,1.718,1.708,2.1,1.708s2.849-1.592,3.876-2.1,4.106-1.938,4.106-1.938l1.592-5.133Z" transform="translate(-522.025 -287.372)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <line id="Line_890" data-name="Line 890" x1="0.147" y1="0.587" transform="translate(146.601 80.016)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <line id="Line_891" data-name="Line 891" x1="1.907" y1="7.511" transform="translate(146.999 81.556)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15167" data-name="Path 15167" d="M692.439,356.84l12.885,5.081-5.583,29.657s.45,2.734.115,3.3a3.059,3.059,0,0,1-.911.922h-25.2s-1.708-1.6-1.708-1.938v-1.488l-3.143-32.035,12.686-3.331Z" transform="translate(-521.587 -287.53)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15168" data-name="Path 15168" d="M688.369,357.621l-1.833,5.185,2.472,31.5-3.876,6.149-3.352-6.684,3.457-30.966-2.158-4.641,3.027-1.666Z" transform="translate(-520.974 -287.547)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15169" data-name="Path 15169" d="M691.57,353.06l1.31,2.745-3.991,5.971L686.73,356.6Z" transform="translate(-520.739 -287.71)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15170" data-name="Path 15170" d="M685.11,356.712l-1.718,5.5-3.562-5.72,1.613-3.342Z" transform="translate(-521.067 -287.706)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15171" data-name="Path 15171" d="M692.729,355.68l-2.849,35.89.817,40.164h14.676l-3.666-43.024,2.619-26.734Z" transform="translate(-520.589 -287.586)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15172" data-name="Path 15172" d="M680.524,355.68l2.849,35.89-.807,40.164H667.88l3.667-43.024-2.65-28.326Z" transform="translate(-521.635 -287.586)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15173" data-name="Path 15173" d="M649.268,377.33s16.761,4.6,17.086,4.683a5.447,5.447,0,0,1,2.242,1.76c.272.66.9,3.551-.073,4.986s-5.238,2.912-6.065,2.556L646,383.8Z" transform="translate(-522.676 -286.556)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15174" data-name="Path 15174" d="M650.494,379.017a3.07,3.07,0,0,1-.409,2.42C649.3,382.8,646,383.8,646,383.8l3.268-6.474S650.41,377.33,650.494,379.017Z" transform="translate(-522.676 -286.556)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15175" data-name="Path 15175" d="M648.707,377l1.959,2.1s0,2.148-1.048,2.755-1.54-.073-1.54-.073l-1.048-1.257-4.641-1.875Z" transform="translate(-522.848 -286.572)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15176" data-name="Path 15176" d="M647.62,371.1s-1.309-.733-1.561-1.393-1.655-1.666-2.315-2.409-.251-1.571.911-1.152a20.421,20.421,0,0,1,3.729,2.567Z" transform="translate(-522.799 -287.093)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15177" data-name="Path 15177" d="M712.72,403.934a4.19,4.19,0,0,0,2.1.367c.681-.21,2.326.283,3.321.346s1.393-.775.356-1.435a21.454,21.454,0,0,0-4.326-1.351Z" transform="translate(-519.503 -285.389)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15178" data-name="Path 15178" d="M743.027,414.528a7.333,7.333,0,0,0,1.592-14.247l-111.713-39.9a7.359,7.359,0,0,0-4.955,13.859l111.723,39.912a7.26,7.26,0,0,0,3.352.377Z" transform="translate(-523.767 -287.383)" fill="#263238" />
                          <path id="Path_15179" data-name="Path 15179" d="M578.658,291.329A57.26,57.26,0,1,1,528.7,355.063,57.26,57.26,0,0,1,578.658,291.329ZM590.841,392.22a44.365,44.365,0,1,0-49.382-38.708,44.364,44.364,0,0,0,49.382,38.708Z" transform="translate(-528.276 -290.667)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15180" data-name="Path 15180" d="M580.568,291.1a57.26,57.26,0,1,1-49.959,63.724A57.26,57.26,0,0,1,580.568,291.1ZM592.845,391.99a44.375,44.375,0,1,0-49.382-38.76,44.375,44.375,0,0,0,49.382,38.76Z" transform="translate(-528.185 -290.678)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15181" data-name="Path 15181" d="M643.706,377.637s-1.048,3.656-.3,4.107,6.076,2.1,6.076,2.1a50.294,50.294,0,0,0-1.362-4.861c-.3-.451-.89-.65-.89-.4l-.325.911s-.5-1.907-1.048-1.823a.964.964,0,0,0-.67.66S644.827,376.505,643.706,377.637Z" transform="translate(-522.813 -286.559)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15182" data-name="Path 15182" d="M649.243,383.756s1.048.126.9-.869-1.655-3.9-1.99-3.069S648.761,382.9,649.243,383.756Z" transform="translate(-522.577 -286.445)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15183" data-name="Path 15183" d="M646.73,379.42a7.773,7.773,0,0,0,.66,3.31" transform="translate(-522.642 -286.456)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15184" data-name="Path 15184" d="M645.149,378.31a7.615,7.615,0,0,0,.084,3.729" transform="translate(-522.726 -286.509)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15185" data-name="Path 15185" d="M715.668,400.843s-1.142,3.625-2.032,3.583-6.285-1.613-6.285-1.613a48.737,48.737,0,0,1,3.845-3.279c.5-.21,1.048,0,.953.157l-.23.943s1.477-1.309,1.844-.964a.964.964,0,0,1,.178.922S715.364,399.283,715.668,400.843Z" transform="translate(-519.758 -285.504)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15186" data-name="Path 15186" d="M707.423,402.792s-.943-.482-.262-1.215,3.541-2.315,3.363-1.446S708.3,402.342,707.423,402.792Z" transform="translate(-519.779 -285.483)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15187" data-name="Path 15187" d="M711.868,400.58s-1.33,2-2.388,2.388" transform="translate(-519.657 -285.45)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15188" data-name="Path 15188" d="M713.785,400.54a7.553,7.553,0,0,1-2.1,3.048" transform="translate(-519.551 -285.452)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                        </g>
                        <g id="freepik--characters-5--inject-73" transform="translate(92.244 372.603)">
                          <line id="Line_892" data-name="Line 892" x2="1.749" y2="37.325" transform="translate(178.872 85.813)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                          <line id="Line_893" data-name="Line 893" x2="1.948" y2="37.325" transform="translate(171.026 85.813)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                          <line id="Line_894" data-name="Line 894" x1="2.535" y2="37.325" transform="translate(143.475 85.813)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                          <line id="Line_895" data-name="Line 895" x1="6.024" y2="37.325" transform="translate(134.267 85.813)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                          <path id="Path_15189" data-name="Path 15189" d="M262.267,399.24l.126,39.4H226.66l1.278-39.4Z" transform="translate(-82.232 -354.135)" fill="gray" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2216" data-name="Rectangle 2216" width="42.594" height="2.242" transform="translate(180.161 86.641) rotate(180)" fill="gray" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2217" data-name="Rectangle 2217" width="36.078" height="2.242" transform="translate(173.645 86.641) rotate(180)" fill="gray" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15190" data-name="Path 15190" d="M228.91,417.3s-3.3,5.049-3.824,8.873.346,8.182,2.787,9.742a12.1,12.1,0,0,0,5.238,1.739h20.7a8.318,8.318,0,0,0,5.238-4.347c1.561-3.478.524-8-.524-10.266s-1.917-4.19-1.917-4.19Z" transform="translate(-82.314 -353.276)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15191" data-name="Path 15191" d="M238.587,379.113s-12.173,4.526-12.875,5.741,5.395,25.225,5.395,25.225L227.965,420a139.758,139.758,0,0,0,14.446,2.263,43.8,43.8,0,0,0,14.477-1.362l-2.776-11.659,4.515-24.356s-8.936-4.19-10.905-5S240.012,378.935,238.587,379.113Z" transform="translate(-82.28 -355.096)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15192" data-name="Path 15192" d="M244.582,422.4l2.1-42.688a34.8,34.8,0,0,0-8.642-.6l-1,.377,2.326,42.458q1.257.157,2.546.283A23.14,23.14,0,0,0,244.582,422.4Z" transform="translate(-81.738 -355.095)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15193" data-name="Path 15193" d="M226.169,384.59s-11.136,30.62-9.219,31.668,24.157,1.393,24.157,1.393V415.21l-14.792-5.395,2.954-9.564Z" transform="translate(-82.704 -354.832)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15194" data-name="Path 15194" d="M240,413.851s5.908-.691,6.432-.691,5.238,3.3,5.238,3.3L240,416.292Z" transform="translate(-81.597 -353.473)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15195" data-name="Path 15195" d="M257.769,384.59s11.136,30.62,9.219,31.668-24.178,1.393-24.178,1.393V415.21l14.781-5.395-2.8-9.082Z" transform="translate(-81.464 -354.832)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15196" data-name="Path 15196" d="M261.68,408a8.611,8.611,0,0,1,1.048.094" transform="translate(-80.566 -353.718)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15197" data-name="Path 15197" d="M256.92,408.7a26.985,26.985,0,0,1,4.274-.7" transform="translate(-80.792 -353.718)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15198" data-name="Path 15198" d="M243.34,413.851s-5.919-.691-6.443-.691-5.238,3.3-5.238,3.3l11.659-.168Z" transform="translate(-81.994 -353.473)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15199" data-name="Path 15199" d="M258.555,424.889c-1.393-5.709-6.788-6.767-11.523-7.165a17.714,17.714,0,0,0-7.962,1.048s7.762.4,8.758,5.709l-3.143,7.668s-.6,1.77,2.388,4.526" transform="translate(-81.641 -353.26)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15200" data-name="Path 15200" d="M241.211,427.023a97.825,97.825,0,0,0,1.6,11.094c1.247,6.16,6.767,28.525,7.123,29.405s4.452,6.694,3.74,8.81-1.6,1.938-3.206.346-3.206-8.276-3.384-8.978-9.428-16.552-12.288-23.769-4.63-11.272-3.74-16.552,4.1-5.468,6.589-4.756S241.211,427.023,241.211,427.023Z" transform="translate(-82.033 -353.037)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15201" data-name="Path 15201" d="M252.882,474.121c.388-1.142-.629-3.363-1.7-5.3-1.3,1.959-2.661,1.749-3.625,1.163a13.356,13.356,0,0,0,2.1,4.484C251.279,476.059,252.211,476.226,252.882,474.121Z" transform="translate(-81.238 -350.825)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15202" data-name="Path 15202" d="M251,427.023s1.048,2.1,2.284,8.223,6.086,31.427,6.443,32.276,4.452,6.694,3.74,8.81-1.6,1.938-3.206.346-3.206-8.276-3.384-8.978-9.428-16.552-12.288-23.769-4.63-11.272-3.74-16.552,4.1-5.468,6.589-4.756S251,427.023,251,427.023Z" transform="translate(-81.568 -353.037)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15203" data-name="Path 15203" d="M262.672,474.121c.388-1.142-.629-3.363-1.708-5.3-1.289,1.959-2.661,1.749-3.614,1.163a13.357,13.357,0,0,0,2.1,4.484C261.069,476.059,261.959,476.226,262.672,474.121Z" transform="translate(-80.772 -350.825)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15204" data-name="Path 15204" d="M237.264,379.513s-1.76,4.086-2.1,4.62-1.948,4.086-1.948,4.086a35.546,35.546,0,0,1,3.719-.712c.712,0,4.8,2.1,4.8,2.1s3.907-1.592,4.62-1.414,3.719,1.592,3.719,1.592l-2.692-9.931S239.746,377.2,237.264,379.513Z" transform="translate(-81.92 -355.119)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15205" data-name="Path 15205" d="M234.316,364.545s-1.844,2.661-1.477,4.326-.555,4.253-1.854,4.62,0,2.032.932,2.221l.922.189s1.529,5.384,2.273,5.94,3.52,0,3.52,0,.314,4.19,1.6,6.013,2.1,2.221,3.708-.189a53.272,53.272,0,0,0,3.7-8.14s.555-6.107-.922-10.36S239.68,359.36,234.316,364.545Z" transform="translate(-82.052 -355.885)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15206" data-name="Path 15206" d="M231.908,364.338s2.273,1.372,4.19,2.4a3.887,3.887,0,0,1,1.6,1.613,13.619,13.619,0,0,0,2.787,3.415,2.17,2.17,0,0,1,.4.43c.22.45.45-.115,1.048-.566a1.592,1.592,0,0,1,2.284,1.477c.1,1.592-.23,2.273-1.142,2.5a3.635,3.635,0,0,1-1.477.115s-1.477,9.1.911,12.173,9.428,2.619,10.58.566l2.158-5.919a15.085,15.085,0,0,1-2.661-8.213c0-4.672,1.048-6.652-3.143-11.995s-12.7-2.661-14.289-1.749S230.42,360.357,231.908,364.338Z" transform="translate(-82.001 -356.044)" fill="#263238" />
                          <path id="Path_15207" data-name="Path 15207" d="M234.454,371.569c0,.471-.168.859-.377.859s-.377-.388-.377-.859.168-.859.377-.859S234.454,371.1,234.454,371.569Z" transform="translate(-81.897 -355.492)" fill="#263238" />
                          <path id="Path_15208" data-name="Path 15208" d="M233.49,369.6s.869-.964,1.613.325" transform="translate(-81.907 -355.562)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75" />
                          <path id="Path_15209" data-name="Path 15209" d="M233.91,376.384s.534.22,1.823-.744" transform="translate(-81.887 -355.258)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75" />
                          <line id="Line_896" data-name="Line 896" x1="1.749" y2="37.325" transform="translate(74.639 85.813)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                          <line id="Line_897" data-name="Line 897" x1="1.948" y2="37.325" transform="translate(93.495 85.813)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                          <line id="Line_898" data-name="Line 898" x2="2.525" y2="37.325" transform="translate(104.966 85.813)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                          <line id="Line_899" data-name="Line 899" x2="6.024" y2="37.325" transform="translate(115.075 85.813)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                          <path id="Path_15210" data-name="Path 15210" d="M158.88,399.24l1.676,39.4H192l-3.09-39.4Z" transform="translate(-85.456 -354.135)" fill="gray" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2218" data-name="Rectangle 2218" width="42.688" height="2.242" transform="translate(75.1 84.389)" fill="gray" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2219" data-name="Rectangle 2219" width="25.33" height="2.242" transform="translate(92.469 84.389)" fill="gray" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15211" data-name="Path 15211" d="M164.242,417.935s-1.571,14.247-1.048,16.112a5.007,5.007,0,0,0,3.415,3.467c1.446.4,21.517,0,22.072,0,3.206.063,3.834-1.582,3.949-4.8.115-3.488-.4-15.766-.4-15.766Z" transform="translate(-85.256 -353.293)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15212" data-name="Path 15212" d="M171.248,380.875s-11.8,4.662-11.408,5.437,3.907,23.466,4.033,24.094.388,8.161.388,8.81a2.42,2.42,0,0,0,1.561,1.948c4.054.671,8.475.325,12.571.377h8.318c1.634,0,5.447.765,6.055-1.048.251-1.425.587-32.663.712-34.224s-1.142-1.7-2.818-2.608a81.877,81.877,0,0,0-10.874-4C179.021,379.7,171.248,380.875,171.248,380.875Z" transform="translate(-85.411 -355.066)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15213" data-name="Path 15213" d="M173.977,388.023l2.629,3.677L173.6,414.2l4.735,5,2.619-5.783-3.834-22.1,2.9-3.677-4.861-2.766Z" transform="translate(-84.756 -354.819)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75" />
                          <path id="Path_15214" data-name="Path 15214" d="M171.678,379.742s-1.561.524-1.687,1.561.262,9.2.262,9.2l5.049-1.938,2.2-.524,5.709,1.048a28.609,28.609,0,0,0-1.048-7.019c-.911-2.336-1.948-3.143-2.724-3.237S171.678,379.742,171.678,379.742Z" transform="translate(-84.929 -355.106)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15215" data-name="Path 15215" d="M170.85,379.353a60.655,60.655,0,0,0,1.949,6.223c.513.911,3.886,3.635,4.662,2.462a22.543,22.543,0,0,0,1.687-4.662c.126-.388.262-4.536.262-4.536S173.186,381.04,170.85,379.353Z" transform="translate(-84.887 -355.105)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15216" data-name="Path 15216" d="M173.48,381.5a5.291,5.291,0,0,0,2.3,1.435,13.462,13.462,0,0,1,2.839,1.54l.367-1.393.22-3.74Z" transform="translate(-84.761 -355.082)" fill="#263238" stroke="#263238" stroke-miterlimit="10" stroke-width="1" />
                          <path id="Path_15217" data-name="Path 15217" d="M183.326,370.394a29.825,29.825,0,0,0,0-6.663c-.45-2.43-3.027-3.143-5-3.488s-10.308.45-11.366,2.724,1.2,4.1,1.2,4.1-1.362.911-.754,2.1,1.362,2.881,1.362,2.881l.911-3.478,6.369.147,2.42.608,2.577,3.143s1.362.3,1.823-.3A3.782,3.782,0,0,0,183.326,370.394Z" transform="translate(-85.085 -355.994)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15218" data-name="Path 15218" d="M168.928,370.947s-1.288-1.425-1.288.136a5.416,5.416,0,0,0,1.55,3.143Z" transform="translate(-85.039 -355.509)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15219" data-name="Path 15219" d="M168.68,367.3s.157,8.5.461,10.161,4.065,5.364,4.777,5.364a3.646,3.646,0,0,0,2.724-.45c.911-.754,4.62-3.509,4.924-4.567a21.337,21.337,0,0,0,.45-3.143s2.43-1.215,2.1-3.038-1.215-1.969-1.666-1.509a15.388,15.388,0,0,0-1.215,1.508,5.238,5.238,0,0,0-.911-2.1c-.608-.608-2.42-.911-2.1-1.519l1.666-3.331a14.478,14.478,0,0,1-5.6,2.881,17.461,17.461,0,0,1-5.6-.262Z" transform="translate(-84.99 -355.779)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15220" data-name="Path 15220" d="M172.49,374.27s.084.67,1.739.419" transform="translate(-84.809 -355.323)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75" />
                          <path id="Path_15221" data-name="Path 15221" d="M172.37,377.434a4.756,4.756,0,0,0,5.238-1.194" transform="translate(-84.814 -355.229)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75" />
                          <path id="Path_15222" data-name="Path 15222" d="M171.424,371.195c0,.419-.22.765-.482.765s-.482-.346-.482-.765.22-.765.482-.765S171.424,370.776,171.424,371.195Z" transform="translate(-84.905 -355.505)" fill="#263238" />
                          <path id="Path_15223" data-name="Path 15223" d="M176.094,371.195c0,.419-.21.765-.482.765s-.482-.346-.482-.765.22-.765.482-.765S176.094,370.776,176.094,371.195Z" transform="translate(-84.683 -355.505)" fill="#263238" />
                          <path id="Path_15224" data-name="Path 15224" d="M169.92,369.54a1.749,1.749,0,0,1,2.1-.168" transform="translate(-84.931 -355.569)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75" />
                          <path id="Path_15225" data-name="Path 15225" d="M174.14,369.452a2.441,2.441,0,0,1,2.891.482" transform="translate(-84.73 -355.565)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75" />
                          <path id="Path_15226" data-name="Path 15226" d="M160.234,386s-8.15,23.34-8.15,23.727-1.163,2.587,0,4.012a4.19,4.19,0,0,0,4.41,1.425c1.163-.513,6.579-11.848,6.579-11.848S160.621,387.309,160.234,386Z" transform="translate(-85.804 -354.765)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15227" data-name="Path 15227" d="M192.431,468.934l2.944,3.928s3.939,1.048,3.97,1.446-1.4,1.048-1.4,1.048l-3.824.6s-4.525-1.77-5.6-2.022-2.1,0-1.781-1.226.828-3.855.828-3.855Z" transform="translate(-84.134 -350.824)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15228" data-name="Path 15228" d="M182.346,430.956s3.017-5.385,7.228-7.49,7.878-1.309,11.293-1.309,9.2.524,9.858,1.309-18,47.7-18,47.7l-4.871-.126s3.551-15.766,4.075-19.453,1.844-14.32,1.844-14.32-9.114,0-13.315-.147" transform="translate(-84.429 -353.052)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15229" data-name="Path 15229" d="M165.32,423.76s.136-1.969,4.337-2.1,8.674-.524,9.2,1.98.66,40.729.66,42.049.4,4.463.4,4.463l-4.871.4s-6.285-21.161-7.333-25.885-.922-16.164-.922-17.086" transform="translate(-85.15 -353.073)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15230" data-name="Path 15230" d="M179.475,467.94s1.708,5.783,2.1,6.443.66,1.048-.524,1.833a7.27,7.27,0,0,1-5,.922c-1.446-.4-1.708-2.891-1.446-5a14.75,14.75,0,0,0,0-3.8Z" transform="translate(-84.712 -350.867)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15231" data-name="Path 15231" d="M169.446,412.514s-9-1.77-11.638-2.619-4.861-.744-6.285.513a5.154,5.154,0,0,0-1.236,4.672c.3.691,3.74,1.383,6.055,1.676s9.617-.629,11.073-.838l1.467-.21Z" transform="translate(-85.871 -353.654)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15232" data-name="Path 15232" d="M168.6,412.389s3.824-.409,4.4-.409a18.857,18.857,0,0,1,4.693,1.561c.754.524,2.367,2.1,2.2,2.315s-.471.178-1.048.115-2.148-.283-2.148-.283-3.991.23-5.615.23a14.921,14.921,0,0,1-3.038-.335Z" transform="translate(-85.02 -353.529)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15233" data-name="Path 15233" d="M192,386s8.15,23.34,8.15,23.727,1.163,2.587,0,4.012a4.19,4.19,0,0,1-4.41,1.425c-1.163-.513-3.834-8.234-3.834-8.234S191.868,387.886,192,386Z" transform="translate(-83.885 -354.765)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15234" data-name="Path 15234" d="M183.23,412.514s9-1.77,11.638-2.619,4.871-.744,6.285.513a5.164,5.164,0,0,1,1.247,4.672c-.314.691-3.75,1.383-6.065,1.676s-9.617-.629-11.073-.838l-1.467-.21Z" transform="translate(-84.298 -353.654)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15235" data-name="Path 15235" d="M183.743,412.389s-3.824-.409-4.4-.409a18.959,18.959,0,0,0-4.693,1.561c-.744.524-2.367,2.1-2.189,2.315s.461.178,1.048.115,2.1-.283,2.1-.283,3.991.23,5.615.23a14.921,14.921,0,0,0,3.038-.335Z" transform="translate(-84.811 -353.529)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <line id="Line_900" data-name="Line 900" x1="6.024" y2="37.325" transform="translate(10.497 85.813)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                          <line id="Line_901" data-name="Line 901" x1="6.024" y2="37.325" transform="translate(30.715 85.813)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                          <line id="Line_902" data-name="Line 902" x2="6.024" y2="37.325" transform="translate(45.098 85.813)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                          <line id="Line_903" data-name="Line 903" x2="6.024" y2="37.325" transform="translate(66.091 85.813)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                          <path id="Path_15236" data-name="Path 15236" d="M95.05,399.24l8.674,39.4H132.05l-10.078-39.4Z" transform="translate(-88.492 -354.135)" fill="gray" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2220" data-name="Rectangle 2220" width="53.583" height="2.242" transform="translate(15.232 84.389)" fill="gray" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2221" data-name="Rectangle 2221" width="18.133" height="2.242" transform="translate(50.681 84.389)" fill="gray" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15237" data-name="Path 15237" d="M133.728,383.25s2.5,6.285,3.143,7.647c.723,1.613,4.033,11.576,4.033,11.576L147.126,389l3.363,1.425s-5.311,16.761-6.474,19.181-.911,4.4-2.724,4.274-8.611-6.621-8.611-6.621Z" transform="translate(-86.702 -354.896)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <line id="Line_904" data-name="Line 904" x1="2.535" y2="4.567" transform="translate(51.635 47.577)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <line id="Line_905" data-name="Line 905" x1="2.095" y2="1.655" transform="translate(51.09 49.274)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <line id="Line_906" data-name="Line 906" x2="3.31" y2="1.645" transform="translate(59.575 36.263)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15238" data-name="Path 15238" d="M146.441,389.223s-.136-3.635.251-4.41,1.812-2.849,1.948-3.373.775-1.425.9-.513a6.82,6.82,0,0,1-.126,2.1,10.277,10.277,0,0,0,1.812-2.1c.136-.524,0-2.336.524-2.336s1.048.388,1.55,1.948a9.973,9.973,0,0,1-.262,4.924,32.383,32.383,0,0,1-2.849,4.4l-.388.786Z" transform="translate(-86.049 -355.117)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15239" data-name="Path 15239" d="M153.215,381.441s-.22-.88-.44-.22-.88,3.206-.88,3.206-3.415,2.315-3.415,2.975v.66" transform="translate(-85.951 -355.004)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15240" data-name="Path 15240" d="M112.049,379.357l-10.759,5.824L107,423.449s-.262.911.388,1.3a8.2,8.2,0,0,0,2.462.513h21.119a4.191,4.191,0,0,0,1.948-1.289,3.258,3.258,0,0,0,.65-2.336c0-.911,2.2-36.969,1.812-37.618a7.993,7.993,0,0,0-1.048-1.3l-11.146-4.4Z" transform="translate(-88.195 -355.13)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15241" data-name="Path 15241" d="M118.89,387.081l2.535,1.739-.712,27.813,4.735,5,3.8-5.783-6.118-27.425,1.708-3.677-4.871-2.755Z" transform="translate(-87.358 -354.956)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75" />
                          <path id="Path_15242" data-name="Path 15242" d="M127.231,359.93l1.561,4.662-.649,1.875,2.1,3.5-1.425.9a56.048,56.048,0,0,1-.8,5.856c-.251.388-4.389.1-4.389.1l-.775,7.773-8.747-5.1v-7.647s-2.86-10.759-2.21-11.659,5.06-2.724,10.371-3.373,6.086-1.163,6.348.388S127.231,359.93,127.231,359.93Z" transform="translate(-87.696 -356.183)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15243" data-name="Path 15243" d="M123.274,371.136a3.436,3.436,0,0,0-.618,1.184,11.907,11.907,0,0,1-2.189-.744,28.212,28.212,0,0,1-1.9-3.436l-1.341.126s1.456,6.621,2.168,7a8.213,8.213,0,0,0,1.7.911c0,.272,2.242.545,2.242.545s4.19-.168,4.4-.555a56.047,56.047,0,0,0,.8-5.856h0C127.737,370.2,124.427,369.848,123.274,371.136Z" transform="translate(-87.437 -355.614)" fill="#263238" stroke="#263238" stroke-miterlimit="10" stroke-width="0.75" />
                          <path id="Path_15244" data-name="Path 15244" d="M128.654,357.206c-.262-1.55-1.048-1.048-6.348-.388s-9.721,2.462-10.371,3.373,2.168,11.659,2.168,11.659v2.1a3.142,3.142,0,0,0,1.048,0,7.585,7.585,0,0,1,1.928-.2s-.5-.838-1.289-2a5.238,5.238,0,0,1-.775-3.886c.262-1.3,1.5-.88,1.875-.356s1.11,2.032,1.76,1.781.775-3.247.775-3.247,2.724-1.812,2.849-2.713a4.745,4.745,0,0,0-.775-2.336l5.7-1.048S128.915,358.767,128.654,357.206Z" transform="translate(-87.694 -356.183)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15245" data-name="Path 15245" d="M126.72,364.715a2.65,2.65,0,0,0-1.54,0" transform="translate(-87.059 -355.783)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75" />
                          <ellipse id="Ellipse_302" data-name="Ellipse 302" cx="0.388" cy="0.838" rx="0.388" ry="0.838" transform="translate(38.446 9.99)" fill="#263238" />
                          <path id="Path_15246" data-name="Path 15246" d="M114.187,377.08l-3.247,2.587,7.909,9.072,4.023-5.185Z" transform="translate(-87.736 -355.189)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15247" data-name="Path 15247" d="M122.33,383.531l2.975,3.9-.911-8.171-1.425-1.687Z" transform="translate(-87.195 -355.166)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15248" data-name="Path 15248" d="M134.605,414.72s3.5.524,3.761.911,3.143,3.625,3.5,4.012.649,4.023.649,4.023-.911.649-1.163.251-3.247-4.138-3.247-4.138-3.761-1.3-4.012-2.1l-.262-.786Z" transform="translate(-86.648 -353.399)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15249" data-name="Path 15249" d="M101.29,384.87s6.484,32.831,7,32.956,27.09.513,27.09.513l.775-2.2-22-5.573-1.425-22.973" transform="translate(-88.195 -354.819)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15250" data-name="Path 15250" d="M144.964,425.31s14,3.31,15.033,3.939.513,2.933-.126,4.84-20.48,35.869-20.48,35.869l-2.986-.765,9.114-32.181H134.08Z" transform="translate(-86.636 -352.895)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15251" data-name="Path 15251" d="M139.414,467.965l2.336,5.343s3.237,2.42,3.143,2.8-1.687.388-1.687.388l-3.761-.89s-3.5-3.31-4.41-3.949-1.938-.765-1.163-1.781a28.727,28.727,0,0,1,2.588-2.671Z" transform="translate(-86.659 -350.902)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15252" data-name="Path 15252" d="M107.139,422.64s-.649,7-.649,8.779a6.621,6.621,0,0,0,4.023,5.594c2.588,1.048,32.778,0,32.778,0l11.932,36.665,3.5-1.268s-4.41-39.944-4.41-40.855-.388-4.075-1.687-4.7-21.9-3.7-21.9-3.7Z" transform="translate(-87.948 -353.022)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15253" data-name="Path 15253" d="M156.509,470.12s6.736.89,7.511.765,3.237-.639,2.462.629-5.835,2.671-7.909,3.562-4.138,2.294-4.787,1.278-.775-4.965-.775-4.965Z" transform="translate(-85.735 -350.763)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15254" data-name="Path 15254" d="M291.79,417.16H91.61l1.917,60.5h2.65l1.634-51.4H287.924l1.624,51.4h2.661l1.917-60.5Z" transform="translate(-88.656 -353.283)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2222" data-name="Rectangle 2222" width="207.418" height="2.137" transform="translate(0 62.882)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2223" data-name="Rectangle 2223" width="30.757" height="17.505" transform="translate(75.718 45.503)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15255" data-name="Path 15255" d="M233.007,399.62H202.25l4.442,17.5h30.757Z" transform="translate(-83.393 -354.117)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                        </g>
                        <g id="freepik--character-4--inject-73" transform="translate(627.268 112.36)">
                          <path id="Path_15256" data-name="Path 15256" d="M600.316,254.7a68.99,68.99,0,0,0,6.16-3.436,7.7,7.7,0,0,1,0-1.865c.147-.43,1.435-1.435,1.435-1.435s1.142-10.476,1.142-15.473a87.53,87.53,0,0,1,1.152-12.466c.136-.576,4.012-40.562,4.012-40.562l1.289-10.182,12.759.629,12.759-.629,1.289,10.182s3.876,39.986,4.012,40.562a87.53,87.53,0,0,1,1.152,12.466c0,5.018,1.142,15.473,1.142,15.473a7.936,7.936,0,0,1,1.435,1.435,7.7,7.7,0,0,1,0,1.865,68.982,68.982,0,0,0,6.16,3.436c1.047.293.576,1.152-.566,1.729a14.792,14.792,0,0,1-4.871.859c-1.152,0-2.87-2.011-3.3-2.294s-.283,1.435-.283,1.435a4.357,4.357,0,0,1-1.582-.293c-.576-.283-1.571.293-1.718-1.435s-.147-3.436-.147-3.436a6.2,6.2,0,0,1-1.288-1.865c-.283-.859-.859-3.289-.859-3.289l-9.03-36.4-4.306-19.9-4.305,19.9-9.03,36.4s-.576,2.43-.859,3.289a6.2,6.2,0,0,1-1.288,1.865s0,1.718-.147,3.436-1.142,1.152-1.718,1.435a4.306,4.306,0,0,1-1.571.293s.136-1.728-.293-1.435-2.1,2.294-3.289,2.294a14.8,14.8,0,0,1-4.882-.859C599.74,255.851,599.31,254.992,600.316,254.7Z" transform="translate(-599.509 -104.831)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <line id="Line_907" data-name="Line 907" y1="5.332" x2="2.2" transform="translate(44.773 42)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15257" data-name="Path 15257" d="M633.839,133.66l12.885,5.081-5.594,29.646s.461,2.734.115,3.31a3.143,3.143,0,0,1-.911.911H615.14s-1.708-1.6-1.708-1.938v-1.488l-3.143-32.035,12.759-3.331Z" transform="translate(-599.008 -106.525)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15258" data-name="Path 15258" d="M629.759,134.451l-1.833,5.175,2.483,31.5-3.886,6.149-3.342-6.683,3.446-30.966-2.158-4.641,3.028-1.666Z" transform="translate(-598.395 -106.541)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15259" data-name="Path 15259" d="M623.64,125.86l-.88,4.305,5.332,4.243,3.9-4.044.2-2.158Z" transform="translate(-598.414 -106.896)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15260" data-name="Path 15260" d="M632.97,129.88l1.309,2.745-4,5.982-2.158-5.185Z" transform="translate(-598.16 -106.705)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15261" data-name="Path 15261" d="M626.51,133.532l-1.729,5.5-3.562-5.72,1.613-3.342Z" transform="translate(-598.488 -106.7)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15262" data-name="Path 15262" d="M634.13,132.5l-2.86,35.89.817,40.164h14.687L643.1,165.53l2.629-26.734Z" transform="translate(-598.01 -106.58)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15263" data-name="Path 15263" d="M621.914,132.5l2.86,35.89-.817,40.164H609.27l3.677-43.024L610.286,137.2Z" transform="translate(-599.056 -106.58)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15264" data-name="Path 15264" d="M619.9,120.174a32.089,32.089,0,0,1-1.4-6.924c0-2.608,2.462-3.928,4.431-4.662s10.759-1.624,12.319.471-.388,4.484-.388,4.484,1.6.649,1.236,2.032-.807,3.268-.807,3.268l-1.676-3.415-6.547,1.508-2.378,1.131L622.7,121.9s-1.341.6-1.938.073a3.992,3.992,0,0,1-.859-1.8Z" transform="translate(-598.617 -107.757)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15265" data-name="Path 15265" d="M634.295,113.85s1.645,8.81,1.676,10.591-3.027,6.359-3.813,6.516-1.812.7-2.912.1-5.51-2.65-6.055-3.677a23.319,23.319,0,0,1-1.142-3.143s-2.755-.744-2.828-2.692.838-2.284,1.4-1.917a18.105,18.105,0,0,1,1.571,1.309,5.469,5.469,0,0,1,.492-2.378c.5-.754,2.315-1.456,1.875-2.011l-2.43-3.143s3.488,1.9,6.4,1.791A18.2,18.2,0,0,0,634.295,113.85Z" transform="translate(-598.583 -107.488)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15266" data-name="Path 15266" d="M630.672,118.59l1.529,5.039a4.19,4.19,0,0,1-2.231.356" transform="translate(-598.072 -107.242)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75" />
                          <path id="Path_15267" data-name="Path 15267" d="M632.045,125.646s-1.288,1.289-3.865-.126" transform="translate(-598.157 -106.912)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75" />
                          <path id="Path_15268" data-name="Path 15268" d="M631.671,118.55c.084.44.377.744.65.691s.429-.45.346-.89-.388-.744-.66-.681S631.577,118.121,631.671,118.55Z" transform="translate(-597.992 -107.286)" fill="#263238" />
                          <path id="Path_15269" data-name="Path 15269" d="M626.841,119.541c.084.44.377.744.66.691s.419-.461.335-.89-.388-.744-.66-.691S626.747,119.111,626.841,119.541Z" transform="translate(-598.222 -107.239)" fill="#263238" />
                          <path id="Path_15270" data-name="Path 15270" d="M632.919,116.521a1.823,1.823,0,0,0-2.189.272" transform="translate(-598.035 -107.352)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75" />
                          <path id="Path_15271" data-name="Path 15271" d="M628.581,117.325a2.556,2.556,0,0,0-2.881,1.1" transform="translate(-598.275 -107.307)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75" />
                          <path id="Path_15272" data-name="Path 15272" d="M623.79,114.05S620.3,116,620.06,117.936s5.636,4.19,5.636,4.19Z" transform="translate(-598.543 -107.458)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2224" data-name="Rectangle 2224" width="17.369" height="8.978" rx="3.47" transform="translate(23.052 5.897) rotate(-2.99)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2225" data-name="Rectangle 2225" width="14.603" height="6.086" rx="2.87" transform="translate(24.508 7.258) rotate(-2.99)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.5" />
                          <path id="Path_15273" data-name="Path 15273" d="M666.19,129.149c.262-.649-.66-1.048-1.309-.786a8.684,8.684,0,0,0-2.357,1.833c-.262.4-2.1,3.928-2.1,3.928a13.282,13.282,0,0,1,.524-2.357,1.53,1.53,0,0,0-.66-2.1c-1.048-.649-1.236,1.048-1.456,1.676s-1.32,3.74-1.32,3.74a11.8,11.8,0,0,0,0,3.886l-1.907,3.939,3.478,3.478,3.006-4.934a1.048,1.048,0,0,0,.66-.126c.524-.4,3.007-2.755,3.268-4.326s-2.221-3.143-2.221-3.143l.388-1.833a25.442,25.442,0,0,0,2-2.881Z" transform="translate(-596.852 -106.781)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15274" data-name="Path 15274" d="M663.883,131.86s1.184.66,1.446,1.309-1.048,3.771-1.812,4.033S662.049,135.4,663.883,131.86Z" transform="translate(-596.514 -106.611)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15275" data-name="Path 15275" d="M665.262,133.11s1.435.786,1.435,1.708-.807,2.787-1.414,2.787c-1.446,0-1.142-.838-1.142-.838A34.082,34.082,0,0,0,665.262,133.11Z" transform="translate(-596.447 -106.551)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15276" data-name="Path 15276" d="M660.228,133.86s.911,2.619.649,3.279a3.141,3.141,0,0,1-1.048,1.173" transform="translate(-596.651 -106.515)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15277" data-name="Path 15277" d="M666.9,135.22a3.018,3.018,0,0,1,.451,1.372,2.671,2.671,0,0,1-1.048,1.6c-.461.147-1.048-.691-1.048-.691A7.309,7.309,0,0,0,666.9,135.22Z" transform="translate(-596.393 -106.451)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15278" data-name="Path 15278" d="M645.163,138.51l5.9,11.157,6.432-10.591,4.924,5.112-9.648,19.474s-2.1,2.273-3.216,2.273-6.432-6.432-6.432-6.432Z" transform="translate(-597.446 -106.294)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <line id="Line_908" data-name="Line 908" x1="3.017" y2="5.489" transform="translate(50.598 43.372)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15279" data-name="Path 15279" d="M605.774,178.959s.492,4.662.608,5.405a16.223,16.223,0,0,1,0,4.285c-.241,1.11-1.341,1.351-1.467.859s-.733-2.451-.859-2.818-.251,4.3-.733,5.238-1.477-.492-1.477-.492-2.326-3.069-2.326-4.3,1.55-9.04,1.55-9.04Z" transform="translate(-599.52 -104.411)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15280" data-name="Path 15280" d="M610.719,136.99l-8.988,23.78-1.571,21.014,5.7.388,3.928-20.616,2.745-3.928Z" transform="translate(-599.49 -106.367)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                        </g>
                        <g id="freepik--character-3--inject-73" transform="translate(521.548 113.334)">
                          <path id="Path_15281" data-name="Path 15281" d="M555.921,131.482l-1.372-8.3-4.19.723a24.909,24.909,0,0,0,.325-5.384A12.811,12.811,0,0,0,547.4,113c-.691-.4-3.886,1.215-3.886,1.215s-1.351,2.881-1.854,4.117-1.173,2.43-1.938,2.493-1.519.587-1.121,1.592a8.674,8.674,0,0,0,1.592,2.252s.283,4.861.744,5.51,2.786.765,4.243.545c.639-.094,1.55-.2,2.357-.293a1.3,1.3,0,0,0,.073.534,11.679,11.679,0,0,1-.911,4.871c1.76-1.268,3.3-.974,5.175-1.519,2.1-.639,4.8,2.42,5.9,2.745C557.032,136.049,555.921,131.482,555.921,131.482Z" transform="translate(-496.702 -108.485)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15282" data-name="Path 15282" d="M543.572,124.42a4.306,4.306,0,0,1-2.462.608" transform="translate(-496.578 -107.939)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15283" data-name="Path 15283" d="M547.144,110.586a3.6,3.6,0,0,0-3.667-.65,3.279,3.279,0,0,0-1.907,4.285c.524,1.048,3.143,2.42,3.143,2.42l1.2-1.582a20.254,20.254,0,0,0,1.393,3.583l1.917,3.038,2.944,2.273a17.055,17.055,0,0,0,1.99,3.635c1.519,2.1,1.634,2.1,3.3-.817,1.3-2.273,3.227-4.3,3.635-7.982s-3.143-8.182-7.029-9.68S547.144,110.586,547.144,110.586Z" transform="translate(-496.567 -108.688)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15284" data-name="Path 15284" d="M547.79,122.119s1.98-3.677,3.792-2.336.377,4.19-.8,5.458-2.1-.649-2.1-.649" transform="translate(-496.26 -108.174)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15285" data-name="Path 15285" d="M564.175,119.637c-2,1.268-4.808.419-6.285-1.9s-1.048-5.238.953-6.484,4.808-.419,6.285,1.9S566.166,118.348,564.175,119.637Z" transform="translate(-495.821 -108.593)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15286" data-name="Path 15286" d="M561.46,201.37s-.524,4.473.136,6.285,4.861,14.477,5.521,25.665,1.32,21.454.922,22-2.766.262-2.766.262h-7.888a.953.953,0,0,1-.272-1.184c.4-.66,4.19-3.562,4.745-4.484a10.109,10.109,0,0,0,1.184-2.629c0-.388-.922-10.015-4.085-16.332s-7.239-21.842-7.626-27.5Z" transform="translate(-496.092 -104.279)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15287" data-name="Path 15287" d="M556.814,252.115a27.343,27.343,0,0,1,2.766-2.525v1.341h8.276a6.955,6.955,0,0,1-.115,2.1c-.4.524-2.766.262-2.766.262h-7.888A.953.953,0,0,1,556.814,252.115Z" transform="translate(-495.835 -101.985)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15288" data-name="Path 15288" d="M553.791,202.012s-1.844,13.168-.524,17.117.786,11.052-1.048,18.552-3.289,12.246-3.289,12.634,0,3.949-.4,4.609-11.974.524-12.759.524-1.582-.786-.922-1.582,4.19-1.645,6.579-2.755,2.367-1.32,2.367-1.32,1.32-15.4.8-22.24-3.436-21.056-2.912-25.8Z" transform="translate(-496.887 -104.261)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15289" data-name="Path 15289" d="M535.763,253.167c-.8,0-1.582-.786-.922-1.582.524-.629,2.944-1.309,5.091-2.1l.44.953,8.517-.639a9.554,9.554,0,0,1-.367,2.881C548.135,253.3,536.549,253.167,535.763,253.167Z" transform="translate(-496.888 -101.99)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15290" data-name="Path 15290" d="M560.988,159.38s4.494,9.763,4.882,15.714-2.1,12.361-2.1,12.361l1.571,21.81s-5.238,3.289-14.079,3.143-13.157-1.582-13.157-1.582-1.048-25.519,1.708-35.513,1.781-15.462,1.781-15.462S553.854,160.407,560.988,159.38Z" transform="translate(-496.73 -106.276)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15291" data-name="Path 15291" d="M515,131.671s-2.347-4.306-3-5.343-4.84-5.06-5.887-4.8-.513,1.435.136,1.959a27.454,27.454,0,0,1,2.619,3.143,8.55,8.55,0,0,1,.786,2.21c.136.388-3.09-.89-3.876-1.289s-1.687.922-.911,1.7,3.352,2.2,4,2.849a13.618,13.618,0,0,0,3.007,1.561l1.425,1.3Z" transform="translate(-498.316 -108.077)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15292" data-name="Path 15292" d="M507.031,127.788a12.442,12.442,0,0,1-1.247-.545c-.775-.388-1.687.922-.911,1.7a9.433,9.433,0,0,0,1.247.943Z" transform="translate(-498.316 -107.808)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15293" data-name="Path 15293" d="M506.077,121.53c-1.048.262-.513,1.435.136,1.959a9.43,9.43,0,0,1,1.048,1.152c.388-.492.869-1.11,1.33-1.655C507.554,122.1,506.527,121.426,506.077,121.53Z" transform="translate(-498.272 -108.077)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15294" data-name="Path 15294" d="M547.607,135.767l-11.157,6.516L514.692,131.21l-2.472,5.081s21.36,17.714,22.271,18.626,4.305.911,7.815,1.435S547.607,135.767,547.607,135.767Z" transform="translate(-497.952 -107.616)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15295" data-name="Path 15295" d="M538.038,152.206,532.8,196.351l6.725,1.184.4-49.875S539.012,148.435,538.038,152.206Z" transform="translate(-496.973 -106.834)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15296" data-name="Path 15296" d="M562.985,158.072c.272.094.429.136.429.073,0-.608-.89-13.189-1.917-17.379a29.2,29.2,0,0,0-3.813-8.255s-2.1-1.11-3.059-.492c-.325.189-6.118-.262-9.051,2.6,0,0-5.825,9.837-5.825,13.189s-3.53,50.472-3.53,50.472a35.042,35.042,0,0,0,11.094,1.823c5.311-.23,11.314-.45,13.849-.67a25.813,25.813,0,0,0,5.542-1.341s.691-13.87.691-19.684C567.417,173.911,564.368,162.859,562.985,158.072Z" transform="translate(-496.81 -107.587)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15297" data-name="Path 15297" d="M556.235,129.89s-8.276,1.969-9.931,3.572-6.484,12.456-6.484,12.456,8.276-9.617,9.292-10.36,8.38-2.954,8.38-2.954Z" transform="translate(-496.639 -107.679)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15298" data-name="Path 15298" d="M542.28,178.39h11.764v2.315l-11.565-.189" transform="translate(-496.522 -105.372)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15299" data-name="Path 15299" d="M537.657,120.776s7.961,1.5,8.381.419,1.226-6.107.272-6.505-9.6-3.007-9.753-1.5C535.887,119.3,535.269,120.975,537.657,120.776Z" transform="translate(-496.823 -108.495)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <line id="Line_909" data-name="Line 909" x1="0.911" y2="7.721" transform="translate(40.834 4.56)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15300" data-name="Path 15300" d="M545.686,117.17l14.415,5.123-.87,1.561-13.9-5.1Z" transform="translate(-496.377 -108.284)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15301" data-name="Path 15301" d="M509.138,135.051l-4.065-3.478s-3.373-2.912-4.693-3.373-1.781-.094-1.781.272a1.781,1.781,0,0,0,.943,1.32,25.6,25.6,0,0,1,2.347,1.781s1.592,3.562,1.687,3.845,1.309,2.535,2.252,2.818a21.818,21.818,0,0,1,2.158.838Z" transform="translate(-498.6 -107.77)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15302" data-name="Path 15302" d="M501,130.9l.754.618s1.592,3.562,1.687,3.845,1.31,2.535,2.252,2.818a21.816,21.816,0,0,1,2.158.838L509,135l-4.065-3.478s-1.582-1.362-3-2.367Z" transform="translate(-498.486 -107.714)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15303" data-name="Path 15303" d="M506.11,132.256a36.5,36.5,0,0,0-2.629-4.19c-.849-.943-2.441-2.158-2.818-.943s1.4,2.1,1.4,2.1Z" transform="translate(-498.504 -107.835)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15304" data-name="Path 15304" d="M503.35,127.955s.744-1.226,1.4-.566,2.441,6,2.441,6Z" transform="translate(-498.374 -107.807)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15305" data-name="Path 15305" d="M554.55,139.35a72.888,72.888,0,0,0-8.81,2.42c-2.64,1.1-12.11,1.76-12.11,1.76l-24.88-8.8-1.98,8.381s25.32,10.35,27.3,10.79,22.24.22,22.24.22" transform="translate(-498.211 -107.449)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                        </g>
                        <g id="freepik--character-2--inject-73" transform="translate(214.116 109.954)">
                          <path id="Path_15306" data-name="Path 15306" d="M213.437,141.533s-.513-3.593-1.048-4.3-2.294-2.483-2.514-2.975-1.048-1.268-.985-.346a6.913,6.913,0,0,0,.492,2.011,10.476,10.476,0,0,1-2.147-1.718c-.22-.492-.409-2.294-.922-2.21s-.953.566-1.184,2.189A9.993,9.993,0,0,0,206.251,139a33.053,33.053,0,0,0,3.583,3.834l.513.691Z" transform="translate(-205.128 -104.198)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15307" data-name="Path 15307" d="M205.28,135.083s.063-.911.388-.293,1.435,3,1.435,3,3.771,1.676,3.886,2.326l.126.649" transform="translate(-205.121 -104.076)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15308" data-name="Path 15308" d="M227.352,133.63,222.3,150.779l-9.1-9.365-3.069,1.99,11.314,15.934s2.231,1.54,3.08,1.194,5.51-6.642,5.51-6.642Z" transform="translate(-204.89 -104.12)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15309" data-name="Path 15309" d="M251.092,154.21,255,134.683l-9.648-3.813-5.133-1.76-5.123,1.76-8.443,2.975,2.682,20.291-.178,3-3.394,9.669a15.871,15.871,0,0,0-.911,5.322v9.344l15.274,4.1,15.263-4.086v-9.344a16.144,16.144,0,0,0-.911-5.322l-3.4-9.669Z" transform="translate(-204.19 -104.335)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15310" data-name="Path 15310" d="M255.387,170.816a16.144,16.144,0,0,0-.911-5.322l-3.059-8.674H228.81l-3.048,8.674a15.871,15.871,0,0,0-.911,5.322l.073,38.435H255.3Z" transform="translate(-204.19 -103.017)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15311" data-name="Path 15311" d="M244.544,130.79l-2.724,66.3h15.022l-4.19-39.787,1.54-22.7Z" transform="translate(-203.383 -104.256)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15312" data-name="Path 15312" d="M235.461,130.92l-1.645,66.489H222.67l4.086-63.65Z" transform="translate(-204.294 -104.249)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15313" data-name="Path 15313" d="M246.713,132l-1.76-.807-.168.031-.8-.2-1.152-8.695-6.579.168-.911,8.695-.388.136-.66.042-1.089.5,4.861,7.092Z" transform="translate(-203.792 -104.658)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15314" data-name="Path 15314" d="M235.648,129a3.645,3.645,0,0,0-2.1,1.163c-.7.922-1.98,2.787-1.98,2.787l-1.163,2.787,4.766-.7,3.017,4.651Z" transform="translate(-203.925 -104.341)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15315" data-name="Path 15315" d="M237.85,139.733l5.573-11.523a2.786,2.786,0,0,1,1.98,1.278c.691,1.163,2.787,4.882,2.787,4.882l-4.065.23Z" transform="translate(-203.571 -104.378)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15316" data-name="Path 15316" d="M229.047,198.82s-.681,10.413-.461,15.85,2.043,31.427,2.043,32.6a3.844,3.844,0,0,1-1.11,2.231s-4.065,2.273-6.495,3.729c-.89.534-2.1.828-.094,1.739s12.456,0,12.456,0h2.032a34.858,34.858,0,0,0-.22-3.855,32.2,32.2,0,0,0-.681-3.394c-.23-.9,1.131-29.657,1.582-33.962s-.45-15.169-.45-15.169Z" transform="translate(-204.333 -101.03)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15317" data-name="Path 15317" d="M227,248.54c-1.278.733-2.818,1.624-3.981,2.315-.89.534-2.1.828-.094,1.739s12.456,0,12.456,0h2.032s0-1.54-.126-2.965l-9.1.786Z" transform="translate(-204.333 -98.654)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15318" data-name="Path 15318" d="M240.647,198.82s-.681,10.413-.45,15.85,2.032,31.427,2.032,32.6a3.844,3.844,0,0,1-1.11,2.231s-4.054,2.273-6.495,3.729c-.89.534-2.1.828-.094,1.739s12.456,0,12.456,0h2.032a34.843,34.843,0,0,0-.22-3.855,29.686,29.686,0,0,0-.681-3.394c-.23-.9,1.131-29.657,1.582-33.962s-.45-15.169-.45-15.169Z" transform="translate(-203.781 -101.03)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15319" data-name="Path 15319" d="M238.615,248.54c-1.288.733-2.828,1.624-3.991,2.315-.89.534-2.1.828-.094,1.739s12.456,0,12.456,0h2.032s0-1.54-.126-2.965l-9.1.786Z" transform="translate(-203.781 -98.654)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15320" data-name="Path 15320" d="M253.71,134.43l5.3,20.134-3.08,25.278-3.426-1.288.346-22.847-.911-3Z" transform="translate(-202.901 -104.082)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15321" data-name="Path 15321" d="M250.618,121.3a4.494,4.494,0,1,1-4.494-4.494A4.494,4.494,0,0,1,250.618,121.3Z" transform="translate(-203.392 -104.921)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15322" data-name="Path 15322" d="M237.941,106.632s-3.782.953-5.405,3.708-.346,5.1-.922,8.485a11.607,11.607,0,0,0,2.755,9.03,4.19,4.19,0,0,0,3.876,1.048c1.781-.325,4.5-3.007,5-3.331a8.62,8.62,0,0,0,1.184-2.535s3.394-3.719,3.562-6.8-1.624-7.333-4.19-8.59A12.183,12.183,0,0,0,237.941,106.632Z" transform="translate(-203.872 -105.406)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15323" data-name="Path 15323" d="M234.512,117.42s.45.377.22,1.414-1.341,1.571-.744,2.1a2.263,2.263,0,0,0,1.341.513" transform="translate(-203.763 -104.892)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.5" />
                          <path id="Path_15324" data-name="Path 15324" d="M232,113.977a58.666,58.666,0,0,0,6.652-1.456l3.394-.974s-.964,2.755-.639,4.19a21.339,21.339,0,0,0,1.781,4.054s.932-1.739,1.739-1.561c2.766.6.367,3.509.367,3.509s2.587-.974,2.755-5.51-1.131-9.564-5.238-10.214-6.642-1.456-9.889,2.273S229.412,113.652,232,113.977Z" transform="translate(-203.933 -105.46)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15325" data-name="Path 15325" d="M237.934,118.279c0,.482-.22.859-.482.859s-.492-.377-.492-.859.22-.859.492-.859S237.934,117.808,237.934,118.279Z" transform="translate(-203.614 -104.892)" fill="#263238" />
                          <path id="Path_15326" data-name="Path 15326" d="M233.774,117.769c0,.471-.22.859-.482.859s-.482-.388-.482-.859.21-.859.482-.859S233.774,117.3,233.774,117.769Z" transform="translate(-203.811 -104.916)" fill="#263238" />
                          <path id="Path_15327" data-name="Path 15327" d="M234.4,123.395s.859.639,2.682-.325" transform="translate(-203.736 -104.623)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.5" />
                          <path id="Path_15328" data-name="Path 15328" d="M236.24,116.414s1.3-1.393,2.8.325" transform="translate(-203.648 -104.965)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.5" />
                          <path id="Path_15329" data-name="Path 15329" d="M234.173,116.415s-1.048-1.173-1.833-.1" transform="translate(-203.834 -104.966)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.5" />
                          <path id="Path_15330" data-name="Path 15330" d="M252.555,176.55l-1.477,3.289-.168,4.431.828-.073.534-.807.136,4.023,2.011-.063s1.414-3.426,1.613-3.824a53.079,53.079,0,0,0-.052-5.688Z" transform="translate(-202.95 -102.079)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                        </g>
                        <g id="freepik--character-1--inject-73" transform="translate(25.036 111.074)">
                          <path id="Path_15331" data-name="Path 15331" d="M64.675,177.591,61.417,255.5H55.6l-3.331-62.791H50.637L44.446,255.5H39.093L37.7,177.13Z" transform="translate(-24.012 -103.172)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <rect id="Rectangle_2226" data-name="Rectangle 2226" width="21.863" height="51.729" transform="translate(38.337 75.917) rotate(180)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15332" data-name="Path 15332" d="M67.259,130.65S79.347,152.157,79.829,153.7s3.866,7.742-2.734,8.381L67.07,151.968Z" transform="translate(-22.615 -105.383)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15333" data-name="Path 15333" d="M55.8,127.4l.566,83.585H67.795V130.8Z" transform="translate(-23.151 -105.537)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15334" data-name="Path 15334" d="M55.989,156.5,55.8,126.91l2.242.8,2.451,10.057-3.185,2.818,3,3.363Z" transform="translate(-23.151 -105.561)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15335" data-name="Path 15335" d="M56.337,124.8l-.115,5.824-2.881,5.824L48.47,129.1V123.11Z" transform="translate(-23.5 -105.741)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15336" data-name="Path 15336" d="M52.509,128.611h1.728c2.587,0,5.961-3.352,5.961-5.95v-12.9a3.143,3.143,0,0,0-3.2-3.2H49.345a3.143,3.143,0,0,0-3.2,3.2v12.9C46.15,125.259,52.509,128.611,52.509,128.611Z" transform="translate(-23.61 -106.528)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15337" data-name="Path 15337" d="M56.038,115.9v3.939H54.1" transform="translate(-23.232 -106.084)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15338" data-name="Path 15338" d="M56.762,122.278H54.1a3.384,3.384,0,0,1-2.011-.67l-.21-.157" transform="translate(-23.338 -105.82)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15339" data-name="Path 15339" d="M58.694,114.2a1.634,1.634,0,0,0-2.284,0" transform="translate(-23.122 -106.187)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15340" data-name="Path 15340" d="M54.143,114.335a2.483,2.483,0,0,0-3.143,0" transform="translate(-23.379 -106.185)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15341" data-name="Path 15341" d="M57.2,116.178c0,.587.22,1.048.482,1.048s.492-.482.492-1.048-.22-1.048-.492-1.048S57.2,115.58,57.2,116.178Z" transform="translate(-23.085 -106.121)" fill="#263238" />
                          <path id="Path_15342" data-name="Path 15342" d="M52.05,116.178c0,.587.22,1.048.482,1.048s.482-.482.482-1.048-.21-1.048-.482-1.048S52.05,115.58,52.05,116.178Z" transform="translate(-23.33 -106.121)" fill="#263238" />
                          <path id="Path_15343" data-name="Path 15343" d="M46.257,115.395s-.932-2.619-1.875-.754a5.542,5.542,0,0,0,.377,5.238.922.922,0,0,0,1.5.094Z" transform="translate(-23.717 -106.176)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15344" data-name="Path 15344" d="M46.15,117.5v-5.447a5.521,5.521,0,0,1,5.521-5.521h3.007c1.6,0,6.422.471,7.427,1.571a63.1,63.1,0,0,1-7.155,1.048c-1.309,0-5.049-.377-5.049-.377s1.121,3.143-.189,3.939a8.381,8.381,0,0,1-2.629.932v4.127Z" transform="translate(-23.61 -106.53)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15345" data-name="Path 15345" d="M49.27,127.76l-.555,82.831H31.66s1.687-40.08,1.687-40.646,1.875-5.8,1.875-5.8l-.744-33.522Z" transform="translate(-24.299 -105.52)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15346" data-name="Path 15346" d="M47.038,140.6l-3.928-2.252,2.43-10.329,3.185-1.435-.189,30.128L43.3,143.781Z" transform="translate(-23.755 -105.576)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15347" data-name="Path 15347" d="M52.6,156.166l-.555,5.426-23.214,1.288s-6.369-1.142-3.415-9.1c1.571-4.19,9.428-23.287,9.428-23.287v23.047l-.943,1.875Z" transform="translate(-24.634 -105.39)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15348" data-name="Path 15348" d="M72.215,149.66s8.182,3.708,8.642,5.468a4.588,4.588,0,0,1-3.866,6.139c-2.954.23-8.182-7.731-8.182-7.731Z" transform="translate(-22.532 -104.478)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15349" data-name="Path 15349" d="M76.192,144.447l-7.679,19.474H52.59l7.3-19.851Z" transform="translate(-23.304 -104.744)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15350" data-name="Path 15350" d="M70.291,144.283,69.233,146H63.89l.733-1.791Z" transform="translate(-22.766 -104.738)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15351" data-name="Path 15351" d="M51.357,155.1l2.807-2.441,5.992-.189v2.807l-3.75,1.687-1.5,1.5-3.928.943Z" transform="translate(-23.38 -104.345)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15352" data-name="Path 15352" d="M60.709,249.767l9.1,4.148H54.35l.513-4.306Z" transform="translate(-23.22 -99.724)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                          <path id="Path_15353" data-name="Path 15353" d="M44.949,249.767l9.1,4.148H38.59l.513-4.306Z" transform="translate(-23.97 -99.724)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                        </g>
                      </g>
                    </svg>
                  </div>
                  <a data-aos="slide-right" data-aos-duration="100" data-aos-anchor=".hp"
                    data-aos-easing="ease-in-out" className="getinTouch getinTouchWidth" href="#GetInTouch">
                    Get in touch
                  </a>
                </div>
              </div>

              <div data-aos="fade-up" data-aos-duration="1000"
                data-aos-easing="ease-in-out" className="col-lg-6 col-md-12 MobileOff">
                <svg className="HomePageImage" xmlns="http://www.w3.org/2000/svg" width="762.307" height="488.993" viewBox="0 0 762.307 488.993">
                  <g id="tech_company-bro" data-name="tech company-bro" transform="translate(-16.18 -10.5)">
                    <g id="freepik--background-simple--inject-73" transform="translate(16.18 27.596)">
                      <path id="Path_15043" data-name="Path 15043" d="M755.826,153.156c-9.627-40.373-29.51-76.472-65.567-98.471-48.188-29.426-111.6-34.821-171.382-19.516-34.109,8.737-66.238,25.3-97.654,42.28-27.918,15.1-61.178,38.854-92.4,42.06-42.259,4.347-125.541-62.854-201.133-51.687S11.277,170.482,16.651,229.67s74.325,64.4,89.221,113.85,96.1,96.491,212.593,77.918,177.249-29.782,231.1-3.687,132.968,38.341,179.6-50.618c18.657-35.617,30.1-74.011,33.009-112.09C764.709,221.75,763.662,186.028,755.826,153.156Z" transform="translate(-16.18 -26.842)" fill="#ffc100" />
                      <path id="Path_15044" data-name="Path 15044" d="M755.826,153.156c-9.627-40.373-29.51-76.472-65.567-98.471-48.188-29.426-111.6-34.821-171.382-19.516-34.109,8.737-66.238,25.3-97.654,42.28-27.918,15.1-61.178,38.854-92.4,42.06-42.259,4.347-125.541-62.854-201.133-51.687S11.277,170.482,16.651,229.67s74.325,64.4,89.221,113.85,96.1,96.491,212.593,77.918,177.249-29.782,231.1-3.687,132.968,38.341,179.6-50.618c18.657-35.617,30.1-74.011,33.009-112.09C764.709,221.75,763.662,186.028,755.826,153.156Z" transform="translate(-16.18 -26.842)" fill="#fff" opacity="0.7" />
                    </g>
                    <g id="freepik--Scheme--inject-73" transform="translate(672.157 59.104)">
                      <rect id="Rectangle_2141" data-name="Rectangle 2141" width="50.608" height="24.272" rx="4.83" transform="translate(19.108)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2142" data-name="Rectangle 2142" width="37.136" height="17.809" rx="4.83" transform="translate(45.967 43.128)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2143" data-name="Rectangle 2143" width="37.136" height="17.809" rx="4.83" transform="translate(45.967 69.464)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2144" data-name="Rectangle 2144" width="37.136" height="17.809" rx="4.83" transform="translate(45.967 95.8)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2145" data-name="Rectangle 2145" width="37.136" height="17.809" rx="4.83" transform="translate(0 43.128)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <line id="Line_817" data-name="Line 817" y2="9.48" transform="translate(43.799 24.775)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <line id="Line_818" data-name="Line 818" y2="7.689" transform="translate(65.326 88.038)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <line id="Line_819" data-name="Line 819" y2="6.82" transform="translate(65.326 61.807)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15045" data-name="Path 15045" d="M659.46,98.291V92.749a3.069,3.069,0,0,1,3.069-3.069h41.285a3.069,3.069,0,0,1,3.069,3.069v5.175" transform="translate(-641.557 -55.362)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                    </g>
                    <g id="freepik--abstract-element--inject-73" transform="translate(471.414 21.662)">
                      <path id="Path_15046" data-name="Path 15046" d="M477.234,46.658a5.144,5.144,0,1,0-4.306,5.866A5.144,5.144,0,0,0,477.234,46.658Z" transform="translate(-449.969 -20.173)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15047" data-name="Path 15047" d="M547.835,85.549a5.154,5.154,0,1,0-4.316,5.866,5.154,5.154,0,0,0,4.316-5.866Z" transform="translate(-446.611 -18.324)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15048" data-name="Path 15048" d="M460.985,95.547a5.154,5.154,0,1,0-.924,3.86,5.154,5.154,0,0,0,.924-3.86Z" transform="translate(-450.742 -17.846)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15049" data-name="Path 15049" d="M492.146,84.331a8.58,8.58,0,1,0-1.565,6.382,8.59,8.59,0,0,0,1.565-6.382Z" transform="translate(-449.585 -18.521)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15050" data-name="Path 15050" d="M484.465,122.339a5.154,5.154,0,1,0-4.305,5.866,5.154,5.154,0,0,0,4.305-5.866Z" transform="translate(-449.626 -16.574)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15051" data-name="Path 15051" d="M588.3,115.249a5.133,5.133,0,1,0-.934,3.828,5.154,5.154,0,0,0,.934-3.828Z" transform="translate(-444.687 -16.912)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15052" data-name="Path 15052" d="M580.464,31.928a5.133,5.133,0,1,0-.934,3.828,5.144,5.144,0,0,0,.934-3.828Z" transform="translate(-445.058 -20.874)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15053" data-name="Path 15053" d="M539.185,25.559a5.154,5.154,0,1,0-4.305,5.866A5.154,5.154,0,0,0,539.185,25.559Z" transform="translate(-447.023 -21.178)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15054" data-name="Path 15054" d="M562.395,45.33a8.569,8.569,0,1,0-1.558,6.381,8.579,8.579,0,0,0,1.558-6.381Z" transform="translate(-446.243 -20.375)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <line id="Line_820" data-name="Line 820" x2="65.169" y2="36.298" transform="translate(26.48 28.915)" fill="none" stroke="#263238" stroke-linejoin="round" stroke-width="1" />
                      <line id="Line_821" data-name="Line 821" y1="28.473" x2="8.36" transform="translate(97.788 35.221)" fill="none" stroke="#263238" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15055" data-name="Path 15055" d="M557.47,54.762l25.938,59.68L576.787,37.33" transform="translate(-445.666 -20.41)" fill="none" stroke="#263238" stroke-linejoin="round" stroke-width="1" />
                      <line id="Line_822" data-name="Line 822" x1="56.684" y2="34.151" transform="translate(34.798 71.614)" fill="none" stroke="#263238" stroke-linejoin="round" stroke-width="1" />
                      <line id="Line_823" data-name="Line 823" y1="35.46" x2="57.637" transform="translate(42.56 30.34)" fill="none" stroke="#263238" stroke-linejoin="round" stroke-width="1" />
                      <line id="Line_824" data-name="Line 824" x1="7.553" y1="26.619" transform="translate(22.96 32.351)" fill="none" stroke="#263238" stroke-linejoin="round" stroke-width="1" />
                      <line id="Line_825" data-name="Line 825" y1="6.83" x2="10.528" transform="translate(115.722 15.349)" fill="none" stroke="#263238" stroke-linejoin="round" stroke-width="1" />
                      <line id="Line_826" data-name="Line 826" x2="16.95" y2="19.967" transform="translate(8.671 82.571)" fill="none" stroke="#263238" stroke-linejoin="round" stroke-width="1" />
                      <line id="Line_827" data-name="Line 827" y1="41.651" x2="15.022" transform="translate(4.366 31.743)" fill="none" stroke="#263238" stroke-linejoin="round" stroke-width="1" />
                      <line id="Line_828" data-name="Line 828" x2="92.176" y2="27.53" transform="translate(41.251 72.368)" fill="none" stroke="#263238" stroke-linejoin="round" stroke-width="1" />
                      <line id="Line_829" data-name="Line 829" x2="38.268" y2="23.067" transform="translate(96.908 73.091)" fill="none" stroke="#263238" stroke-linejoin="round" stroke-width="1" />
                      <line id="Line_830" data-name="Line 830" x2="71.926" y2="1.121" transform="translate(27.266 26.485)" fill="none" stroke="#263238" stroke-linejoin="round" stroke-width="1" />
                      <line id="Line_831" data-name="Line 831" x1="99.467" y2="11.785" transform="translate(25.789 12.615)" fill="none" stroke="#263238" stroke-linejoin="round" stroke-width="1" />
                      <line id="Line_832" data-name="Line 832" x1="32.873" y1="3.059" transform="translate(92.592 7.209)" fill="none" stroke="#263238" stroke-linejoin="round" stroke-width="1" />
                      <line id="Line_833" data-name="Line 833" x1="49.833" y2="93.862" transform="translate(32.493 8.205)" fill="none" stroke="#263238" stroke-linejoin="round" stroke-width="1" />
                      <line id="Line_834" data-name="Line 834" x1="15.787" y2="6.474" transform="translate(10.243 71.226)" fill="none" stroke="#263238" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15056" data-name="Path 15056" d="M504.408,46.523a2.409,2.409,0,1,0-2.022,2.745,2.409,2.409,0,0,0,2.022-2.745Z" transform="translate(-448.418 -20.07)" fill="#263238" />
                      <path id="Path_15057" data-name="Path 15057" d="M490.388,106.473a2.4,2.4,0,1,0-.435,1.791,2.409,2.409,0,0,0,.435-1.791Z" transform="translate(-449.084 -17.218)" fill="#263238" />
                      <path id="Path_15058" data-name="Path 15058" d="M549.948,70.093a2.409,2.409,0,1,0-2.011,2.745A2.409,2.409,0,0,0,549.948,70.093Z" transform="translate(-446.251 -18.948)" fill="#263238" />
                      <path id="Path_15059" data-name="Path 15059" d="M555.218,108.523a2.409,2.409,0,1,0-2.011,2.745,2.409,2.409,0,0,0,2.011-2.745Z" transform="translate(-446.001 -17.12)" fill="#263238" />
                      <path id="Path_15060" data-name="Path 15060" d="M465.908,68.223a2.409,2.409,0,1,0-2.011,2.745,2.409,2.409,0,0,0,2.011-2.745Z" transform="translate(-450.249 -19.037)" fill="#263238" />
                      <path id="Path_15061" data-name="Path 15061" d="M578.768,47.173a2.389,2.389,0,1,0-.431,1.793,2.409,2.409,0,0,0,.431-1.793Z" transform="translate(-444.88 -20.039)" fill="#263238" />
                    </g>
                    <g id="freepik--Cloud--inject-73" transform="translate(345.942 36.275)">
                      <path id="Path_15062" data-name="Path 15062" d="M417.667,70.957c0-.524.073-1.048.073-1.571a34.235,34.235,0,0,0-67.2-9.25,21.789,21.789,0,0,0-.136,43.338v.136h67.044a16.342,16.342,0,0,0,.251-32.674Z" transform="translate(-330.968 -35.128)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15063" data-name="Path 15063" d="M400.129,95.255,379.889,67.83,359.64,95.255h7.909v13.5H392.23v-13.5Z" transform="translate(-329.604 -33.572)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                    </g>
                    <g id="freepik--Server--inject-73" transform="translate(312.401 127.909)">
                      <path id="Path_15064" data-name="Path 15064" d="M330.615,250.4a3.143,3.143,0,1,1-3.143-3.143,3.143,3.143,0,0,1,3.143,3.143Z" transform="translate(-297.743 -116.67)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15065" data-name="Path 15065" d="M339.705,250.4a3.143,3.143,0,1,1-3.143-3.143A3.143,3.143,0,0,1,339.705,250.4Z" transform="translate(-297.31 -116.67)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15066" data-name="Path 15066" d="M348.8,250.4a3.143,3.143,0,1,1-3.143-3.143A3.143,3.143,0,0,1,348.8,250.4Z" transform="translate(-296.878 -116.67)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2146" data-name="Rectangle 2146" width="44.899" height="8.161" transform="translate(68.7 129.197)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2147" data-name="Rectangle 2147" width="26.765" height="8.161" transform="translate(117.233 129.197)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2148" data-name="Rectangle 2148" width="135.65" height="16.824" transform="translate(17.432)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2149" data-name="Rectangle 2149" width="135.65" height="8.412" transform="translate(17.432 16.824)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2150" data-name="Rectangle 2150" width="135.65" height="16.824" transform="translate(17.432 25.152)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2151" data-name="Rectangle 2151" width="135.65" height="8.412" transform="translate(17.432 41.976)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2152" data-name="Rectangle 2152" width="135.65" height="16.824" transform="translate(17.432 50.304)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2153" data-name="Rectangle 2153" width="135.65" height="8.412" transform="translate(17.432 67.128)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2154" data-name="Rectangle 2154" width="135.65" height="16.824" transform="translate(17.432 75.467)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2155" data-name="Rectangle 2155" width="135.65" height="8.412" transform="translate(17.432 92.291)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2156" data-name="Rectangle 2156" width="135.65" height="16.824" transform="translate(17.432 100.619)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2157" data-name="Rectangle 2157" width="135.65" height="8.412" transform="translate(17.432 117.443)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2158" data-name="Rectangle 2158" width="135.65" height="16.824" transform="translate(17.432 125.771)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2159" data-name="Rectangle 2159" width="135.65" height="8.412" transform="translate(17.432 142.595)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2160" data-name="Rectangle 2160" width="135.65" height="16.824" transform="translate(17.432 150.923)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2161" data-name="Rectangle 2161" width="135.65" height="8.412" transform="translate(17.432 167.747)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2162" data-name="Rectangle 2162" width="135.65" height="16.824" transform="translate(17.432 176.086)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2163" data-name="Rectangle 2163" width="135.65" height="8.412" transform="translate(17.432 192.91)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2164" data-name="Rectangle 2164" width="135.65" height="16.824" transform="translate(17.432 201.238)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2165" data-name="Rectangle 2165" width="135.65" height="8.412" transform="translate(17.432 218.062)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15067" data-name="Path 15067" d="M330.615,131.333a3.143,3.143,0,1,1-3.143-3.143,3.143,3.143,0,0,1,3.143,3.143Z" transform="translate(-297.743 -122.334)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15068" data-name="Path 15068" d="M339.705,131.333a3.143,3.143,0,1,1-3.143-3.143A3.143,3.143,0,0,1,339.705,131.333Z" transform="translate(-297.31 -122.334)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15069" data-name="Path 15069" d="M348.8,131.333a3.143,3.143,0,1,1-3.143-3.143A3.143,3.143,0,0,1,348.8,131.333Z" transform="translate(-296.878 -122.334)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2166" data-name="Rectangle 2166" width="44.899" height="8.161" transform="translate(68.7 4.463)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2167" data-name="Rectangle 2167" width="26.765" height="8.161" transform="translate(117.233 4.463)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15070" data-name="Path 15070" d="M330.615,155.143A3.143,3.143,0,1,1,327.473,152a3.143,3.143,0,0,1,3.143,3.143Z" transform="translate(-297.743 -121.201)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15071" data-name="Path 15071" d="M339.705,155.143A3.143,3.143,0,1,1,336.563,152,3.143,3.143,0,0,1,339.705,155.143Z" transform="translate(-297.31 -121.201)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15072" data-name="Path 15072" d="M348.8,155.143A3.143,3.143,0,1,1,345.653,152,3.143,3.143,0,0,1,348.8,155.143Z" transform="translate(-296.878 -121.201)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2168" data-name="Rectangle 2168" width="44.899" height="8.161" transform="translate(68.7 29.416)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2169" data-name="Rectangle 2169" width="26.765" height="8.161" transform="translate(117.233 29.416)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <circle id="Ellipse_296" data-name="Ellipse 296" cx="3.174" cy="3.174" r="3.174" transform="translate(26.524 55.72)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <circle id="Ellipse_297" data-name="Ellipse 297" cx="3.174" cy="3.174" r="3.174" transform="translate(36.047 55.72)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <circle id="Ellipse_298" data-name="Ellipse 298" cx="3.174" cy="3.174" r="3.174" transform="translate(45.569 55.72)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2170" data-name="Rectangle 2170" width="44.899" height="8.161" transform="translate(68.7 54.358)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2171" data-name="Rectangle 2171" width="26.765" height="8.161" transform="translate(117.233 54.358)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <circle id="Ellipse_299" data-name="Ellipse 299" cx="3.174" cy="3.174" r="3.174" transform="translate(26.524 80.673)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <circle id="Ellipse_300" data-name="Ellipse 300" cx="3.174" cy="3.174" r="3.174" transform="translate(36.047 80.673)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <circle id="Ellipse_301" data-name="Ellipse 301" cx="3.174" cy="3.174" r="3.174" transform="translate(45.569 80.673)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2172" data-name="Rectangle 2172" width="44.899" height="8.161" transform="translate(68.7 79.311)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2173" data-name="Rectangle 2173" width="26.765" height="8.161" transform="translate(117.233 79.311)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15073" data-name="Path 15073" d="M330.615,226.593a3.143,3.143,0,1,1-3.143-3.143,3.143,3.143,0,0,1,3.143,3.143Z" transform="translate(-297.743 -117.803)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15074" data-name="Path 15074" d="M339.705,226.593a3.143,3.143,0,1,1-3.143-3.143,3.143,3.143,0,0,1,3.143,3.143Z" transform="translate(-297.31 -117.803)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15075" data-name="Path 15075" d="M348.8,226.593a3.143,3.143,0,1,1-3.143-3.143,3.143,3.143,0,0,1,3.143,3.143Z" transform="translate(-296.878 -117.803)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2174" data-name="Rectangle 2174" width="44.899" height="8.161" transform="translate(68.7 104.254)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2175" data-name="Rectangle 2175" width="26.765" height="8.161" transform="translate(117.233 104.254)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15076" data-name="Path 15076" d="M308.326,131H304.24a5.238,5.238,0,0,0-5.29,5.238V282.9" transform="translate(-298.95 -122.2)" fill="none" stroke="#263238" stroke-linejoin="round" stroke-width="1" />
                      <line id="Line_835" data-name="Line 835" x1="7.836" transform="translate(9.376 8.8)" fill="none" stroke="#263238" stroke-linejoin="round" stroke-width="4" />
                      <path id="Path_15077" data-name="Path 15077" d="M308.775,179.96H306.03V292.7" transform="translate(-298.613 -119.871)" fill="none" stroke="#263238" stroke-linejoin="round" stroke-width="1" />
                      <line id="Line_836" data-name="Line 836" x1="6.652" transform="translate(10.161 60.078)" fill="none" stroke="#263238" stroke-linejoin="round" stroke-width="4" />
                      <path id="Path_15078" data-name="Path 15078" d="M449.53,203.12h2.347V305.687" transform="translate(-291.787 -118.77)" fill="none" stroke="#263238" stroke-linejoin="round" stroke-width="1" />
                      <line id="Line_837" data-name="Line 837" x2="4.693" transform="translate(153.05 84.35)" fill="none" stroke="#263238" stroke-linejoin="round" stroke-width="4" />
                      <path id="Path_15079" data-name="Path 15079" d="M453.64,154.54h3.75a4.473,4.473,0,0,1,4.473,4.473V302.53" transform="translate(-291.592 -121.081)" fill="none" stroke="#263238" stroke-linejoin="round" stroke-width="1" />
                      <line id="Line_838" data-name="Line 838" x2="8.999" transform="translate(153.05 33.459)" fill="none" stroke="#263238" stroke-linejoin="round" stroke-width="4" />
                    </g>
                    <g id="freepik--stairs-3--inject-73" transform="translate(16.777 264.501)">
                      <path id="Path_15080" data-name="Path 15080" d="M383.891,333.642V322.171H371.048v-11.46H358.215v-11.46H345.372V287.79H332.529V276.319H319.7v-11.46H305.91V252.99H16.75V486.556H422.232V333.642Z" transform="translate(-16.75 -252.99)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                    </g>
                    <g id="freepik--stairs-2--inject-73" transform="translate(308.556 264.941)">
                      <path id="Path_15081" data-name="Path 15081" d="M753.633,486.536V253.41H499.116v11.869h-13.8V276.75H472.487v11.46H459.643v11.46H446.811v11.46H433.968v8.244H421.4V322.6h-.262v8.653H407.611v11.46H394.768v11.46H381.935v11.46H369.092v11.471H356.248v11.46H343.416v11.46H295.28v86.508Z" transform="translate(-295.28 -253.41)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                    </g>
                    <g id="freepik--stairs-1--inject-73" transform="translate(308.221 411.621)">
                      <path id="Path_15082" data-name="Path 15082" d="M423.758,479.875v-5.8H410.915v-11.46H398.072v-11.46H385.239V439.68H372.4V428.22H359.564v-11.46H346.72V405.3H332.934V393.43H294.96v86.445Z" transform="translate(-294.96 -393.43)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                    </g>
                    <g id="freepik--Plant--inject-73" transform="translate(30.675 400.947)">
                      <path id="Path_15083" data-name="Path 15083" d="M39.5,406.189s-1.215,3.342-3.8,3.8-7.134-4.567-4.253-7.144S38.283,404.387,39.5,406.189Z" transform="translate(-29.993 -382.347)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75" />
                      <path id="Path_15084" data-name="Path 15084" d="M42.98,390.415s-3.792,9.271-2.273,13.828,10.937,7.909,13.671,1.98-3.342-14.289-5.615-16.112S43.744,388.9,42.98,390.415Z" transform="translate(-29.525 -382.967)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75" />
                      <path id="Path_15085" data-name="Path 15085" d="M64.337,394.178S57.811,400.107,57,406.037s3.646,8.056,8.2,7.333,6.841-12.309,5.479-17.62S64.337,394.178,64.337,394.178Z" transform="translate(-28.737 -382.78)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75" />
                      <path id="Path_15086" data-name="Path 15086" d="M65.546,425.46s-7.333.3-7.595,3.8,3.143,4.253,6.683,1.215S68.594,425.607,65.546,425.46Z" transform="translate(-28.689 -381.232)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75" />
                      <path id="Path_15087" data-name="Path 15087" d="M58.07,430.02s5.741-2.629,8.328-3.09" transform="translate(-28.682 -381.162)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="0.75" />
                      <path id="Path_15088" data-name="Path 15088" d="M67.182,447.44s-5.364-4.955-8.056-2.713-.765,5.238,3.855,5.6S69.214,449.7,67.182,447.44Z" transform="translate(-28.691 -380.343)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75" />
                      <path id="Path_15089" data-name="Path 15089" d="M58.59,445.35s5.919,2.221,8.066,3.729" transform="translate(-28.658 -380.286)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="0.75" />
                      <path id="Path_15090" data-name="Path 15090" d="M39.834,446.106s7.333-6.841,11.094-3.74,1.048,7.26-5.311,7.721S37.027,449.228,39.834,446.106Z" transform="translate(-29.606 -380.466)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75" />
                      <path id="Path_15091" data-name="Path 15091" d="M51.505,443.22s-8.161,3.059-11.125,5.144" transform="translate(-29.524 -380.387)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="0.75" />
                      <path id="Path_15092" data-name="Path 15092" d="M48.36,432.558s-.754,3.646-3.5,5.018-7.595-1.519-5.007-5.929S46.987,430.736,48.36,432.558Z" transform="translate(-29.584 -381.039)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75" />
                      <path id="Path_15093" data-name="Path 15093" d="M42.413,421.618s-1.372-3.038-6.694-1.048-7.438,5.007-4.1,6.076S42.413,421.618,42.413,421.618Z" transform="translate(-30.017 -381.496)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75" />
                      <path id="Path_15094" data-name="Path 15094" d="M41.274,421.41s-5.636.608-8.684,3.143" transform="translate(-29.894 -381.424)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="0.75" />
                      <path id="Path_15095" data-name="Path 15095" d="M56.35,386.9s.922,2.1,2.745,1.666,2.273-3.2,5.165-2.43,5.343,4.107,5.343,2.587-3.656-5.479-7.752-5.479S56.507,384.916,56.35,386.9Z" transform="translate(-28.764 -383.24)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75" />
                      <path id="Path_15096" data-name="Path 15096" d="M41.85,421.544s6.537-1.257,9.271,6.013a21.057,21.057,0,0,1,.681,2.221" transform="translate(-29.454 -381.422)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="0.75" />
                      <path id="Path_15097" data-name="Path 15097" d="M67.92,414.9s1.362-3.038,6.683-1.048,7.448,5.007,4.106,6.076S67.92,414.9,67.92,414.9Z" transform="translate(-28.214 -381.816)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75" />
                      <path id="Path_15098" data-name="Path 15098" d="M69.08,415s5.479-.3,8.066,2.9" transform="translate(-28.159 -381.73)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="0.75" />
                      <path id="Path_15099" data-name="Path 15099" d="M68.126,414.824a8.538,8.538,0,0,0-4.536.618" transform="translate(-28.42 -381.741)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="0.75" />
                      <path id="Path_15100" data-name="Path 15100" d="M45.765,409.811a12.571,12.571,0,0,0-6.38-3.771s-4.683-1.236-6.505.44" transform="translate(-29.881 -382.176)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="0.75" />
                      <line id="Line_839" data-name="Line 839" x1="0.283" y1="1.268" transform="translate(16.251 9.533)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="0.75" />
                      <path id="Path_15101" data-name="Path 15101" d="M53.182,460.67s.681-26.189,0-34.119A86.948,86.948,0,0,0,49.327,409.3L46.09,394.83" transform="translate(-29.252 -382.689)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="0.75" />
                      <line id="Line_840" data-name="Line 840" y1="1.257" x2="0.587" transform="translate(37.444 15.158)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="0.75" />
                      <path id="Path_15102" data-name="Path 15102" d="M54.38,460.39s.23-23.874,1.131-30.694,4.546-17.484,4.546-17.484l5.573-11.942" transform="translate(-28.858 -382.43)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="0.75" />
                      <path id="Path_15103" data-name="Path 15103" d="M58.184,429.88s-1.592,13.744-2.5,29.133" transform="translate(-28.796 -381.021)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="0.75" />
                      <path id="Path_15104" data-name="Path 15104" d="M51.091,458.874s.45-21.831-.461-24.785-2.43-1.655-2.43-1.655-4.19.5-5.73,2.933" transform="translate(-29.424 -380.914)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="0.75" />
                      <path id="Path_15105" data-name="Path 15105" d="M51.69,431c1.194,6.652.094,27.918.094,27.918" transform="translate(-28.986 -380.968)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="0.75" />
                      <path id="Path_15106" data-name="Path 15106" d="M62.626,416.09a9.512,9.512,0,0,0-3.447,4.683c-2.724,7.27-2.409,38.9-2.409,38.9" transform="translate(-28.745 -381.677)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="0.75" />
                      <path id="Path_15107" data-name="Path 15107" d="M45.93,410.45a21.37,21.37,0,0,1,3.855,6.956c3.635,10.476,5,37.5,4.316,42.5" transform="translate(-29.26 -381.946)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="0.75" />
                      <path id="Path_15108" data-name="Path 15108" d="M54.757,419.78c-.22,13.178-.377,39.682-.377,39.682" transform="translate(-28.858 -381.502)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="0.75" />
                      <path id="Path_15109" data-name="Path 15109" d="M54.77,419.815c.147-8.716.325-17.243.5-21.831.45-11.817,1.152-11.146,1.152-11.146s.849-2.661,5.563-2.347" transform="translate(-28.839 -383.182)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="0.75" />
                      <path id="Path_15110" data-name="Path 15110" d="M49.34,428.025s-3.8-3.384-2.284-5.049,10.947-2.891,13.681-.723-3.352,5.238-5.625,5.887S50.094,428.58,49.34,428.025Z" transform="translate(-29.223 -381.44)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75" />
                      <path id="Path_15111" data-name="Path 15111" d="M52.574,425.07a5.532,5.532,0,0,0-.094,1.048" transform="translate(-28.948 -381.25)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="0.75" />
                      <path id="Path_15112" data-name="Path 15112" d="M53.854,421.29a17.189,17.189,0,0,0-.974,2.514" transform="translate(-28.929 -381.43)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="0.75" />
                      <path id="Path_15113" data-name="Path 15113" d="M49.341,449.177s-3.8-3.384-2.326-5.091,10.947-2.891,13.681-.723-3.352,5.238-5.625,5.887S50.1,449.733,49.341,449.177Z" transform="translate(-29.224 -380.436)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75" />
                      <path id="Path_15114" data-name="Path 15114" d="M53.873,442.44a13.712,13.712,0,0,0-1.393,4.965" transform="translate(-28.948 -380.424)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="0.75" />
                      <path id="Path_15115" data-name="Path 15115" d="M52,440.134s-2.587-2.3-1.55-3.436,7.427-1.959,9.281-.492-2.263,3.551-3.813,4A5.981,5.981,0,0,1,52,440.134Z" transform="translate(-29.056 -380.758)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75" />
                      <path id="Path_15116" data-name="Path 15116" d="M55.043,435.56a9.344,9.344,0,0,0-.943,3.373" transform="translate(-28.871 -380.751)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="0.75" />
                      <rect id="Rectangle_2176" data-name="Rectangle 2176" width="10.287" height="22.806" transform="translate(19.373 74.189)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75" />
                    </g>
                    <g id="freepik--device-2--inject-73" transform="translate(448.49 326.318)">
                      <path id="Path_15117" data-name="Path 15117" d="M459.367,312h224.41a10.958,10.958,0,0,1,10.958,10.958v148.9H448.42V322.926A10.958,10.958,0,0,1,459.367,312Z" transform="translate(-427.93 -312)" fill="#263238" stroke="#263238" stroke-miterlimit="10" stroke-width="1" />
                      <rect id="Rectangle_2177" data-name="Rectangle 2177" width="226.118" height="133.743" transform="translate(30.558 10.842)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2178" data-name="Rectangle 2178" width="169.277" height="8.339" transform="translate(59.565 152.159)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2179" data-name="Rectangle 2179" width="169.277" height="4.326" transform="translate(59.565 156.171)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15118" data-name="Path 15118" d="M429.4,469.93s2.776,3.394,11.125,6.181H705.539s6.181-1.55,8.967-5.866Z" transform="translate(-428.834 -304.488)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2180" data-name="Rectangle 2180" width="286.29" height="6.495" rx="1.89" transform="translate(0 159.356)" fill="#969696" stroke="#263238" stroke-miterlimit="10" stroke-width="1" />
                      <path id="Path_15119" data-name="Path 15119" d="M568.2,316.688a2.158,2.158,0,1,0-.628,1.533,2.158,2.158,0,0,0,.628-1.533Z" transform="translate(-422.437 -311.879)" fill="#737373" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15120" data-name="Path 15120" d="M450.658,355.8h0a.629.629,0,0,1-.618-.618V337.258a.629.629,0,0,1,.618-.618h0a.618.618,0,0,1,.618.618v17.924A.618.618,0,0,1,450.658,355.8Z" transform="translate(-427.852 -310.828)" fill="#263238" />
                      <path id="Path_15121" data-name="Path 15121" d="M479.863,332.848V330.68H470.54v15.19h19.631V332.848Z" transform="translate(-426.877 -311.111)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15122" data-name="Path 15122" d="M503.043,332.848V330.68H493.72v15.19h19.642V332.848Z" transform="translate(-425.775 -311.111)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15123" data-name="Path 15123" d="M479.863,353.569V351.39H470.54v15.2h19.631V353.569Z" transform="translate(-426.877 -310.126)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15124" data-name="Path 15124" d="M503.043,353.569V351.39H493.72v15.2h19.642V353.569Z" transform="translate(-425.775 -310.126)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15125" data-name="Path 15125" d="M479.863,374.289V372.11H470.54v15.2h19.631V374.289Z" transform="translate(-426.877 -309.141)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15126" data-name="Path 15126" d="M503.043,374.289V372.11H493.72v15.2h19.642V374.289Z" transform="translate(-425.775 -309.141)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15127" data-name="Path 15127" d="M479.863,395.009V392.83H470.54v15.2h19.631V395.009Z" transform="translate(-426.877 -308.155)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15128" data-name="Path 15128" d="M503.043,395.009V392.83H493.72v15.2h19.642V395.009Z" transform="translate(-425.775 -308.155)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15129" data-name="Path 15129" d="M479.863,415.729V413.55H470.54v15.2h19.631V415.729Z" transform="translate(-426.877 -307.169)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15130" data-name="Path 15130" d="M503.043,415.729V413.55H493.72v15.2h19.642V415.729Z" transform="translate(-425.775 -307.169)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15131" data-name="Path 15131" d="M520.491,381.72h32.59a3.143,3.143,0,0,1,3.143,3.143v38.194a5.646,5.646,0,0,1-5.646,5.646H523.026a5.646,5.646,0,0,1-5.646-5.646V384.831a3.143,3.143,0,0,1,3.111-3.111Z" transform="translate(-424.649 -308.684)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15132" data-name="Path 15132" d="M520.491,381.72h32.59a3.143,3.143,0,0,1,3.143,3.143v1.624H517.38v-1.624a3.143,3.143,0,0,1,3.111-3.143Z" transform="translate(-424.649 -308.684)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15133" data-name="Path 15133" d="M545.514,384.127a1.247,1.247,0,1,0-.365.892A1.257,1.257,0,0,0,545.514,384.127Z" transform="translate(-423.431 -308.629)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15134" data-name="Path 15134" d="M549.375,384.128a1.257,1.257,0,1,0-.372.893,1.268,1.268,0,0,0,.372-.893Z" transform="translate(-423.248 -308.63)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15135" data-name="Path 15135" d="M553.225,384.128a1.268,1.268,0,1,0-1.257,1.257,1.247,1.247,0,0,0,1.257-1.257Z" transform="translate(-423.065 -308.63)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15136" data-name="Path 15136" d="M531.762,391.39l-6.652,5.259,6.652,5.259" transform="translate(-424.281 -308.224)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                      <path id="Path_15137" data-name="Path 15137" d="M540.17,391.39l6.652,5.259-6.652,5.259" transform="translate(-423.565 -308.224)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                      <line id="Line_841" data-name="Line 841" x1="2.011" y2="12.686" transform="translate(110.875 81.93)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                      <line id="Line_842" data-name="Line 842" x2="7.354" transform="translate(120.208 100.619)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <line id="Line_843" data-name="Line 843" x2="9.554" transform="translate(109.324 100.619)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <line id="Line_844" data-name="Line 844" x2="5.919" transform="translate(101.3 100.619)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <line id="Line_845" data-name="Line 845" x2="2.776" transform="translate(97.183 100.619)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <line id="Line_846" data-name="Line 846" x2="4.295" transform="translate(123.267 104.956)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <line id="Line_847" data-name="Line 847" x2="19.296" transform="translate(101.876 104.956)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <line id="Line_848" data-name="Line 848" x2="2.399" transform="translate(97.183 104.956)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <line id="Line_849" data-name="Line 849" x2="14.614" transform="translate(112.949 109.293)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <line id="Line_850" data-name="Line 850" x2="9.554" transform="translate(100.724 109.293)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <line id="Line_851" data-name="Line 851" x2="2.011" transform="translate(97.183 109.293)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <line id="Line_852" data-name="Line 852" x2="4.106" transform="translate(123.456 113.64)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <line id="Line_853" data-name="Line 853" x2="11.659" transform="translate(109.513 113.64)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <line id="Line_854" data-name="Line 854" x2="10.036" transform="translate(97.183 113.64)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15138" data-name="Path 15138" d="M566.621,326.58h32.6a3.143,3.143,0,0,1,3.143,3.143v38.152a5.646,5.646,0,0,1-5.646,5.646H569.156a5.646,5.646,0,0,1-5.646-5.646V329.681A3.143,3.143,0,0,1,566.621,326.58Z" transform="translate(-422.455 -311.306)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15139" data-name="Path 15139" d="M566.621,326.58h32.6a3.143,3.143,0,0,1,3.143,3.143v1.624H563.52v-1.624a3.143,3.143,0,0,1,3.1-3.143Z" transform="translate(-422.454 -311.306)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15140" data-name="Path 15140" d="M591.655,328.988a1.268,1.268,0,1,0-1.268,1.257,1.268,1.268,0,0,0,1.268-1.257Z" transform="translate(-421.237 -311.253)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15141" data-name="Path 15141" d="M595.505,328.988a1.268,1.268,0,1,0-1.257,1.257A1.267,1.267,0,0,0,595.505,328.988Z" transform="translate(-421.054 -311.253)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15142" data-name="Path 15142" d="M599.365,328.988a1.268,1.268,0,1,0-1.268,1.257,1.268,1.268,0,0,0,1.268-1.257Z" transform="translate(-420.87 -311.253)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15143" data-name="Path 15143" d="M577.892,336.25l-6.652,5.259,6.652,5.259" transform="translate(-422.087 -310.846)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                      <path id="Path_15144" data-name="Path 15144" d="M586.3,336.25l6.652,5.259-6.652,5.259" transform="translate(-421.371 -310.846)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                      <line id="Line_855" data-name="Line 855" x1="2.001" y2="12.676" transform="translate(159.209 24.167)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                      <line id="Line_856" data-name="Line 856" x2="7.354" transform="translate(168.533 42.856)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <line id="Line_857" data-name="Line 857" x2="9.554" transform="translate(157.649 42.856)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <line id="Line_858" data-name="Line 858" x2="5.919" transform="translate(149.624 42.856)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <line id="Line_859" data-name="Line 859" x2="2.776" transform="translate(145.507 42.856)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <line id="Line_860" data-name="Line 860" x2="4.295" transform="translate(171.592 47.193)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <line id="Line_861" data-name="Line 861" x2="19.296" transform="translate(150.2 47.193)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <line id="Line_862" data-name="Line 862" x2="2.399" transform="translate(145.507 47.193)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <line id="Line_863" data-name="Line 863" x2="14.614" transform="translate(161.273 51.53)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <line id="Line_864" data-name="Line 864" x2="9.554" transform="translate(149.048 51.53)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <line id="Line_865" data-name="Line 865" x2="2.011" transform="translate(145.507 51.53)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <line id="Line_866" data-name="Line 866" x2="4.106" transform="translate(171.78 55.867)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <line id="Line_867" data-name="Line 867" x2="11.659" transform="translate(157.837 55.867)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <line id="Line_868" data-name="Line 868" x2="10.036" transform="translate(145.507 55.867)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                    </g>
                    <g id="freepik--device-1--inject-73" transform="translate(87.142 11)">
                      <rect id="Rectangle_2181" data-name="Rectangle 2181" width="118.344" height="221.697" rx="7.69" transform="translate(0 14.373)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2182" data-name="Rectangle 2182" width="105.029" height="196.765" transform="translate(6.652 26.839)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2183" data-name="Rectangle 2183" width="17.107" height="17.107" rx="2.12" transform="translate(15.839 100.682)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2184" data-name="Rectangle 2184" width="17.107" height="17.107" rx="2.12" transform="translate(38.844 100.682)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2185" data-name="Rectangle 2185" width="17.107" height="17.107" rx="2.12" transform="translate(61.848 100.682)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2186" data-name="Rectangle 2186" width="17.107" height="17.107" rx="2.12" transform="translate(84.843 100.682)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2187" data-name="Rectangle 2187" width="17.107" height="17.107" rx="2.12" transform="translate(15.839 126.494)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2188" data-name="Rectangle 2188" width="17.107" height="17.107" rx="2.12" transform="translate(38.844 126.494)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2189" data-name="Rectangle 2189" width="17.107" height="17.107" rx="2.12" transform="translate(61.848 126.494)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2190" data-name="Rectangle 2190" width="17.107" height="17.107" rx="2.12" transform="translate(84.843 126.494)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2191" data-name="Rectangle 2191" width="17.107" height="17.107" rx="2.12" transform="translate(15.839 152.306)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2192" data-name="Rectangle 2192" width="17.107" height="17.107" rx="2.12" transform="translate(38.844 152.306)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2193" data-name="Rectangle 2193" width="17.107" height="17.107" rx="2.12" transform="translate(61.848 152.306)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2194" data-name="Rectangle 2194" width="17.107" height="17.107" rx="2.12" transform="translate(84.843 152.306)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2195" data-name="Rectangle 2195" width="17.107" height="17.107" rx="2.12" transform="translate(15.839 178.118)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2196" data-name="Rectangle 2196" width="17.107" height="17.107" rx="2.12" transform="translate(38.844 178.118)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2197" data-name="Rectangle 2197" width="17.107" height="17.107" rx="2.12" transform="translate(61.848 178.118)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2198" data-name="Rectangle 2198" width="17.107" height="17.107" rx="2.12" transform="translate(84.843 178.118)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15145" data-name="Path 15145" d="M113.7,11A15.368,15.368,0,0,0,98.29,26.378c0,8.485,15.368,25.833,15.368,25.833s15.368-17.348,15.368-25.833A15.368,15.368,0,0,0,113.7,11Zm-.43,22.565a14.787,14.787,0,1,0-.042.01Z" transform="translate(-83.236 -11)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2199" data-name="Rectangle 2199" width="82.559" height="56.537" transform="translate(70.438 37.367)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2200" data-name="Rectangle 2200" width="82.559" height="7.228" transform="translate(70.438 86.665)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15146" data-name="Path 15146" d="M205.334,71.5,180.36,57.07V85.91Z" transform="translate(-79.333 -8.808)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <line id="Line_869" data-name="Line 869" x2="74.891" transform="translate(74.744 90.636)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                    </g>
                    <g id="freepik--Tabs--inject-73" transform="translate(37.236 277.563)">
                      <path id="Path_15147" data-name="Path 15147" d="M40.858,299.93h85.9a4.578,4.578,0,0,1,4.557,4.578v78.966H36.28V304.508A4.578,4.578,0,0,1,40.858,299.93Z" transform="translate(-36.28 -263.819)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15148" data-name="Path 15148" d="M131.315,308.572v-3.6a5.039,5.039,0,0,0-5.039-5.039H41.319a5.049,5.049,0,0,0-5.039,5.039v3.6Z" transform="translate(-36.28 -263.819)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <line id="Line_870" data-name="Line 870" y2="3.227" transform="translate(9.868 109.943)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="1" />
                      <line id="Line_871" data-name="Line 871" y2="3.227" transform="translate(17.61 109.943)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="1" />
                      <line id="Line_872" data-name="Line 872" y2="3.227" transform="translate(25.341 109.943)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="1" />
                      <line id="Line_873" data-name="Line 873" y2="3.227" transform="translate(33.082 109.943)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="1" />
                      <line id="Line_874" data-name="Line 874" y2="3.227" transform="translate(40.824 109.943)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="1" />
                      <line id="Line_875" data-name="Line 875" y2="3.227" transform="translate(48.565 109.943)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="1" />
                      <line id="Line_876" data-name="Line 876" y2="3.227" transform="translate(56.296 109.943)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="1" />
                      <line id="Line_877" data-name="Line 877" y2="3.227" transform="translate(64.038 109.943)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="1" />
                      <line id="Line_878" data-name="Line 878" y2="3.227" transform="translate(71.779 109.943)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="1" />
                      <line id="Line_879" data-name="Line 879" y2="3.227" transform="translate(79.521 109.943)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="1" />
                      <line id="Line_880" data-name="Line 880" y2="3.227" transform="translate(87.262 109.943)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="1" />
                      <path id="Path_15149" data-name="Path 15149" d="M119.736,327.906l-8.391,4.515-6.767-13.545-8.705,11.282-7.742-6.767L80.714,315l-7.742,9.03-7.742,21.926L56.851,337.9l-8.381,6.128V364.34h71.266Z" transform="translate(-35.7 -263.102)" fill="#ffc100" />
                      <line id="Line_881" data-name="Line 881" x2="8.768" transform="translate(12.958 51.72)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="1" />
                      <line id="Line_882" data-name="Line 882" x2="8.768" transform="translate(12.958 55.376)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="1" />
                      <line id="Line_883" data-name="Line 883" x2="8.768" transform="translate(12.958 59.021)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="1" />
                      <path id="Path_15150" data-name="Path 15150" d="M119.736,344.9l-8.391,3.1-6.767-15.064-8.705,10.549-7.742-2.577-7.417,16.122-7.742-21.6-7.742,21.6-8.381-8.056L48.47,359.622v7.731h71.266Z" transform="translate(-35.7 -262.249)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15151" data-name="Path 15151" d="M142.147,265.46H254.718a3.143,3.143,0,0,1,3.143,3.143v88.834H138.92V268.6a3.143,3.143,0,0,1,3.227-3.143Z" transform="translate(-31.398 -265.459)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15152" data-name="Path 15152" d="M142.147,265.46H254.718a3.143,3.143,0,0,1,3.143,3.143v6.568H138.92V268.6a3.143,3.143,0,0,1,3.227-3.143Z" transform="translate(-31.398 -265.459)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15153" data-name="Path 15153" d="M172.78,316.242a18.49,18.49,0,0,1-18.05-22.5l6.61,1.467a11.523,11.523,0,0,0-.283,2.546,11.722,11.722,0,0,0,15.567,11.073l2.21,6.4A18.552,18.552,0,0,1,172.78,316.242Z" transform="translate(-30.667 -264.114)" fill="#263238" />
                      <path id="Path_15154" data-name="Path 15154" d="M161.3,295.865l-6.61-1.467a18.49,18.49,0,0,1,33.009-6.872l-5.447,4a11.722,11.722,0,0,0-20.951,4.358Z" transform="translate(-30.647 -264.772)" fill="#b0b0b0" />
                      <path id="Path_15155" data-name="Path 15155" d="M177.82,315.537l-2.21-6.39a11.733,11.733,0,0,0,5.646-17.966l5.468-3.981a18.49,18.49,0,0,1-8.9,28.358Z" transform="translate(-29.652 -264.425)" fill="#ffc100" />
                      <line id="Line_884" data-name="Line 884" x2="22.407" transform="translate(178.097 20.743)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="1" />
                      <rect id="Rectangle_2201" data-name="Rectangle 2201" width="5.385" height="5.385" transform="translate(169.13 18.051)" fill="#263238" />
                      <line id="Line_885" data-name="Line 885" x2="22.407" transform="translate(178.097 33.293)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="1" />
                      <rect id="Rectangle_2202" data-name="Rectangle 2202" width="5.385" height="5.385" transform="translate(169.13 30.611)" fill="#b0b0b0" />
                      <line id="Line_886" data-name="Line 886" x2="22.407" transform="translate(178.097 45.853)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="1" />
                      <rect id="Rectangle_2203" data-name="Rectangle 2203" width="5.385" height="5.385" transform="translate(169.13 43.161)" fill="#ffc100" />
                      <rect id="Rectangle_2204" data-name="Rectangle 2204" width="5.982" height="6.945" transform="translate(113.085 81.041)" fill="#ffc100" />
                      <rect id="Rectangle_2205" data-name="Rectangle 2205" width="5.982" height="4.484" transform="translate(122.283 83.503)" fill="#ffc100" />
                      <rect id="Rectangle_2206" data-name="Rectangle 2206" width="5.982" height="9.418" transform="translate(131.48 78.579)" fill="#ffc100" />
                      <rect id="Rectangle_2207" data-name="Rectangle 2207" width="5.982" height="18.825" transform="translate(140.678 69.162)" fill="#ffc100" />
                      <rect id="Rectangle_2208" data-name="Rectangle 2208" width="5.982" height="22.418" transform="translate(149.876 65.579)" fill="#ffc100" />
                      <rect id="Rectangle_2209" data-name="Rectangle 2209" width="5.982" height="3.143" transform="translate(159.073 84.854)" fill="#ffc100" />
                      <rect id="Rectangle_2210" data-name="Rectangle 2210" width="5.982" height="24.209" transform="translate(168.271 63.777)" fill="#ffc100" />
                      <rect id="Rectangle_2211" data-name="Rectangle 2211" width="5.982" height="5.385" transform="translate(177.469 82.612)" fill="#ffc100" />
                      <rect id="Rectangle_2212" data-name="Rectangle 2212" width="5.982" height="14.792" transform="translate(186.666 73.195)" fill="#ffc100" />
                      <rect id="Rectangle_2213" data-name="Rectangle 2213" width="5.982" height="7.396" transform="translate(195.864 80.591)" fill="#ffc100" />
                      <rect id="Rectangle_2214" data-name="Rectangle 2214" width="5.982" height="21.517" transform="translate(205.061 66.469)" fill="#ffc100" />
                      <rect id="Rectangle_2215" data-name="Rectangle 2215" width="5.982" height="34.067" transform="translate(214.259 53.919)" fill="#ffc100" />
                      <line id="Line_887" data-name="Line 887" x2="22.868" transform="translate(115.777 57.953)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="1" />
                      <line id="Line_888" data-name="Line 888" x2="22.868" transform="translate(115.777 62.887)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="1" />
                    </g>
                    <g id="freepik--character-6--inject-73" transform="translate(552.636 303.981)">
                      <path id="Path_15156" data-name="Path 15156" d="M687.76,331a9.365,9.365,0,0,0-5.919,1.257,8.61,8.61,0,0,0-2.965,2.849c-1.917,3.143-.419,5.007-1.655,6.285s-2.179,1.865-1.98,2.807,1.7,1.676,1.928,1.844-.419,3.206.073,4.84,4.536,1.592,4.536,1.592.461,2.986.576,3.96-.817,8.517,3.268,6.191,6.443-4.609,6.684-5.594-1.048-4-1.048-4.965a2.64,2.64,0,0,1,.388-2.1c.482-.671,3.865-2.839,5.426-6.746a8.653,8.653,0,0,0-4.023-10.79A16.426,16.426,0,0,0,687.76,331Z" transform="translate(-521.287 -288.763)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15157" data-name="Path 15157" d="M685.548,333.578a4.756,4.756,0,0,0-3.038-1.8,9.564,9.564,0,0,1,4.9-.733,16.427,16.427,0,0,1,5.238,1.488,8.653,8.653,0,0,1,4.023,10.79c-1.561,3.907-4.945,6.076-5.426,6.746a2.272,2.272,0,0,0-.356.744c-3.143-.587-3.551-2.451-3.551-2.451a2.682,2.682,0,0,0,2.1.21c1.163-.4,2.3-3.247,1.771-4.965s-2.3-.346-2.724.7-1.268,1.728-1.708,1.4.147-1.446.251-2.263-1.613-2.8-1.854-3.614.712-1.048,1.886-1.687S687.989,336.239,685.548,333.578Z" transform="translate(-520.94 -288.761)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15158" data-name="Path 15158" d="M687.259,347.8a4.976,4.976,0,0,1-2.189,2.42" transform="translate(-520.818 -287.961)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75" />
                      <path id="Path_15159" data-name="Path 15159" d="M679.15,341.13a3.6,3.6,0,0,1,1.77.409" transform="translate(-521.099 -288.278)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75" />
                      <path id="Path_15160" data-name="Path 15160" d="M678.85,339.367a1.844,1.844,0,0,1,2.8.733" transform="translate(-521.114 -288.379)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75" />
                      <path id="Path_15161" data-name="Path 15161" d="M678.25,346.861a1.812,1.812,0,0,0,2.21-.471" transform="translate(-521.142 -288.028)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75" />
                      <path id="Path_15162" data-name="Path 15162" d="M658.915,477.879a67.929,67.929,0,0,0,6.17-3.436,7.7,7.7,0,0,1,0-1.865c.136-.43,1.425-1.435,1.425-1.435s1.152-10.476,1.152-15.473a90.189,90.189,0,0,1,1.142-12.477c.147-.566,4.012-40.551,4.012-40.551l1.3-10.182,12.759.629,12.759-.629,1.289,10.182s3.865,39.986,4.012,40.551a87.911,87.911,0,0,1,1.152,12.477c0,5.018,1.142,15.473,1.142,15.473a7.936,7.936,0,0,1,1.435,1.435,7.7,7.7,0,0,1,0,1.865,67.5,67.5,0,0,0,6.16,3.436c1.048.293.576,1.152-.576,1.728a14.666,14.666,0,0,1-4.871.859c-1.142,0-2.86-2.011-3.289-2.294s-.293,1.435-.293,1.435a4.273,4.273,0,0,1-1.571-.293c-.576-.283-1.582.293-1.718-1.435s-.147-3.436-.147-3.436a6.2,6.2,0,0,1-1.288-1.865c-.293-.859-.859-3.3-.859-3.3l-9.03-36.445-4.305-19.9-4.306,19.9-9.03,36.4s-.576,2.441-.859,3.3a6.411,6.411,0,0,1-1.288,1.865s0,1.718-.147,3.436-1.152,1.152-1.718,1.435a4.359,4.359,0,0,1-1.582.293s.147-1.729-.283-1.435-2.1,2.294-3.3,2.294a14.741,14.741,0,0,1-4.871-.859C658.35,479.031,657.92,478.172,658.915,477.879Z" transform="translate(-522.088 -285.836)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15163" data-name="Path 15163" d="M700.19,379.247l3.646-17.557s7.867,27.362,8.213,28.043a.316.316,0,0,1,0,.126l3.656,11.817-7.176-.21-4.19-7.783-2.022-6.17C701.468,385.135,700.19,379.247,700.19,379.247Z" transform="translate(-520.099 -287.3)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <line id="Line_889" data-name="Line 889" y1="5.332" x2="2.2" transform="translate(180.804 84.174)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15164" data-name="Path 15164" d="M703.539,384.05l-.628.45" transform="translate(-519.969 -286.236)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15165" data-name="Path 15165" d="M709.291,382.9a18.732,18.732,0,0,0-2.609-.765,12.336,12.336,0,0,0-2.483,1.5" transform="translate(-519.908 -286.327)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15166" data-name="Path 15166" d="M669.276,360.17s-9,24.094-9.229,24.964a6.057,6.057,0,0,0-.346,2.514c.115,1.048,1.718,1.708,2.1,1.708s2.849-1.592,3.876-2.1,4.106-1.938,4.106-1.938l1.592-5.133Z" transform="translate(-522.025 -287.372)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <line id="Line_890" data-name="Line 890" x1="0.147" y1="0.587" transform="translate(146.601 80.016)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <line id="Line_891" data-name="Line 891" x1="1.907" y1="7.511" transform="translate(146.999 81.556)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15167" data-name="Path 15167" d="M692.439,356.84l12.885,5.081-5.583,29.657s.45,2.734.115,3.3a3.059,3.059,0,0,1-.911.922h-25.2s-1.708-1.6-1.708-1.938v-1.488l-3.143-32.035,12.686-3.331Z" transform="translate(-521.587 -287.53)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15168" data-name="Path 15168" d="M688.369,357.621l-1.833,5.185,2.472,31.5-3.876,6.149-3.352-6.684,3.457-30.966-2.158-4.641,3.027-1.666Z" transform="translate(-520.974 -287.547)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15169" data-name="Path 15169" d="M691.57,353.06l1.31,2.745-3.991,5.971L686.73,356.6Z" transform="translate(-520.739 -287.71)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15170" data-name="Path 15170" d="M685.11,356.712l-1.718,5.5-3.562-5.72,1.613-3.342Z" transform="translate(-521.067 -287.706)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15171" data-name="Path 15171" d="M692.729,355.68l-2.849,35.89.817,40.164h14.676l-3.666-43.024,2.619-26.734Z" transform="translate(-520.589 -287.586)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15172" data-name="Path 15172" d="M680.524,355.68l2.849,35.89-.807,40.164H667.88l3.667-43.024-2.65-28.326Z" transform="translate(-521.635 -287.586)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15173" data-name="Path 15173" d="M649.268,377.33s16.761,4.6,17.086,4.683a5.447,5.447,0,0,1,2.242,1.76c.272.66.9,3.551-.073,4.986s-5.238,2.912-6.065,2.556L646,383.8Z" transform="translate(-522.676 -286.556)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15174" data-name="Path 15174" d="M650.494,379.017a3.07,3.07,0,0,1-.409,2.42C649.3,382.8,646,383.8,646,383.8l3.268-6.474S650.41,377.33,650.494,379.017Z" transform="translate(-522.676 -286.556)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15175" data-name="Path 15175" d="M648.707,377l1.959,2.1s0,2.148-1.048,2.755-1.54-.073-1.54-.073l-1.048-1.257-4.641-1.875Z" transform="translate(-522.848 -286.572)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15176" data-name="Path 15176" d="M647.62,371.1s-1.309-.733-1.561-1.393-1.655-1.666-2.315-2.409-.251-1.571.911-1.152a20.421,20.421,0,0,1,3.729,2.567Z" transform="translate(-522.799 -287.093)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15177" data-name="Path 15177" d="M712.72,403.934a4.19,4.19,0,0,0,2.1.367c.681-.21,2.326.283,3.321.346s1.393-.775.356-1.435a21.454,21.454,0,0,0-4.326-1.351Z" transform="translate(-519.503 -285.389)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15178" data-name="Path 15178" d="M743.027,414.528a7.333,7.333,0,0,0,1.592-14.247l-111.713-39.9a7.359,7.359,0,0,0-4.955,13.859l111.723,39.912a7.26,7.26,0,0,0,3.352.377Z" transform="translate(-523.767 -287.383)" fill="#263238" />
                      <path id="Path_15179" data-name="Path 15179" d="M578.658,291.329A57.26,57.26,0,1,1,528.7,355.063,57.26,57.26,0,0,1,578.658,291.329ZM590.841,392.22a44.365,44.365,0,1,0-49.382-38.708,44.364,44.364,0,0,0,49.382,38.708Z" transform="translate(-528.276 -290.667)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15180" data-name="Path 15180" d="M580.568,291.1a57.26,57.26,0,1,1-49.959,63.724A57.26,57.26,0,0,1,580.568,291.1ZM592.845,391.99a44.375,44.375,0,1,0-49.382-38.76,44.375,44.375,0,0,0,49.382,38.76Z" transform="translate(-528.185 -290.678)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15181" data-name="Path 15181" d="M643.706,377.637s-1.048,3.656-.3,4.107,6.076,2.1,6.076,2.1a50.294,50.294,0,0,0-1.362-4.861c-.3-.451-.89-.65-.89-.4l-.325.911s-.5-1.907-1.048-1.823a.964.964,0,0,0-.67.66S644.827,376.505,643.706,377.637Z" transform="translate(-522.813 -286.559)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15182" data-name="Path 15182" d="M649.243,383.756s1.048.126.9-.869-1.655-3.9-1.99-3.069S648.761,382.9,649.243,383.756Z" transform="translate(-522.577 -286.445)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15183" data-name="Path 15183" d="M646.73,379.42a7.773,7.773,0,0,0,.66,3.31" transform="translate(-522.642 -286.456)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15184" data-name="Path 15184" d="M645.149,378.31a7.615,7.615,0,0,0,.084,3.729" transform="translate(-522.726 -286.509)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15185" data-name="Path 15185" d="M715.668,400.843s-1.142,3.625-2.032,3.583-6.285-1.613-6.285-1.613a48.737,48.737,0,0,1,3.845-3.279c.5-.21,1.048,0,.953.157l-.23.943s1.477-1.309,1.844-.964a.964.964,0,0,1,.178.922S715.364,399.283,715.668,400.843Z" transform="translate(-519.758 -285.504)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15186" data-name="Path 15186" d="M707.423,402.792s-.943-.482-.262-1.215,3.541-2.315,3.363-1.446S708.3,402.342,707.423,402.792Z" transform="translate(-519.779 -285.483)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15187" data-name="Path 15187" d="M711.868,400.58s-1.33,2-2.388,2.388" transform="translate(-519.657 -285.45)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15188" data-name="Path 15188" d="M713.785,400.54a7.553,7.553,0,0,1-2.1,3.048" transform="translate(-519.551 -285.452)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                    </g>
                    <g id="freepik--characters-5--inject-73" transform="translate(92.244 372.603)">
                      <line id="Line_892" data-name="Line 892" x2="1.749" y2="37.325" transform="translate(178.872 85.813)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                      <line id="Line_893" data-name="Line 893" x2="1.948" y2="37.325" transform="translate(171.026 85.813)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                      <line id="Line_894" data-name="Line 894" x1="2.535" y2="37.325" transform="translate(143.475 85.813)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                      <line id="Line_895" data-name="Line 895" x1="6.024" y2="37.325" transform="translate(134.267 85.813)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                      <path id="Path_15189" data-name="Path 15189" d="M262.267,399.24l.126,39.4H226.66l1.278-39.4Z" transform="translate(-82.232 -354.135)" fill="gray" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2216" data-name="Rectangle 2216" width="42.594" height="2.242" transform="translate(180.161 86.641) rotate(180)" fill="gray" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2217" data-name="Rectangle 2217" width="36.078" height="2.242" transform="translate(173.645 86.641) rotate(180)" fill="gray" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15190" data-name="Path 15190" d="M228.91,417.3s-3.3,5.049-3.824,8.873.346,8.182,2.787,9.742a12.1,12.1,0,0,0,5.238,1.739h20.7a8.318,8.318,0,0,0,5.238-4.347c1.561-3.478.524-8-.524-10.266s-1.917-4.19-1.917-4.19Z" transform="translate(-82.314 -353.276)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15191" data-name="Path 15191" d="M238.587,379.113s-12.173,4.526-12.875,5.741,5.395,25.225,5.395,25.225L227.965,420a139.758,139.758,0,0,0,14.446,2.263,43.8,43.8,0,0,0,14.477-1.362l-2.776-11.659,4.515-24.356s-8.936-4.19-10.905-5S240.012,378.935,238.587,379.113Z" transform="translate(-82.28 -355.096)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15192" data-name="Path 15192" d="M244.582,422.4l2.1-42.688a34.8,34.8,0,0,0-8.642-.6l-1,.377,2.326,42.458q1.257.157,2.546.283A23.14,23.14,0,0,0,244.582,422.4Z" transform="translate(-81.738 -355.095)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15193" data-name="Path 15193" d="M226.169,384.59s-11.136,30.62-9.219,31.668,24.157,1.393,24.157,1.393V415.21l-14.792-5.395,2.954-9.564Z" transform="translate(-82.704 -354.832)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15194" data-name="Path 15194" d="M240,413.851s5.908-.691,6.432-.691,5.238,3.3,5.238,3.3L240,416.292Z" transform="translate(-81.597 -353.473)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15195" data-name="Path 15195" d="M257.769,384.59s11.136,30.62,9.219,31.668-24.178,1.393-24.178,1.393V415.21l14.781-5.395-2.8-9.082Z" transform="translate(-81.464 -354.832)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15196" data-name="Path 15196" d="M261.68,408a8.611,8.611,0,0,1,1.048.094" transform="translate(-80.566 -353.718)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15197" data-name="Path 15197" d="M256.92,408.7a26.985,26.985,0,0,1,4.274-.7" transform="translate(-80.792 -353.718)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15198" data-name="Path 15198" d="M243.34,413.851s-5.919-.691-6.443-.691-5.238,3.3-5.238,3.3l11.659-.168Z" transform="translate(-81.994 -353.473)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15199" data-name="Path 15199" d="M258.555,424.889c-1.393-5.709-6.788-6.767-11.523-7.165a17.714,17.714,0,0,0-7.962,1.048s7.762.4,8.758,5.709l-3.143,7.668s-.6,1.77,2.388,4.526" transform="translate(-81.641 -353.26)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15200" data-name="Path 15200" d="M241.211,427.023a97.825,97.825,0,0,0,1.6,11.094c1.247,6.16,6.767,28.525,7.123,29.405s4.452,6.694,3.74,8.81-1.6,1.938-3.206.346-3.206-8.276-3.384-8.978-9.428-16.552-12.288-23.769-4.63-11.272-3.74-16.552,4.1-5.468,6.589-4.756S241.211,427.023,241.211,427.023Z" transform="translate(-82.033 -353.037)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15201" data-name="Path 15201" d="M252.882,474.121c.388-1.142-.629-3.363-1.7-5.3-1.3,1.959-2.661,1.749-3.625,1.163a13.356,13.356,0,0,0,2.1,4.484C251.279,476.059,252.211,476.226,252.882,474.121Z" transform="translate(-81.238 -350.825)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15202" data-name="Path 15202" d="M251,427.023s1.048,2.1,2.284,8.223,6.086,31.427,6.443,32.276,4.452,6.694,3.74,8.81-1.6,1.938-3.206.346-3.206-8.276-3.384-8.978-9.428-16.552-12.288-23.769-4.63-11.272-3.74-16.552,4.1-5.468,6.589-4.756S251,427.023,251,427.023Z" transform="translate(-81.568 -353.037)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15203" data-name="Path 15203" d="M262.672,474.121c.388-1.142-.629-3.363-1.708-5.3-1.289,1.959-2.661,1.749-3.614,1.163a13.357,13.357,0,0,0,2.1,4.484C261.069,476.059,261.959,476.226,262.672,474.121Z" transform="translate(-80.772 -350.825)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15204" data-name="Path 15204" d="M237.264,379.513s-1.76,4.086-2.1,4.62-1.948,4.086-1.948,4.086a35.546,35.546,0,0,1,3.719-.712c.712,0,4.8,2.1,4.8,2.1s3.907-1.592,4.62-1.414,3.719,1.592,3.719,1.592l-2.692-9.931S239.746,377.2,237.264,379.513Z" transform="translate(-81.92 -355.119)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15205" data-name="Path 15205" d="M234.316,364.545s-1.844,2.661-1.477,4.326-.555,4.253-1.854,4.62,0,2.032.932,2.221l.922.189s1.529,5.384,2.273,5.94,3.52,0,3.52,0,.314,4.19,1.6,6.013,2.1,2.221,3.708-.189a53.272,53.272,0,0,0,3.7-8.14s.555-6.107-.922-10.36S239.68,359.36,234.316,364.545Z" transform="translate(-82.052 -355.885)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15206" data-name="Path 15206" d="M231.908,364.338s2.273,1.372,4.19,2.4a3.887,3.887,0,0,1,1.6,1.613,13.619,13.619,0,0,0,2.787,3.415,2.17,2.17,0,0,1,.4.43c.22.45.45-.115,1.048-.566a1.592,1.592,0,0,1,2.284,1.477c.1,1.592-.23,2.273-1.142,2.5a3.635,3.635,0,0,1-1.477.115s-1.477,9.1.911,12.173,9.428,2.619,10.58.566l2.158-5.919a15.085,15.085,0,0,1-2.661-8.213c0-4.672,1.048-6.652-3.143-11.995s-12.7-2.661-14.289-1.749S230.42,360.357,231.908,364.338Z" transform="translate(-82.001 -356.044)" fill="#263238" />
                      <path id="Path_15207" data-name="Path 15207" d="M234.454,371.569c0,.471-.168.859-.377.859s-.377-.388-.377-.859.168-.859.377-.859S234.454,371.1,234.454,371.569Z" transform="translate(-81.897 -355.492)" fill="#263238" />
                      <path id="Path_15208" data-name="Path 15208" d="M233.49,369.6s.869-.964,1.613.325" transform="translate(-81.907 -355.562)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75" />
                      <path id="Path_15209" data-name="Path 15209" d="M233.91,376.384s.534.22,1.823-.744" transform="translate(-81.887 -355.258)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75" />
                      <line id="Line_896" data-name="Line 896" x1="1.749" y2="37.325" transform="translate(74.639 85.813)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                      <line id="Line_897" data-name="Line 897" x1="1.948" y2="37.325" transform="translate(93.495 85.813)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                      <line id="Line_898" data-name="Line 898" x2="2.525" y2="37.325" transform="translate(104.966 85.813)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                      <line id="Line_899" data-name="Line 899" x2="6.024" y2="37.325" transform="translate(115.075 85.813)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                      <path id="Path_15210" data-name="Path 15210" d="M158.88,399.24l1.676,39.4H192l-3.09-39.4Z" transform="translate(-85.456 -354.135)" fill="gray" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2218" data-name="Rectangle 2218" width="42.688" height="2.242" transform="translate(75.1 84.389)" fill="gray" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2219" data-name="Rectangle 2219" width="25.33" height="2.242" transform="translate(92.469 84.389)" fill="gray" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15211" data-name="Path 15211" d="M164.242,417.935s-1.571,14.247-1.048,16.112a5.007,5.007,0,0,0,3.415,3.467c1.446.4,21.517,0,22.072,0,3.206.063,3.834-1.582,3.949-4.8.115-3.488-.4-15.766-.4-15.766Z" transform="translate(-85.256 -353.293)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15212" data-name="Path 15212" d="M171.248,380.875s-11.8,4.662-11.408,5.437,3.907,23.466,4.033,24.094.388,8.161.388,8.81a2.42,2.42,0,0,0,1.561,1.948c4.054.671,8.475.325,12.571.377h8.318c1.634,0,5.447.765,6.055-1.048.251-1.425.587-32.663.712-34.224s-1.142-1.7-2.818-2.608a81.877,81.877,0,0,0-10.874-4C179.021,379.7,171.248,380.875,171.248,380.875Z" transform="translate(-85.411 -355.066)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15213" data-name="Path 15213" d="M173.977,388.023l2.629,3.677L173.6,414.2l4.735,5,2.619-5.783-3.834-22.1,2.9-3.677-4.861-2.766Z" transform="translate(-84.756 -354.819)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75" />
                      <path id="Path_15214" data-name="Path 15214" d="M171.678,379.742s-1.561.524-1.687,1.561.262,9.2.262,9.2l5.049-1.938,2.2-.524,5.709,1.048a28.609,28.609,0,0,0-1.048-7.019c-.911-2.336-1.948-3.143-2.724-3.237S171.678,379.742,171.678,379.742Z" transform="translate(-84.929 -355.106)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15215" data-name="Path 15215" d="M170.85,379.353a60.655,60.655,0,0,0,1.949,6.223c.513.911,3.886,3.635,4.662,2.462a22.543,22.543,0,0,0,1.687-4.662c.126-.388.262-4.536.262-4.536S173.186,381.04,170.85,379.353Z" transform="translate(-84.887 -355.105)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15216" data-name="Path 15216" d="M173.48,381.5a5.291,5.291,0,0,0,2.3,1.435,13.462,13.462,0,0,1,2.839,1.54l.367-1.393.22-3.74Z" transform="translate(-84.761 -355.082)" fill="#263238" stroke="#263238" stroke-miterlimit="10" stroke-width="1" />
                      <path id="Path_15217" data-name="Path 15217" d="M183.326,370.394a29.825,29.825,0,0,0,0-6.663c-.45-2.43-3.027-3.143-5-3.488s-10.308.45-11.366,2.724,1.2,4.1,1.2,4.1-1.362.911-.754,2.1,1.362,2.881,1.362,2.881l.911-3.478,6.369.147,2.42.608,2.577,3.143s1.362.3,1.823-.3A3.782,3.782,0,0,0,183.326,370.394Z" transform="translate(-85.085 -355.994)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15218" data-name="Path 15218" d="M168.928,370.947s-1.288-1.425-1.288.136a5.416,5.416,0,0,0,1.55,3.143Z" transform="translate(-85.039 -355.509)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15219" data-name="Path 15219" d="M168.68,367.3s.157,8.5.461,10.161,4.065,5.364,4.777,5.364a3.646,3.646,0,0,0,2.724-.45c.911-.754,4.62-3.509,4.924-4.567a21.337,21.337,0,0,0,.45-3.143s2.43-1.215,2.1-3.038-1.215-1.969-1.666-1.509a15.388,15.388,0,0,0-1.215,1.508,5.238,5.238,0,0,0-.911-2.1c-.608-.608-2.42-.911-2.1-1.519l1.666-3.331a14.478,14.478,0,0,1-5.6,2.881,17.461,17.461,0,0,1-5.6-.262Z" transform="translate(-84.99 -355.779)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15220" data-name="Path 15220" d="M172.49,374.27s.084.67,1.739.419" transform="translate(-84.809 -355.323)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75" />
                      <path id="Path_15221" data-name="Path 15221" d="M172.37,377.434a4.756,4.756,0,0,0,5.238-1.194" transform="translate(-84.814 -355.229)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75" />
                      <path id="Path_15222" data-name="Path 15222" d="M171.424,371.195c0,.419-.22.765-.482.765s-.482-.346-.482-.765.22-.765.482-.765S171.424,370.776,171.424,371.195Z" transform="translate(-84.905 -355.505)" fill="#263238" />
                      <path id="Path_15223" data-name="Path 15223" d="M176.094,371.195c0,.419-.21.765-.482.765s-.482-.346-.482-.765.22-.765.482-.765S176.094,370.776,176.094,371.195Z" transform="translate(-84.683 -355.505)" fill="#263238" />
                      <path id="Path_15224" data-name="Path 15224" d="M169.92,369.54a1.749,1.749,0,0,1,2.1-.168" transform="translate(-84.931 -355.569)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75" />
                      <path id="Path_15225" data-name="Path 15225" d="M174.14,369.452a2.441,2.441,0,0,1,2.891.482" transform="translate(-84.73 -355.565)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75" />
                      <path id="Path_15226" data-name="Path 15226" d="M160.234,386s-8.15,23.34-8.15,23.727-1.163,2.587,0,4.012a4.19,4.19,0,0,0,4.41,1.425c1.163-.513,6.579-11.848,6.579-11.848S160.621,387.309,160.234,386Z" transform="translate(-85.804 -354.765)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15227" data-name="Path 15227" d="M192.431,468.934l2.944,3.928s3.939,1.048,3.97,1.446-1.4,1.048-1.4,1.048l-3.824.6s-4.525-1.77-5.6-2.022-2.1,0-1.781-1.226.828-3.855.828-3.855Z" transform="translate(-84.134 -350.824)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15228" data-name="Path 15228" d="M182.346,430.956s3.017-5.385,7.228-7.49,7.878-1.309,11.293-1.309,9.2.524,9.858,1.309-18,47.7-18,47.7l-4.871-.126s3.551-15.766,4.075-19.453,1.844-14.32,1.844-14.32-9.114,0-13.315-.147" transform="translate(-84.429 -353.052)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15229" data-name="Path 15229" d="M165.32,423.76s.136-1.969,4.337-2.1,8.674-.524,9.2,1.98.66,40.729.66,42.049.4,4.463.4,4.463l-4.871.4s-6.285-21.161-7.333-25.885-.922-16.164-.922-17.086" transform="translate(-85.15 -353.073)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15230" data-name="Path 15230" d="M179.475,467.94s1.708,5.783,2.1,6.443.66,1.048-.524,1.833a7.27,7.27,0,0,1-5,.922c-1.446-.4-1.708-2.891-1.446-5a14.75,14.75,0,0,0,0-3.8Z" transform="translate(-84.712 -350.867)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15231" data-name="Path 15231" d="M169.446,412.514s-9-1.77-11.638-2.619-4.861-.744-6.285.513a5.154,5.154,0,0,0-1.236,4.672c.3.691,3.74,1.383,6.055,1.676s9.617-.629,11.073-.838l1.467-.21Z" transform="translate(-85.871 -353.654)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15232" data-name="Path 15232" d="M168.6,412.389s3.824-.409,4.4-.409a18.857,18.857,0,0,1,4.693,1.561c.754.524,2.367,2.1,2.2,2.315s-.471.178-1.048.115-2.148-.283-2.148-.283-3.991.23-5.615.23a14.921,14.921,0,0,1-3.038-.335Z" transform="translate(-85.02 -353.529)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15233" data-name="Path 15233" d="M192,386s8.15,23.34,8.15,23.727,1.163,2.587,0,4.012a4.19,4.19,0,0,1-4.41,1.425c-1.163-.513-3.834-8.234-3.834-8.234S191.868,387.886,192,386Z" transform="translate(-83.885 -354.765)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15234" data-name="Path 15234" d="M183.23,412.514s9-1.77,11.638-2.619,4.871-.744,6.285.513a5.164,5.164,0,0,1,1.247,4.672c-.314.691-3.75,1.383-6.065,1.676s-9.617-.629-11.073-.838l-1.467-.21Z" transform="translate(-84.298 -353.654)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15235" data-name="Path 15235" d="M183.743,412.389s-3.824-.409-4.4-.409a18.959,18.959,0,0,0-4.693,1.561c-.744.524-2.367,2.1-2.189,2.315s.461.178,1.048.115,2.1-.283,2.1-.283,3.991.23,5.615.23a14.921,14.921,0,0,0,3.038-.335Z" transform="translate(-84.811 -353.529)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <line id="Line_900" data-name="Line 900" x1="6.024" y2="37.325" transform="translate(10.497 85.813)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                      <line id="Line_901" data-name="Line 901" x1="6.024" y2="37.325" transform="translate(30.715 85.813)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                      <line id="Line_902" data-name="Line 902" x2="6.024" y2="37.325" transform="translate(45.098 85.813)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                      <line id="Line_903" data-name="Line 903" x2="6.024" y2="37.325" transform="translate(66.091 85.813)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                      <path id="Path_15236" data-name="Path 15236" d="M95.05,399.24l8.674,39.4H132.05l-10.078-39.4Z" transform="translate(-88.492 -354.135)" fill="gray" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2220" data-name="Rectangle 2220" width="53.583" height="2.242" transform="translate(15.232 84.389)" fill="gray" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2221" data-name="Rectangle 2221" width="18.133" height="2.242" transform="translate(50.681 84.389)" fill="gray" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15237" data-name="Path 15237" d="M133.728,383.25s2.5,6.285,3.143,7.647c.723,1.613,4.033,11.576,4.033,11.576L147.126,389l3.363,1.425s-5.311,16.761-6.474,19.181-.911,4.4-2.724,4.274-8.611-6.621-8.611-6.621Z" transform="translate(-86.702 -354.896)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <line id="Line_904" data-name="Line 904" x1="2.535" y2="4.567" transform="translate(51.635 47.577)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <line id="Line_905" data-name="Line 905" x1="2.095" y2="1.655" transform="translate(51.09 49.274)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <line id="Line_906" data-name="Line 906" x2="3.31" y2="1.645" transform="translate(59.575 36.263)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15238" data-name="Path 15238" d="M146.441,389.223s-.136-3.635.251-4.41,1.812-2.849,1.948-3.373.775-1.425.9-.513a6.82,6.82,0,0,1-.126,2.1,10.277,10.277,0,0,0,1.812-2.1c.136-.524,0-2.336.524-2.336s1.048.388,1.55,1.948a9.973,9.973,0,0,1-.262,4.924,32.383,32.383,0,0,1-2.849,4.4l-.388.786Z" transform="translate(-86.049 -355.117)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15239" data-name="Path 15239" d="M153.215,381.441s-.22-.88-.44-.22-.88,3.206-.88,3.206-3.415,2.315-3.415,2.975v.66" transform="translate(-85.951 -355.004)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15240" data-name="Path 15240" d="M112.049,379.357l-10.759,5.824L107,423.449s-.262.911.388,1.3a8.2,8.2,0,0,0,2.462.513h21.119a4.191,4.191,0,0,0,1.948-1.289,3.258,3.258,0,0,0,.65-2.336c0-.911,2.2-36.969,1.812-37.618a7.993,7.993,0,0,0-1.048-1.3l-11.146-4.4Z" transform="translate(-88.195 -355.13)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15241" data-name="Path 15241" d="M118.89,387.081l2.535,1.739-.712,27.813,4.735,5,3.8-5.783-6.118-27.425,1.708-3.677-4.871-2.755Z" transform="translate(-87.358 -354.956)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75" />
                      <path id="Path_15242" data-name="Path 15242" d="M127.231,359.93l1.561,4.662-.649,1.875,2.1,3.5-1.425.9a56.048,56.048,0,0,1-.8,5.856c-.251.388-4.389.1-4.389.1l-.775,7.773-8.747-5.1v-7.647s-2.86-10.759-2.21-11.659,5.06-2.724,10.371-3.373,6.086-1.163,6.348.388S127.231,359.93,127.231,359.93Z" transform="translate(-87.696 -356.183)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15243" data-name="Path 15243" d="M123.274,371.136a3.436,3.436,0,0,0-.618,1.184,11.907,11.907,0,0,1-2.189-.744,28.212,28.212,0,0,1-1.9-3.436l-1.341.126s1.456,6.621,2.168,7a8.213,8.213,0,0,0,1.7.911c0,.272,2.242.545,2.242.545s4.19-.168,4.4-.555a56.047,56.047,0,0,0,.8-5.856h0C127.737,370.2,124.427,369.848,123.274,371.136Z" transform="translate(-87.437 -355.614)" fill="#263238" stroke="#263238" stroke-miterlimit="10" stroke-width="0.75" />
                      <path id="Path_15244" data-name="Path 15244" d="M128.654,357.206c-.262-1.55-1.048-1.048-6.348-.388s-9.721,2.462-10.371,3.373,2.168,11.659,2.168,11.659v2.1a3.142,3.142,0,0,0,1.048,0,7.585,7.585,0,0,1,1.928-.2s-.5-.838-1.289-2a5.238,5.238,0,0,1-.775-3.886c.262-1.3,1.5-.88,1.875-.356s1.11,2.032,1.76,1.781.775-3.247.775-3.247,2.724-1.812,2.849-2.713a4.745,4.745,0,0,0-.775-2.336l5.7-1.048S128.915,358.767,128.654,357.206Z" transform="translate(-87.694 -356.183)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15245" data-name="Path 15245" d="M126.72,364.715a2.65,2.65,0,0,0-1.54,0" transform="translate(-87.059 -355.783)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75" />
                      <ellipse id="Ellipse_302" data-name="Ellipse 302" cx="0.388" cy="0.838" rx="0.388" ry="0.838" transform="translate(38.446 9.99)" fill="#263238" />
                      <path id="Path_15246" data-name="Path 15246" d="M114.187,377.08l-3.247,2.587,7.909,9.072,4.023-5.185Z" transform="translate(-87.736 -355.189)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15247" data-name="Path 15247" d="M122.33,383.531l2.975,3.9-.911-8.171-1.425-1.687Z" transform="translate(-87.195 -355.166)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15248" data-name="Path 15248" d="M134.605,414.72s3.5.524,3.761.911,3.143,3.625,3.5,4.012.649,4.023.649,4.023-.911.649-1.163.251-3.247-4.138-3.247-4.138-3.761-1.3-4.012-2.1l-.262-.786Z" transform="translate(-86.648 -353.399)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15249" data-name="Path 15249" d="M101.29,384.87s6.484,32.831,7,32.956,27.09.513,27.09.513l.775-2.2-22-5.573-1.425-22.973" transform="translate(-88.195 -354.819)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15250" data-name="Path 15250" d="M144.964,425.31s14,3.31,15.033,3.939.513,2.933-.126,4.84-20.48,35.869-20.48,35.869l-2.986-.765,9.114-32.181H134.08Z" transform="translate(-86.636 -352.895)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15251" data-name="Path 15251" d="M139.414,467.965l2.336,5.343s3.237,2.42,3.143,2.8-1.687.388-1.687.388l-3.761-.89s-3.5-3.31-4.41-3.949-1.938-.765-1.163-1.781a28.727,28.727,0,0,1,2.588-2.671Z" transform="translate(-86.659 -350.902)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15252" data-name="Path 15252" d="M107.139,422.64s-.649,7-.649,8.779a6.621,6.621,0,0,0,4.023,5.594c2.588,1.048,32.778,0,32.778,0l11.932,36.665,3.5-1.268s-4.41-39.944-4.41-40.855-.388-4.075-1.687-4.7-21.9-3.7-21.9-3.7Z" transform="translate(-87.948 -353.022)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15253" data-name="Path 15253" d="M156.509,470.12s6.736.89,7.511.765,3.237-.639,2.462.629-5.835,2.671-7.909,3.562-4.138,2.294-4.787,1.278-.775-4.965-.775-4.965Z" transform="translate(-85.735 -350.763)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15254" data-name="Path 15254" d="M291.79,417.16H91.61l1.917,60.5h2.65l1.634-51.4H287.924l1.624,51.4h2.661l1.917-60.5Z" transform="translate(-88.656 -353.283)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2222" data-name="Rectangle 2222" width="207.418" height="2.137" transform="translate(0 62.882)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2223" data-name="Rectangle 2223" width="30.757" height="17.505" transform="translate(75.718 45.503)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15255" data-name="Path 15255" d="M233.007,399.62H202.25l4.442,17.5h30.757Z" transform="translate(-83.393 -354.117)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                    </g>
                    <g id="freepik--character-4--inject-73" transform="translate(627.268 112.36)">
                      <path id="Path_15256" data-name="Path 15256" d="M600.316,254.7a68.99,68.99,0,0,0,6.16-3.436,7.7,7.7,0,0,1,0-1.865c.147-.43,1.435-1.435,1.435-1.435s1.142-10.476,1.142-15.473a87.53,87.53,0,0,1,1.152-12.466c.136-.576,4.012-40.562,4.012-40.562l1.289-10.182,12.759.629,12.759-.629,1.289,10.182s3.876,39.986,4.012,40.562a87.53,87.53,0,0,1,1.152,12.466c0,5.018,1.142,15.473,1.142,15.473a7.936,7.936,0,0,1,1.435,1.435,7.7,7.7,0,0,1,0,1.865,68.982,68.982,0,0,0,6.16,3.436c1.047.293.576,1.152-.566,1.729a14.792,14.792,0,0,1-4.871.859c-1.152,0-2.87-2.011-3.3-2.294s-.283,1.435-.283,1.435a4.357,4.357,0,0,1-1.582-.293c-.576-.283-1.571.293-1.718-1.435s-.147-3.436-.147-3.436a6.2,6.2,0,0,1-1.288-1.865c-.283-.859-.859-3.289-.859-3.289l-9.03-36.4-4.306-19.9-4.305,19.9-9.03,36.4s-.576,2.43-.859,3.289a6.2,6.2,0,0,1-1.288,1.865s0,1.718-.147,3.436-1.142,1.152-1.718,1.435a4.306,4.306,0,0,1-1.571.293s.136-1.728-.293-1.435-2.1,2.294-3.289,2.294a14.8,14.8,0,0,1-4.882-.859C599.74,255.851,599.31,254.992,600.316,254.7Z" transform="translate(-599.509 -104.831)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <line id="Line_907" data-name="Line 907" y1="5.332" x2="2.2" transform="translate(44.773 42)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15257" data-name="Path 15257" d="M633.839,133.66l12.885,5.081-5.594,29.646s.461,2.734.115,3.31a3.143,3.143,0,0,1-.911.911H615.14s-1.708-1.6-1.708-1.938v-1.488l-3.143-32.035,12.759-3.331Z" transform="translate(-599.008 -106.525)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15258" data-name="Path 15258" d="M629.759,134.451l-1.833,5.175,2.483,31.5-3.886,6.149-3.342-6.683,3.446-30.966-2.158-4.641,3.028-1.666Z" transform="translate(-598.395 -106.541)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15259" data-name="Path 15259" d="M623.64,125.86l-.88,4.305,5.332,4.243,3.9-4.044.2-2.158Z" transform="translate(-598.414 -106.896)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15260" data-name="Path 15260" d="M632.97,129.88l1.309,2.745-4,5.982-2.158-5.185Z" transform="translate(-598.16 -106.705)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15261" data-name="Path 15261" d="M626.51,133.532l-1.729,5.5-3.562-5.72,1.613-3.342Z" transform="translate(-598.488 -106.7)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15262" data-name="Path 15262" d="M634.13,132.5l-2.86,35.89.817,40.164h14.687L643.1,165.53l2.629-26.734Z" transform="translate(-598.01 -106.58)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15263" data-name="Path 15263" d="M621.914,132.5l2.86,35.89-.817,40.164H609.27l3.677-43.024L610.286,137.2Z" transform="translate(-599.056 -106.58)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15264" data-name="Path 15264" d="M619.9,120.174a32.089,32.089,0,0,1-1.4-6.924c0-2.608,2.462-3.928,4.431-4.662s10.759-1.624,12.319.471-.388,4.484-.388,4.484,1.6.649,1.236,2.032-.807,3.268-.807,3.268l-1.676-3.415-6.547,1.508-2.378,1.131L622.7,121.9s-1.341.6-1.938.073a3.992,3.992,0,0,1-.859-1.8Z" transform="translate(-598.617 -107.757)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15265" data-name="Path 15265" d="M634.295,113.85s1.645,8.81,1.676,10.591-3.027,6.359-3.813,6.516-1.812.7-2.912.1-5.51-2.65-6.055-3.677a23.319,23.319,0,0,1-1.142-3.143s-2.755-.744-2.828-2.692.838-2.284,1.4-1.917a18.105,18.105,0,0,1,1.571,1.309,5.469,5.469,0,0,1,.492-2.378c.5-.754,2.315-1.456,1.875-2.011l-2.43-3.143s3.488,1.9,6.4,1.791A18.2,18.2,0,0,0,634.295,113.85Z" transform="translate(-598.583 -107.488)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15266" data-name="Path 15266" d="M630.672,118.59l1.529,5.039a4.19,4.19,0,0,1-2.231.356" transform="translate(-598.072 -107.242)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75" />
                      <path id="Path_15267" data-name="Path 15267" d="M632.045,125.646s-1.288,1.289-3.865-.126" transform="translate(-598.157 -106.912)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75" />
                      <path id="Path_15268" data-name="Path 15268" d="M631.671,118.55c.084.44.377.744.65.691s.429-.45.346-.89-.388-.744-.66-.681S631.577,118.121,631.671,118.55Z" transform="translate(-597.992 -107.286)" fill="#263238" />
                      <path id="Path_15269" data-name="Path 15269" d="M626.841,119.541c.084.44.377.744.66.691s.419-.461.335-.89-.388-.744-.66-.691S626.747,119.111,626.841,119.541Z" transform="translate(-598.222 -107.239)" fill="#263238" />
                      <path id="Path_15270" data-name="Path 15270" d="M632.919,116.521a1.823,1.823,0,0,0-2.189.272" transform="translate(-598.035 -107.352)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75" />
                      <path id="Path_15271" data-name="Path 15271" d="M628.581,117.325a2.556,2.556,0,0,0-2.881,1.1" transform="translate(-598.275 -107.307)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75" />
                      <path id="Path_15272" data-name="Path 15272" d="M623.79,114.05S620.3,116,620.06,117.936s5.636,4.19,5.636,4.19Z" transform="translate(-598.543 -107.458)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2224" data-name="Rectangle 2224" width="17.369" height="8.978" rx="3.47" transform="translate(23.052 5.897) rotate(-2.99)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2225" data-name="Rectangle 2225" width="14.603" height="6.086" rx="2.87" transform="translate(24.508 7.258) rotate(-2.99)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.5" />
                      <path id="Path_15273" data-name="Path 15273" d="M666.19,129.149c.262-.649-.66-1.048-1.309-.786a8.684,8.684,0,0,0-2.357,1.833c-.262.4-2.1,3.928-2.1,3.928a13.282,13.282,0,0,1,.524-2.357,1.53,1.53,0,0,0-.66-2.1c-1.048-.649-1.236,1.048-1.456,1.676s-1.32,3.74-1.32,3.74a11.8,11.8,0,0,0,0,3.886l-1.907,3.939,3.478,3.478,3.006-4.934a1.048,1.048,0,0,0,.66-.126c.524-.4,3.007-2.755,3.268-4.326s-2.221-3.143-2.221-3.143l.388-1.833a25.442,25.442,0,0,0,2-2.881Z" transform="translate(-596.852 -106.781)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15274" data-name="Path 15274" d="M663.883,131.86s1.184.66,1.446,1.309-1.048,3.771-1.812,4.033S662.049,135.4,663.883,131.86Z" transform="translate(-596.514 -106.611)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15275" data-name="Path 15275" d="M665.262,133.11s1.435.786,1.435,1.708-.807,2.787-1.414,2.787c-1.446,0-1.142-.838-1.142-.838A34.082,34.082,0,0,0,665.262,133.11Z" transform="translate(-596.447 -106.551)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15276" data-name="Path 15276" d="M660.228,133.86s.911,2.619.649,3.279a3.141,3.141,0,0,1-1.048,1.173" transform="translate(-596.651 -106.515)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15277" data-name="Path 15277" d="M666.9,135.22a3.018,3.018,0,0,1,.451,1.372,2.671,2.671,0,0,1-1.048,1.6c-.461.147-1.048-.691-1.048-.691A7.309,7.309,0,0,0,666.9,135.22Z" transform="translate(-596.393 -106.451)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15278" data-name="Path 15278" d="M645.163,138.51l5.9,11.157,6.432-10.591,4.924,5.112-9.648,19.474s-2.1,2.273-3.216,2.273-6.432-6.432-6.432-6.432Z" transform="translate(-597.446 -106.294)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <line id="Line_908" data-name="Line 908" x1="3.017" y2="5.489" transform="translate(50.598 43.372)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15279" data-name="Path 15279" d="M605.774,178.959s.492,4.662.608,5.405a16.223,16.223,0,0,1,0,4.285c-.241,1.11-1.341,1.351-1.467.859s-.733-2.451-.859-2.818-.251,4.3-.733,5.238-1.477-.492-1.477-.492-2.326-3.069-2.326-4.3,1.55-9.04,1.55-9.04Z" transform="translate(-599.52 -104.411)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15280" data-name="Path 15280" d="M610.719,136.99l-8.988,23.78-1.571,21.014,5.7.388,3.928-20.616,2.745-3.928Z" transform="translate(-599.49 -106.367)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                    </g>
                    <g id="freepik--character-3--inject-73" transform="translate(521.548 113.334)">
                      <path id="Path_15281" data-name="Path 15281" d="M555.921,131.482l-1.372-8.3-4.19.723a24.909,24.909,0,0,0,.325-5.384A12.811,12.811,0,0,0,547.4,113c-.691-.4-3.886,1.215-3.886,1.215s-1.351,2.881-1.854,4.117-1.173,2.43-1.938,2.493-1.519.587-1.121,1.592a8.674,8.674,0,0,0,1.592,2.252s.283,4.861.744,5.51,2.786.765,4.243.545c.639-.094,1.55-.2,2.357-.293a1.3,1.3,0,0,0,.073.534,11.679,11.679,0,0,1-.911,4.871c1.76-1.268,3.3-.974,5.175-1.519,2.1-.639,4.8,2.42,5.9,2.745C557.032,136.049,555.921,131.482,555.921,131.482Z" transform="translate(-496.702 -108.485)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15282" data-name="Path 15282" d="M543.572,124.42a4.306,4.306,0,0,1-2.462.608" transform="translate(-496.578 -107.939)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15283" data-name="Path 15283" d="M547.144,110.586a3.6,3.6,0,0,0-3.667-.65,3.279,3.279,0,0,0-1.907,4.285c.524,1.048,3.143,2.42,3.143,2.42l1.2-1.582a20.254,20.254,0,0,0,1.393,3.583l1.917,3.038,2.944,2.273a17.055,17.055,0,0,0,1.99,3.635c1.519,2.1,1.634,2.1,3.3-.817,1.3-2.273,3.227-4.3,3.635-7.982s-3.143-8.182-7.029-9.68S547.144,110.586,547.144,110.586Z" transform="translate(-496.567 -108.688)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15284" data-name="Path 15284" d="M547.79,122.119s1.98-3.677,3.792-2.336.377,4.19-.8,5.458-2.1-.649-2.1-.649" transform="translate(-496.26 -108.174)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15285" data-name="Path 15285" d="M564.175,119.637c-2,1.268-4.808.419-6.285-1.9s-1.048-5.238.953-6.484,4.808-.419,6.285,1.9S566.166,118.348,564.175,119.637Z" transform="translate(-495.821 -108.593)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15286" data-name="Path 15286" d="M561.46,201.37s-.524,4.473.136,6.285,4.861,14.477,5.521,25.665,1.32,21.454.922,22-2.766.262-2.766.262h-7.888a.953.953,0,0,1-.272-1.184c.4-.66,4.19-3.562,4.745-4.484a10.109,10.109,0,0,0,1.184-2.629c0-.388-.922-10.015-4.085-16.332s-7.239-21.842-7.626-27.5Z" transform="translate(-496.092 -104.279)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15287" data-name="Path 15287" d="M556.814,252.115a27.343,27.343,0,0,1,2.766-2.525v1.341h8.276a6.955,6.955,0,0,1-.115,2.1c-.4.524-2.766.262-2.766.262h-7.888A.953.953,0,0,1,556.814,252.115Z" transform="translate(-495.835 -101.985)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15288" data-name="Path 15288" d="M553.791,202.012s-1.844,13.168-.524,17.117.786,11.052-1.048,18.552-3.289,12.246-3.289,12.634,0,3.949-.4,4.609-11.974.524-12.759.524-1.582-.786-.922-1.582,4.19-1.645,6.579-2.755,2.367-1.32,2.367-1.32,1.32-15.4.8-22.24-3.436-21.056-2.912-25.8Z" transform="translate(-496.887 -104.261)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15289" data-name="Path 15289" d="M535.763,253.167c-.8,0-1.582-.786-.922-1.582.524-.629,2.944-1.309,5.091-2.1l.44.953,8.517-.639a9.554,9.554,0,0,1-.367,2.881C548.135,253.3,536.549,253.167,535.763,253.167Z" transform="translate(-496.888 -101.99)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15290" data-name="Path 15290" d="M560.988,159.38s4.494,9.763,4.882,15.714-2.1,12.361-2.1,12.361l1.571,21.81s-5.238,3.289-14.079,3.143-13.157-1.582-13.157-1.582-1.048-25.519,1.708-35.513,1.781-15.462,1.781-15.462S553.854,160.407,560.988,159.38Z" transform="translate(-496.73 -106.276)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15291" data-name="Path 15291" d="M515,131.671s-2.347-4.306-3-5.343-4.84-5.06-5.887-4.8-.513,1.435.136,1.959a27.454,27.454,0,0,1,2.619,3.143,8.55,8.55,0,0,1,.786,2.21c.136.388-3.09-.89-3.876-1.289s-1.687.922-.911,1.7,3.352,2.2,4,2.849a13.618,13.618,0,0,0,3.007,1.561l1.425,1.3Z" transform="translate(-498.316 -108.077)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15292" data-name="Path 15292" d="M507.031,127.788a12.442,12.442,0,0,1-1.247-.545c-.775-.388-1.687.922-.911,1.7a9.433,9.433,0,0,0,1.247.943Z" transform="translate(-498.316 -107.808)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15293" data-name="Path 15293" d="M506.077,121.53c-1.048.262-.513,1.435.136,1.959a9.43,9.43,0,0,1,1.048,1.152c.388-.492.869-1.11,1.33-1.655C507.554,122.1,506.527,121.426,506.077,121.53Z" transform="translate(-498.272 -108.077)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15294" data-name="Path 15294" d="M547.607,135.767l-11.157,6.516L514.692,131.21l-2.472,5.081s21.36,17.714,22.271,18.626,4.305.911,7.815,1.435S547.607,135.767,547.607,135.767Z" transform="translate(-497.952 -107.616)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15295" data-name="Path 15295" d="M538.038,152.206,532.8,196.351l6.725,1.184.4-49.875S539.012,148.435,538.038,152.206Z" transform="translate(-496.973 -106.834)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15296" data-name="Path 15296" d="M562.985,158.072c.272.094.429.136.429.073,0-.608-.89-13.189-1.917-17.379a29.2,29.2,0,0,0-3.813-8.255s-2.1-1.11-3.059-.492c-.325.189-6.118-.262-9.051,2.6,0,0-5.825,9.837-5.825,13.189s-3.53,50.472-3.53,50.472a35.042,35.042,0,0,0,11.094,1.823c5.311-.23,11.314-.45,13.849-.67a25.813,25.813,0,0,0,5.542-1.341s.691-13.87.691-19.684C567.417,173.911,564.368,162.859,562.985,158.072Z" transform="translate(-496.81 -107.587)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15297" data-name="Path 15297" d="M556.235,129.89s-8.276,1.969-9.931,3.572-6.484,12.456-6.484,12.456,8.276-9.617,9.292-10.36,8.38-2.954,8.38-2.954Z" transform="translate(-496.639 -107.679)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15298" data-name="Path 15298" d="M542.28,178.39h11.764v2.315l-11.565-.189" transform="translate(-496.522 -105.372)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15299" data-name="Path 15299" d="M537.657,120.776s7.961,1.5,8.381.419,1.226-6.107.272-6.505-9.6-3.007-9.753-1.5C535.887,119.3,535.269,120.975,537.657,120.776Z" transform="translate(-496.823 -108.495)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <line id="Line_909" data-name="Line 909" x1="0.911" y2="7.721" transform="translate(40.834 4.56)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15300" data-name="Path 15300" d="M545.686,117.17l14.415,5.123-.87,1.561-13.9-5.1Z" transform="translate(-496.377 -108.284)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15301" data-name="Path 15301" d="M509.138,135.051l-4.065-3.478s-3.373-2.912-4.693-3.373-1.781-.094-1.781.272a1.781,1.781,0,0,0,.943,1.32,25.6,25.6,0,0,1,2.347,1.781s1.592,3.562,1.687,3.845,1.309,2.535,2.252,2.818a21.818,21.818,0,0,1,2.158.838Z" transform="translate(-498.6 -107.77)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15302" data-name="Path 15302" d="M501,130.9l.754.618s1.592,3.562,1.687,3.845,1.31,2.535,2.252,2.818a21.816,21.816,0,0,1,2.158.838L509,135l-4.065-3.478s-1.582-1.362-3-2.367Z" transform="translate(-498.486 -107.714)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15303" data-name="Path 15303" d="M506.11,132.256a36.5,36.5,0,0,0-2.629-4.19c-.849-.943-2.441-2.158-2.818-.943s1.4,2.1,1.4,2.1Z" transform="translate(-498.504 -107.835)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15304" data-name="Path 15304" d="M503.35,127.955s.744-1.226,1.4-.566,2.441,6,2.441,6Z" transform="translate(-498.374 -107.807)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15305" data-name="Path 15305" d="M554.55,139.35a72.888,72.888,0,0,0-8.81,2.42c-2.64,1.1-12.11,1.76-12.11,1.76l-24.88-8.8-1.98,8.381s25.32,10.35,27.3,10.79,22.24.22,22.24.22" transform="translate(-498.211 -107.449)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                    </g>
                    <g id="freepik--character-2--inject-73" transform="translate(214.116 109.954)">
                      <path id="Path_15306" data-name="Path 15306" d="M213.437,141.533s-.513-3.593-1.048-4.3-2.294-2.483-2.514-2.975-1.048-1.268-.985-.346a6.913,6.913,0,0,0,.492,2.011,10.476,10.476,0,0,1-2.147-1.718c-.22-.492-.409-2.294-.922-2.21s-.953.566-1.184,2.189A9.993,9.993,0,0,0,206.251,139a33.053,33.053,0,0,0,3.583,3.834l.513.691Z" transform="translate(-205.128 -104.198)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15307" data-name="Path 15307" d="M205.28,135.083s.063-.911.388-.293,1.435,3,1.435,3,3.771,1.676,3.886,2.326l.126.649" transform="translate(-205.121 -104.076)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15308" data-name="Path 15308" d="M227.352,133.63,222.3,150.779l-9.1-9.365-3.069,1.99,11.314,15.934s2.231,1.54,3.08,1.194,5.51-6.642,5.51-6.642Z" transform="translate(-204.89 -104.12)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15309" data-name="Path 15309" d="M251.092,154.21,255,134.683l-9.648-3.813-5.133-1.76-5.123,1.76-8.443,2.975,2.682,20.291-.178,3-3.394,9.669a15.871,15.871,0,0,0-.911,5.322v9.344l15.274,4.1,15.263-4.086v-9.344a16.144,16.144,0,0,0-.911-5.322l-3.4-9.669Z" transform="translate(-204.19 -104.335)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15310" data-name="Path 15310" d="M255.387,170.816a16.144,16.144,0,0,0-.911-5.322l-3.059-8.674H228.81l-3.048,8.674a15.871,15.871,0,0,0-.911,5.322l.073,38.435H255.3Z" transform="translate(-204.19 -103.017)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15311" data-name="Path 15311" d="M244.544,130.79l-2.724,66.3h15.022l-4.19-39.787,1.54-22.7Z" transform="translate(-203.383 -104.256)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15312" data-name="Path 15312" d="M235.461,130.92l-1.645,66.489H222.67l4.086-63.65Z" transform="translate(-204.294 -104.249)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15313" data-name="Path 15313" d="M246.713,132l-1.76-.807-.168.031-.8-.2-1.152-8.695-6.579.168-.911,8.695-.388.136-.66.042-1.089.5,4.861,7.092Z" transform="translate(-203.792 -104.658)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15314" data-name="Path 15314" d="M235.648,129a3.645,3.645,0,0,0-2.1,1.163c-.7.922-1.98,2.787-1.98,2.787l-1.163,2.787,4.766-.7,3.017,4.651Z" transform="translate(-203.925 -104.341)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15315" data-name="Path 15315" d="M237.85,139.733l5.573-11.523a2.786,2.786,0,0,1,1.98,1.278c.691,1.163,2.787,4.882,2.787,4.882l-4.065.23Z" transform="translate(-203.571 -104.378)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15316" data-name="Path 15316" d="M229.047,198.82s-.681,10.413-.461,15.85,2.043,31.427,2.043,32.6a3.844,3.844,0,0,1-1.11,2.231s-4.065,2.273-6.495,3.729c-.89.534-2.1.828-.094,1.739s12.456,0,12.456,0h2.032a34.858,34.858,0,0,0-.22-3.855,32.2,32.2,0,0,0-.681-3.394c-.23-.9,1.131-29.657,1.582-33.962s-.45-15.169-.45-15.169Z" transform="translate(-204.333 -101.03)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15317" data-name="Path 15317" d="M227,248.54c-1.278.733-2.818,1.624-3.981,2.315-.89.534-2.1.828-.094,1.739s12.456,0,12.456,0h2.032s0-1.54-.126-2.965l-9.1.786Z" transform="translate(-204.333 -98.654)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15318" data-name="Path 15318" d="M240.647,198.82s-.681,10.413-.45,15.85,2.032,31.427,2.032,32.6a3.844,3.844,0,0,1-1.11,2.231s-4.054,2.273-6.495,3.729c-.89.534-2.1.828-.094,1.739s12.456,0,12.456,0h2.032a34.843,34.843,0,0,0-.22-3.855,29.686,29.686,0,0,0-.681-3.394c-.23-.9,1.131-29.657,1.582-33.962s-.45-15.169-.45-15.169Z" transform="translate(-203.781 -101.03)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15319" data-name="Path 15319" d="M238.615,248.54c-1.288.733-2.828,1.624-3.991,2.315-.89.534-2.1.828-.094,1.739s12.456,0,12.456,0h2.032s0-1.54-.126-2.965l-9.1.786Z" transform="translate(-203.781 -98.654)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15320" data-name="Path 15320" d="M253.71,134.43l5.3,20.134-3.08,25.278-3.426-1.288.346-22.847-.911-3Z" transform="translate(-202.901 -104.082)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15321" data-name="Path 15321" d="M250.618,121.3a4.494,4.494,0,1,1-4.494-4.494A4.494,4.494,0,0,1,250.618,121.3Z" transform="translate(-203.392 -104.921)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15322" data-name="Path 15322" d="M237.941,106.632s-3.782.953-5.405,3.708-.346,5.1-.922,8.485a11.607,11.607,0,0,0,2.755,9.03,4.19,4.19,0,0,0,3.876,1.048c1.781-.325,4.5-3.007,5-3.331a8.62,8.62,0,0,0,1.184-2.535s3.394-3.719,3.562-6.8-1.624-7.333-4.19-8.59A12.183,12.183,0,0,0,237.941,106.632Z" transform="translate(-203.872 -105.406)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15323" data-name="Path 15323" d="M234.512,117.42s.45.377.22,1.414-1.341,1.571-.744,2.1a2.263,2.263,0,0,0,1.341.513" transform="translate(-203.763 -104.892)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.5" />
                      <path id="Path_15324" data-name="Path 15324" d="M232,113.977a58.666,58.666,0,0,0,6.652-1.456l3.394-.974s-.964,2.755-.639,4.19a21.339,21.339,0,0,0,1.781,4.054s.932-1.739,1.739-1.561c2.766.6.367,3.509.367,3.509s2.587-.974,2.755-5.51-1.131-9.564-5.238-10.214-6.642-1.456-9.889,2.273S229.412,113.652,232,113.977Z" transform="translate(-203.933 -105.46)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15325" data-name="Path 15325" d="M237.934,118.279c0,.482-.22.859-.482.859s-.492-.377-.492-.859.22-.859.492-.859S237.934,117.808,237.934,118.279Z" transform="translate(-203.614 -104.892)" fill="#263238" />
                      <path id="Path_15326" data-name="Path 15326" d="M233.774,117.769c0,.471-.22.859-.482.859s-.482-.388-.482-.859.21-.859.482-.859S233.774,117.3,233.774,117.769Z" transform="translate(-203.811 -104.916)" fill="#263238" />
                      <path id="Path_15327" data-name="Path 15327" d="M234.4,123.395s.859.639,2.682-.325" transform="translate(-203.736 -104.623)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.5" />
                      <path id="Path_15328" data-name="Path 15328" d="M236.24,116.414s1.3-1.393,2.8.325" transform="translate(-203.648 -104.965)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.5" />
                      <path id="Path_15329" data-name="Path 15329" d="M234.173,116.415s-1.048-1.173-1.833-.1" transform="translate(-203.834 -104.966)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.5" />
                      <path id="Path_15330" data-name="Path 15330" d="M252.555,176.55l-1.477,3.289-.168,4.431.828-.073.534-.807.136,4.023,2.011-.063s1.414-3.426,1.613-3.824a53.079,53.079,0,0,0-.052-5.688Z" transform="translate(-202.95 -102.079)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                    </g>
                    <g id="freepik--character-1--inject-73" transform="translate(25.036 111.074)">
                      <path id="Path_15331" data-name="Path 15331" d="M64.675,177.591,61.417,255.5H55.6l-3.331-62.791H50.637L44.446,255.5H39.093L37.7,177.13Z" transform="translate(-24.012 -103.172)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <rect id="Rectangle_2226" data-name="Rectangle 2226" width="21.863" height="51.729" transform="translate(38.337 75.917) rotate(180)" fill="#ffc100" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15332" data-name="Path 15332" d="M67.259,130.65S79.347,152.157,79.829,153.7s3.866,7.742-2.734,8.381L67.07,151.968Z" transform="translate(-22.615 -105.383)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15333" data-name="Path 15333" d="M55.8,127.4l.566,83.585H67.795V130.8Z" transform="translate(-23.151 -105.537)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15334" data-name="Path 15334" d="M55.989,156.5,55.8,126.91l2.242.8,2.451,10.057-3.185,2.818,3,3.363Z" transform="translate(-23.151 -105.561)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15335" data-name="Path 15335" d="M56.337,124.8l-.115,5.824-2.881,5.824L48.47,129.1V123.11Z" transform="translate(-23.5 -105.741)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15336" data-name="Path 15336" d="M52.509,128.611h1.728c2.587,0,5.961-3.352,5.961-5.95v-12.9a3.143,3.143,0,0,0-3.2-3.2H49.345a3.143,3.143,0,0,0-3.2,3.2v12.9C46.15,125.259,52.509,128.611,52.509,128.611Z" transform="translate(-23.61 -106.528)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15337" data-name="Path 15337" d="M56.038,115.9v3.939H54.1" transform="translate(-23.232 -106.084)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15338" data-name="Path 15338" d="M56.762,122.278H54.1a3.384,3.384,0,0,1-2.011-.67l-.21-.157" transform="translate(-23.338 -105.82)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15339" data-name="Path 15339" d="M58.694,114.2a1.634,1.634,0,0,0-2.284,0" transform="translate(-23.122 -106.187)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15340" data-name="Path 15340" d="M54.143,114.335a2.483,2.483,0,0,0-3.143,0" transform="translate(-23.379 -106.185)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15341" data-name="Path 15341" d="M57.2,116.178c0,.587.22,1.048.482,1.048s.492-.482.492-1.048-.22-1.048-.492-1.048S57.2,115.58,57.2,116.178Z" transform="translate(-23.085 -106.121)" fill="#263238" />
                      <path id="Path_15342" data-name="Path 15342" d="M52.05,116.178c0,.587.22,1.048.482,1.048s.482-.482.482-1.048-.21-1.048-.482-1.048S52.05,115.58,52.05,116.178Z" transform="translate(-23.33 -106.121)" fill="#263238" />
                      <path id="Path_15343" data-name="Path 15343" d="M46.257,115.395s-.932-2.619-1.875-.754a5.542,5.542,0,0,0,.377,5.238.922.922,0,0,0,1.5.094Z" transform="translate(-23.717 -106.176)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15344" data-name="Path 15344" d="M46.15,117.5v-5.447a5.521,5.521,0,0,1,5.521-5.521h3.007c1.6,0,6.422.471,7.427,1.571a63.1,63.1,0,0,1-7.155,1.048c-1.309,0-5.049-.377-5.049-.377s1.121,3.143-.189,3.939a8.381,8.381,0,0,1-2.629.932v4.127Z" transform="translate(-23.61 -106.53)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15345" data-name="Path 15345" d="M49.27,127.76l-.555,82.831H31.66s1.687-40.08,1.687-40.646,1.875-5.8,1.875-5.8l-.744-33.522Z" transform="translate(-24.299 -105.52)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15346" data-name="Path 15346" d="M47.038,140.6l-3.928-2.252,2.43-10.329,3.185-1.435-.189,30.128L43.3,143.781Z" transform="translate(-23.755 -105.576)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15347" data-name="Path 15347" d="M52.6,156.166l-.555,5.426-23.214,1.288s-6.369-1.142-3.415-9.1c1.571-4.19,9.428-23.287,9.428-23.287v23.047l-.943,1.875Z" transform="translate(-24.634 -105.39)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15348" data-name="Path 15348" d="M72.215,149.66s8.182,3.708,8.642,5.468a4.588,4.588,0,0,1-3.866,6.139c-2.954.23-8.182-7.731-8.182-7.731Z" transform="translate(-22.532 -104.478)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15349" data-name="Path 15349" d="M76.192,144.447l-7.679,19.474H52.59l7.3-19.851Z" transform="translate(-23.304 -104.744)" fill="#263238" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15350" data-name="Path 15350" d="M70.291,144.283,69.233,146H63.89l.733-1.791Z" transform="translate(-22.766 -104.738)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15351" data-name="Path 15351" d="M51.357,155.1l2.807-2.441,5.992-.189v2.807l-3.75,1.687-1.5,1.5-3.928.943Z" transform="translate(-23.38 -104.345)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15352" data-name="Path 15352" d="M60.709,249.767l9.1,4.148H54.35l.513-4.306Z" transform="translate(-23.22 -99.724)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_15353" data-name="Path 15353" d="M44.949,249.767l9.1,4.148H38.59l.513-4.306Z" transform="translate(-23.97 -99.724)" fill="#fff" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                    </g>
                  </g>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* 1 */}
      <section id="ProductEngineering"></section>
      <div className="backgroundpale">
        <div className="BringItToCenter">
          <div className="RootLayout">
            <div className="MainLayout pt-4 pb-4">
              <div className="row pt-3 pb-4 ">
                <div className="col-lg-12 col-md-12 d-flex flex-column align-items-start justify-content-start Overflow AlignmentPadding">
                  <p data-aos="fade-right" data-aos-duration="600"
                    data-aos-easing="ease-in-out" className="ProductEngineeringText">Product Engineering</p>
                  <p data-aos="fade-right" data-aos-duration="800"
                    data-aos-easing="ease-in-out" className="TechnicalConsultingSubText">
                    Whether you are a rising start-up or a fledgling enterprise with a startup soul, our team of business analysts, UX/UI designers, developers, testers and support engineers together will ensure the most optimum outcomes using agile development methodologies
                  </p>
                </div>
                <div
                  data-aos="fade-up" data-aos-duration="800"
                  data-aos-easing="ease-in-out" className="col-lg-4 col-md-4 d-flex flex-column align-items-center justify-content-center mt-4 SingleGrid">
                  <a
                    class="HashLink ProductWidth"
                    href="/premvplabs"
                  >
                    <div class="card ProductEngineeringCard1 ">
                      <img className="cardImages" src={ProductEngineeringPreMvpImage} alt="preMvp" />
                    </div>
                  </a>
                  <div class="card-body ProductWidth">
                    <h5 class="card-title ProductEngineeringCardTitle">
                      Pre-MVP Labs
                    </h5>
                    <p class="card-text ProductEngineeringCardText ">
                      Our consultative pre-MVP lab will help you build a
                      Clickable Prototype absolutely free!
                    </p>
                  </div>
                </div>
                <div
                  data-aos="fade-up" data-aos-duration="800"
                  data-aos-easing="ease-in-out" className="col-lg-4 col-md-4 d-flex flex-column align-items-center justify-content-center mt-4 SingleGrid">
                  <a
                    class="HashLink ProductWidth"
                    href="/mvp"
                  >
                    <div
                      class="card ProductEngineeringCard1 ">
                      <img className="cardImages" src={ProductEngineeringMvpDev} alt="preMvp" />
                    </div>
                  </a>
                  <div class="card-body ProductWidth">
                    <h5 class="card-title ProductEngineeringCardTitle">
                      MVP Development
                    </h5>
                    <p class="card-text ProductEngineeringCardText ">
                      Go to market with your product confidently and gain real world users, experience early monetisation and strengthen your fund raising efforts.
                    </p>
                  </div>
                </div>
                <div
                  data-aos="fade-up" data-aos-duration="800"
                  data-aos-easing="ease-in-out" className="col-lg-4 col-md-4 d-flex flex-column align-items-center justify-content-center mt-4 SingleGrid">
                  <a
                    class="HashLink ProductWidth"
                    href="/fullscaleapplication"
                  >
                    <div class="card ProductEngineeringCard1 ">
                      <img className="cardImages" src={ProductEngineeringFullScale} alt="preMvp" />
                    </div>
                  </a>
                  <div class="card-body ProductWidth">
                    <h5 class="card-title ProductEngineeringCardTitle">
                      Full Scale Applications
                    </h5>
                    <p class="card-text ProductEngineeringCardText ">
                      Transform your software product idea into a high-performing reality with our full-scale development services
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
      </div>
      {/* 2 */}
      <div id="TechnicalConsulting" className="backgroundblack">
        <div className="BringItToCenter">
          <div className="RootLayout">
            <div className="MainLayout pt-4 pb-4">
              <div className="row pt-3 pb-4 ">
                <div className="col-lg-12 col-md-12 d-flex flex-column align-items-start justify-content-start Overflow AlignmentPadding">
                  <p data-aos="fade-right" data-aos-duration="600"
                    data-aos-easing="ease-in-out" className="ProductEngineeringText">Technical Consulting</p>
                  <p data-aos="fade-right" data-aos-duration="800"
                    data-aos-easing="ease-in-out" className="TechnicalConsultingSubText">
                    Whether you need help with software architecture, process optimization, or digital transformation, our experienced team of consultants will provide you with expert advice, innovative solutions, and customized strategies to help you achieve your goals.
                  </p>
                </div>

                <div
                  data-aos="fade-up" data-aos-duration="800"
                  data-aos-easing="ease-in-out" className="col-lg-4 col-md-4 d-flex flex-column align-items-center justify-content-center mt-4 SingleGrid">
                  <a
                    class="HashLink ProductWidth "
                    href="/techconsulting#RequirementScopings"
                  >
                    <div class="card ProductEngineeringCard1 ">
                      <img className="cardImages" src={TechnicalConsultingRequirementScoping} alt="preMvp" />
                    </div>
                  </a>
                  <div class="card-body ProductWidth">
                    <h5 class="card-title ProductEngineeringCardTitle">
                      Requirement Scoping
                    </h5>
                    <p class="card-text ProductEngineeringCardText ">
                      Your users are the heroes of your story - Let us help you write the perfect User Story for your software product
                    </p>
                  </div>
                </div>

                <div
                  data-aos="fade-up" data-aos-duration="800"
                  data-aos-easing="ease-in-out" className="col-lg-4 col-md-4 d-flex flex-column align-items-center justify-content-center mt-4 SingleGrid">
                  <a
                    class="HashLink ProductWidth"
                    href="/techconsulting#Re-Architecturing"
                  >
                    <div

                      class="card ProductEngineeringCard1 ">
                      <img className="cardImages" src={TechnicalConsultingRe_Arch} alt="preMvp" />
                    </div>
                  </a>
                  <div class="card-body ProductWidth">
                    <h5 class="card-title ProductEngineeringCardTitle">
                      Re-Architecturing
                    </h5>
                    <p class="card-text ProductEngineeringCardText ">
                      Upgrade your software's foundation and unlock its full potential - Our architecture change services help you meet evolving business needs & stay ahead of competition
                    </p>
                  </div>
                </div>

                <div
                  data-aos="fade-up" data-aos-duration="800"
                  data-aos-easing="ease-in-out" className="col-lg-4 col-md-4 d-flex flex-column align-items-center justify-content-center mt-4 SingleGrid">
                  <a
                    class="HashLink ProductWidth"
                    href="/techconsulting#AuditAssessment"
                  >
                    <div class="card ProductEngineeringCard1 ">
                      <img className="cardImages" src={TechnicalConsultingAudit_Assessment} alt="preMvp" />
                    </div>
                  </a>
                  <div class="card-body ProductWidth">
                    <h5 class="card-title ProductEngineeringCardTitle">
                      Audit & Assessment
                    </h5>
                    <p class="card-text ProductEngineeringCardText ">
                      Even the best software can be made better. Improve your product's performance, scalability and efficiency. We can show how to get better return on your investments
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
      </div>
      {/* 3 */}
      <div id="MaintainAndSupport" className="backgroundpale">
        <div className="BringItToCenter">
          <div className="RootLayout">
            <div className="MainLayout pt-4 pb-4">
              <div className="row pt-3 pb-4 ">
                <div className="col-lg-12 col-md-12 d-flex flex-column align-items-start justify-content-start Overflow AlignmentPadding">
                  <p data-aos="fade-right" data-aos-duration="600"
                    data-aos-easing="ease-in-out" className="ProductEngineeringText">Maintenance & Support</p>
                  <p data-aos="fade-right" data-aos-duration="800"
                    data-aos-easing="ease-in-out" className="TechnicalConsultingSubText">
                    Whether you need help with bug fixes, software updates, or system optimizations, our software maintenance and support services will provide you with the peace of mind you need to focus on your core business.
                  </p>
                </div>
                <div
                  data-aos="fade-up" data-aos-duration="800"
                  data-aos-easing="ease-in-out" className="col-lg-4 col-md-4 d-flex flex-column align-items-center justify-content-center mt-4 SingleGrid">
                  <a
                    class="HashLink ProductWidth"
                    href="/maintainandsupport#Re-Parenting"
                  >
                    <div class="card ProductEngineeringCard1 ">
                      <img className="cardImages" src={MaintenanceSupportCodeReParent} alt="preMvp" />
                    </div>
                  </a>
                  <div class="card-body ProductWidth">
                    <h5 class="card-title ProductEngineeringCardTitle">
                      Code Re-Parenting
                    </h5>
                    <p class="card-text ProductEngineeringCardText ">
                      Stress-free software maintenance and support - Our comprehensive services cover all software, no matter who developed it.
                    </p>
                  </div>
                </div>
                <div
                  data-aos="fade-up" data-aos-duration="800"
                  data-aos-easing="ease-in-out" className="col-lg-4 col-md-4 d-flex flex-column align-items-center justify-content-center mt-4 SingleGrid">
                  <a
                    class="HashLink ProductWidth"
                    href="/maintainandsupport#Enhancements"
                  >
                    <div
                      class="card ProductEngineeringCard1 ">
                      <img className="cardImages" src={MaintenanceSupportEnahncement} alt="preMvp" />
                    </div>
                  </a>
                  <div class="card-body ProductWidth">
                    <h5 class="card-title ProductEngineeringCardTitle">
                      Enhancements
                    </h5>
                    <p class="card-text ProductEngineeringCardText ">
                      Strengthen your software with new capabilities - Our expert team will deliver customized functionality enhancements that will take your software to the next level
                    </p>
                  </div>
                </div>
                <div
                  data-aos="fade-up" data-aos-duration="800"
                  data-aos-easing="ease-in-out" className="col-lg-4 col-md-4 d-flex flex-column align-items-center justify-content-center mt-4 SingleGrid">
                  <a
                    class="HashLink ProductWidth"
                    href="/maintainandsupport#ProductionSupport"
                  >
                    <div class="card ProductEngineeringCard1 ">
                      <img className="cardImages" src={MaintenanceSupportProductionSupport} alt="preMvp" />
                    </div>
                  </a>
                  <div class="card-body ProductWidth">
                    <h5 class="card-title ProductEngineeringCardTitle">
                      Production Support
                    </h5>
                    <p class="card-text ProductEngineeringCardText ">
                      Ensure seamless performance continuity - Not just robust product development, but our expert maintenance services will also ensure maintenance support that will ensure continued business success
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
      </div>
      {/* 4 */}
      <div id="CyberSecurity" className="backgroundblack">
        <div className="BringItToCenter">
          <div className="RootLayout">
            <div className="MainLayout pt-4 pb-4">
              <div className="row pt-3 pb-4 ">
                <div className="col-lg-12 col-md-12 d-flex flex-column align-items-start justify-content-start Overflow AlignmentPadding">
                  <p data-aos="fade-right" data-aos-duration="600"
                    data-aos-easing="ease-in-out" className="ProductEngineeringText">Cyber Security</p>
                  <p data-aos="fade-right" data-aos-duration="800"
                    data-aos-easing="ease-in-out" className="TechnicalConsultingSubText">
                    A comprehensive range of cybersecurity solutions to safeguard your data and network from cyber attacks, including risk assessment, vulnerability testing, and threat detection and response.
                  </p>
                </div>
                <div
                  data-aos="fade-up" data-aos-duration="800"
                  data-aos-easing="ease-in-out" className="col-lg-6 col-md-6 d-flex flex-column align-items-center justify-content-center mt-4 SingleGrid">
                  <a
                    class="HashLink ProductWidth"
                    href="/cybersecurity#Vulnerability"
                  >
                    <div class="card ProductEngineeringCard1 ">
                      <img className="cardImages" src={CyberSecurityVulnerability} alt="preMvp" />
                    </div>
                  </a>
                  <div class="card-body ProductWidth">
                    <h5 class="card-title ProductEngineeringCardTitle">
                      Vulnerability Assessment
                    </h5>
                    <p class="card-text ProductEngineeringCardText ">
                      Get actionable insights into your system or network weaknesses that could potentially be exploited by attackers
                    </p>
                  </div>
                </div>
                <div
                  data-aos="fade-up" data-aos-duration="800"
                  data-aos-easing="ease-in-out" className="col-lg-6 col-md-6 d-flex flex-column align-items-center justify-content-center mt-4 SingleGrid">
                  <a
                    class="HashLink ProductWidth"
                    href="/cybersecurity#Penetration"
                  >
                    <div

                      class="card ProductEngineeringCard1 ">
                      <img className="cardImages" src={CyberSecurityPenetration} alt="preMvp" />
                    </div>
                  </a>
                  <div class="card-body ProductWidth">
                    <h5 class="card-title ProductEngineeringCardTitle">
                      Penetration Testing
                    </h5>
                    <p class="card-text ProductEngineeringCardText ">
                      Let us simulate a real-world attack on your systems or networks and evaluate the effectiveness of your security controls
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
      </div>

      {/* Case Studies */}
      <div className="backgroundpale m-0">
        <div className="BringItToCenter">
          <div className="RootLayout">
            <div className="MainLayout pt-4 pb-4">
              <div className="row pt-3 pb-4 ">
                <div className="col-lg-6 col-md-6 d-flex flex-column align-items-start justify-content-start Overflow AlignmentPadding">
                  <p data-aos="fade-right" data-aos-duration="600"
                    data-aos-easing="ease-in-out" className="ProductEngineeringText CaseStudyEffect">Case Studies</p>
                  <div className="row mb-4">
                    <img data-aos="slide-right" data-aos-duration="800" data-aos-anchor=".CaseStudyEffect"
                      data-aos-easing="ease-in-out" className="col-2 CaseStudiesArrow" src={CircleWatch} alt="preMvp" />
                    <p data-aos="fade-right" data-aos-duration="600" data-aos-anchor=".CaseStudyEffect"
                      data-aos-easing="ease-in-out" class="col-10 card-text ProductEngineeringCardText ">
                      Development of a wearable-tech platform to measure work place happiness
                    </p>
                  </div>
                  <div className="row mb-4">
                    <img data-aos="slide-right" data-aos-duration="800" data-aos-easing="ease-in-out" data-aos-anchor=".CaseStudyEffect" className="col-2 CaseStudiesArrow" src={CircleFingerPrint} alt="preMvp" />
                    <p data-aos="fade-right" data-aos-duration="600" data-aos-anchor=".CaseStudyEffect"
                      data-aos-easing="ease-in-out" class="col-10 card-text ProductEngineeringCardText ">
                      Stunning digital transformation of a successful employment verification leader
                    </p>
                  </div>
                  <div className="row mb-4">
                    <img data-aos="slide-right" data-aos-duration="800" data-aos-easing="ease-in-out" data-aos-anchor=".CaseStudyEffect" className="col-2 CaseStudiesArrow" src={CircleLottery} alt="preMvp" />
                    <p data-aos="fade-right" data-aos-duration="600" data-aos-anchor=".CaseStudyEffect"
                      data-aos-easing="ease-in-out" class="col-10 card-text ProductEngineeringCardText ">
                      An online raffles’ unanticipated growth in popularity required our swift action to stabilize their application
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 d-flex flex-column align-items-center justify-content-center Overflow">
                  <img data-aos="fade-up" data-aos-duration="1000"
                    data-aos-easing="ease-in-out" className="CaseStudycardImages" src={CaseStudiesHome} alt="preMvp" />
                </div>

              </div>
            </div>
          </div>
        </div >
      </div>
    </>
  );
};

export default HomePage;
