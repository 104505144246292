import { HashLink } from "react-router-hash-link";
import logo from "../images/RhibhusLogos.svg";
const Logo = () => {
  return (
    <>
      <div className="mb-4 pb-4 ">
        <span className="relativeTop">
          <span id="HomePage" ></span><span id="Consulting" ></span><span id="Maintenance" ></span><span id="CyberSecurity" ></span><span id="PreMVPLab" ></span><span id="MVP" ></span><span id="FullScaleApplication" ></span><span id="CaseStudies" ></span>
          <span id="HardwareIntegration" ></span><span id="Redevelopment" ></span><span id="FullScaleApplicationCaseStudy" ></span><span id="RhibhusFactor" ></span><span id="Careers" ></span><span id="TechStack" ></span><span id="Policy" ></span><span id="GetInTouchWithUS" ></span>
          <span id="Us" ></span>
        </span>
      </div>
      <div className="d-flex justify-content-start  mb-4">
        <HashLink to={"/"}>
          <img className="Logo" src={logo} alt="logo" />
        </HashLink>
      </div>
    </>
  );
};

export default Logo;
