import "./App.css";
// import { Route, Routes } from "react-router-dom";
// import * as RhibhusRoutes from "./helpers/Routes";

import Logo from "./shared/Logo";
import Nav from "./shared/Nav";
import Footer from "./shared/Footer";

import HomePage from "./components/HomePage";

function App() {
  return (
    <>
      <div className="ItToCenter">
        <div className="FinalMainLayout ">
          <Logo />
        </div>
      </div>
      <div class="NavLayout NavSticky ">
        <Nav />
      </div>
      <div className="ItToCenter">
        <div className="FinalMainLayout">
          <HomePage />
        </div>
      </div>
      <div className="ItToCenter">
        <div className="FinalMainLayout">
          <Footer />
        </div>
      </div>
    </>
  );
}

export default App;
